import React from "react";
import { withRouter } from "react-router";
import { Empty, Table } from "antd";
import {
  showInfoAlert,
  showColumnFeature,
  showColumnStoreType,
  showColumnAction,
} from "./GroupTableColumn";
import "./GroupTable.css";
import QRGenerator from "components/QRCodeGenerator/qrGenerator";
import moment from "moment";
import { numberWithCommas } from "utils/GlobalFunctions";
function GroupTable(props) {
  const { dataList, group, onDetail } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: group,
      onCell: (record, rowIndex) => ({
        onClick: async () => {
          onDetail(record.label_name)
        },
      }),
      render: (info) => {
        return <span style={{ cursor: 'pointer' }}>{info.label_name}</span>;
      },
    },
    {
      title: "Reg Count",
      dataIndex: "reg_count",
    },
    {
      title: "Total Reg",
      dataIndex: "total_reg",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
    },
  ];

  const dataSource = dataList.map((data, index) => {
    return { ...data, key: index };
  });
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={"club-table-row"}
      locale={{
        emptyText: (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Registrations Found"
            ></Empty>
          </div>
        ),
      }}
      scroll={{ x: 900 }}
      pagination={{ pageSize: 10 }}
    />
  );
}

export default withRouter(GroupTable);
