import React from "react";
import { withRouter } from "react-router";
import { Col, Row, Table } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { ClearOutlined, DeleteOutlined, EditFilled } from "@ant-design/icons";
function EventTable(props) {
  const { dataList, addUser, onResultView, editInfo, cancelInfo, deleteInfo } =
    props;
  if (dataList === undefined) return <div />;
  const srcList = dataList.map((x, index)=>{
    return {...x, key: index}
  })
  const columns = [
    {
      title: "Home Team",
      dataIndex: "TEAM_NAME",
      key: "TEAM_NAME",
      sorter: (a, b) => `${b.TEAM_NAME}`.localeCompare(`${a.TEAM_NAME}`),
    },
    {
      title: "Away Team",
      dataIndex: "AWAY_TEAM_NAME",
      key: "AWAY_TEAM_NAME",
      sorter: (a, b) =>
        `${b.AWAY_TEAM_NAME}`.localeCompare(`${a.AWAY_TEAM_NAME}`),
    },

    {
      title: "Date",
      key: "date",
      render: (record) => {
        let month = record.DATE_MONTH;
        if (month < 10) {
          month = "0" + month;
        }
        let day = record.DATE_DAY_OF_MONTH;
        if (day < 10) {
          day = "0" + day;
        }
        let date = month + "/" + day + "/" + record.DATE_YEAR;
        return date;
      },
      sorter: (a, b) => a.unix - b.unix,
    },
    {
      title: "Time",
      key: "time",
      render: (record) => {
        let hour = record.START_TIME_HOUR;
        if (hour < 10) {
          hour = "0" + hour;
        }
        let min = record.START_TIME_MIN;
        if (min < 10) {
          min = "0" + min;
        }
        let time = hour + ":" + min + " " + record.START_TIME_AMPM;
        return time;
      },
    },
    {
      title: "Location",
      key: "LOCATION_NAME",
      render: (record) => {
        let locationDetail = record.location_detail.LOCATION_NAME;
        return locationDetail;
      },
      sorter: (a, b) =>
        `${b.location_detail.LOCATION_NAME}`.localeCompare(
          `${a.location_detail.LOCATION_NAME}`
        ),
    },
    {
      title: "Division",
      dataIndex: "division",
      key: "Division",
      sorter: (a, b) => `${b.division}`.localeCompare(`${a.division}`),
    },
    {
      title: "Action",
      key: "operator",
      width: 155,
      render: (op, info) => {
        return (
          <Row gutter={16} justify="start" align={"middle"}>
            {info.IS_ACTIVE == "0" ? (
              <>
              </>
            ) : (
              <>
                {!info.practice && (
                  <Col>
                    <a
                      style={{ color: "var(--blueColor)" }}
                      onClick={async () => {
                        editInfo(info);
                      }}
                    >
                      <EditFilled /> Edit
                    </a>
                  </Col>
                )}
                <Col>
                  <a
                    style={{ color: "red" }}
                    onClick={async () => {
                      var res = await showConfirmDlgWidget({
                        title: "Cancel Confirmation",
                        content: "Are you sure you want to cancel this event?",
                      });
                      if (res == false) return;
                      cancelInfo(info);
                    }}
                  >
                    <ClearOutlined /> Cancel
                  </a>
                </Col>
              </>
            )}
            <Col>
              <a
                style={{ color: "red" }}
                onClick={async () => {
                  var res = await showConfirmDlgWidget({
                    title: "Delete Confirmation",
                    content: "Are you sure you want to delete this event?",
                  });
                  if (res == false) return;
                  deleteInfo(info);
                }}
              >
                <DeleteOutlined /> Delete
              </a>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return record.IS_ACTIVE == "0" ? (
          <span style={{ color: "var(--redColor)" }}>Cancelled</span>
        ) : (
          <span style={{ color: "var(--mainColor)" }}>Active</span>
        );
      },
    },
    {
      title: "Result",
      key: "result",
      render: (record) => {
        return (
          <CommonButton
            onClick={() => {
              onResultView(record);
            }}
          >
            Score
          </CommonButton>
        );
      },
    },
  ];

  const paginationProps = {
    total: dataList.length,
    showSizeChanger: true,
    defaultPageSize: 20,
    pageSizeOptions: ["10", "20", "30", "50"],
    locale: { items_per_page: "" },
  };
  return (
    <div className="schedule-list-widget" style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        dataSource={srcList}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={paginationProps}
      />
    </div>
  );
}

export default withRouter(EventTable);
