import React from 'react';
import './CommonButton.css'
const CommonButton = (props) => {
    const {onClick, customClass, style } = props;
    return (
        <span style={style} className={`custom-button common-button ${customClass === undefined ? "" : customClass}`} onClick={onClick}>
            {props.children}
        </span>
    );
};

export default CommonButton;