import React from "react";
import { useState } from "react";
import { Input, Row, Col, Select, Drawer, DatePicker, Divider } from "antd";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import moment from "moment";
import "./RightDetailWidget.css";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { isEmpty, ValidateEmail, isNumber } from "utils/GlobalFunctions";
import { useEffect } from "react";
import { MaskedInput } from "antd-mask-input";

const { Option } = Select;
function RightDetailWidget(props) {
  const {
    visible,
    setVisible,
    userData,
    setUserData,
    updateData,
    telePhone,
    setTelephone,
    homePhone,
    setHomephone,
    cellPhone,
    setCellphone,
    contactPhone,
    setContactPhone,
    regGroupList,
  } = props;
  const [errorField, setErrorField] = useState([]);
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    setErrorField([]);
  }, []);
  const onClose = () => {
    setErrorField([]);
    setVisible(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const validateOneFields = () => {
    // setUserData({
    //   ...userData,
    //   // tele_phone: telePhone,
    //   p_home_phone: homePhone,
    //   p_cell_phone: cellPhone,
    //   c_cell_phone: contactPhone,
    // });
    var errorList = Array();
    errorList = isEmpty(userData, "first_name", errorList);
    errorList = isEmpty(userData, "birthday", errorList);
    errorList = isEmpty(userData, "last_name", errorList);
    // let emailErrList = [];
    // emailErrList = isEmpty(userData, "email", emailErrList);
    // if (emailErrList.length == 0) {
    //   if (ValidateEmail(userData.email) === false) {
    //     errorList.push("email");
    //   }
    // }

    errorList = isEmpty(userData, "address", errorList);
    errorList = isEmpty(userData, "city", errorList);

    // if (telePhone != "" && telePhone != null) {
    //   if (telePhone.length != 12) {
    //     errorList.push("tele_phone");
    //   }
    // }

    errorList = isEmpty(userData, "state", errorList);
    errorList = isEmpty(userData, "gender", errorList);
    errorList = isEmpty(userData, "zip", errorList);
    errorList = isEmpty(userData, "grade", errorList);
    errorList = isEmpty(userData, "p_first_name", errorList);

    if (
      userData.p_home_phone != undefined &&
      userData.p_home_phone != "___-___-____" &&
      userData.p_home_phone != ""
    ) {
      if (userData.p_home_phone.length != 12) {
        errorList.push("p_home_phone" || userData.p_home_phone.includes("_"));
      }
    } else {
      errorList.push("p_home_phone");
    }


    errorList = isEmpty(userData, "p_last_name", errorList);

    if (
      userData.p_cell_phone != undefined &&
      userData.p_cell_phone != "___-___-____" &&
      userData.p_cell_phone != ""
    ) {
      if (
        userData.p_cell_phone.length != 12 ||
        userData.p_cell_phone.includes("_")
      ) {
        errorList.push("p_cell_phone");
      }
    } else {
      errorList.push("p_cell_phone");
    }

    errorList = isEmpty(userData, "p_relation", errorList);
    let pemailErrList = [];
    pemailErrList = isEmpty(userData, "p_email", pemailErrList);
    if (pemailErrList.length == 0) {
      if (ValidateEmail(userData.p_email) === false) {
        errorList.push("p_email");
      }
    } else {
      errorList = [...errorList, ...pemailErrList];
    }

    if (
      userData.c_email != undefined &&
      userData.c_email !== "null" &&
      userData.c_email != ""
    ) {
      if (ValidateEmail(userData.c_email) === false) {
        errorList.push("c_email");
      }
    }
    if (
      userData.c_cell_phone != undefined &&
      userData.c_cell_phone != "___-___-____" &&
      userData.c_cell_phone != ""
    ) {
      if (userData.c_cell_phone.length != 12) {
        errorList.push("c_cell_phone");
      }
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onUpdateData = () => {
    let valid = validateOneFields();
    if (!valid) return;
    updateData();
    onClose();
  };

  return (
    <>
      <Drawer
        title=""
        placement="right"
        onClose={onClose}
        open={visible}
        className="right-detail-drawer"
      >
        <div className="block">
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Participant 1 Info</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              <div style={{ paddingBottom: "5px" }}>Registration Group*</div>
              <Select
                className={
                  errorField !== undefined && errorField.includes("fee_label")
                    ? `common-select-error`
                    : `common-select`
                }
                style={{ fontWeight: 400, width: "100%" }}
                value={
                  userData.fee_label == undefined ? null : userData.fee_label
                }
                onSelect={(v, u) => {
                  if (errorField.includes("fee_label")) {
                    let errors = errorField.filter((x) => x != "fee_label");
                    setErrorField([...errors]);
                  }
                  let tmpList = [...regGroupList];
                  let price = tmpList.filter(
                    (x) => x.registration_group_name == v
                  )[0].price;
                  setUserData({ ...userData, fee_label: v, fee: price });
                }}
              >
                {regGroupList.map((x, index) => {
                  return (
                    <Option value={x.registration_group_name} key={index}>
                      {x.registration_group_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <Row gutter={[16, 6]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name*</div>
                <Input
                  value={userData.first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("first_name")) {
                      let errors = errorField.filter((x) => x != "first_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                <Input
                  value={userData.last_name}
                  className={
                    errorField !== undefined && errorField.includes("last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("last_name")) {
                      let errors = errorField.filter((x) => x != "last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              {/* <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Email*</div>
                <Input
                  type="email"
                  value={userData.email}
                  className={
                    errorField !== undefined && errorField.includes("email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("email")) {
                      let errors = errorField.filter((x) => x != "email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, email: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Telephone*</div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={telePhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("tele_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("tele_phone")) {
                      let errors = errorField.filter((x) => x != "tele_phone");
                      setErrorField([...errors]);
                    }
                    setTelephone(e.target.value);
                  }}
                />
              </Col> */}
            </Row>
            <Row style={{ marginTop: 10 }} gutter={16}>
              <Col xs={12}>
                <div style={{ paddingBottom: "5px" }}>Birthdate*</div>
                <DatePicker
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                  className={
                    errorField !== undefined && errorField.includes("birthday")
                      ? `common-input-error`
                      : `common-input`
                  }
                  format={dateFormat}
                  allowClear={false}
                  onChange={(v, str) => {
                    let newErrList = errorField.filter(
                      (x, index) => x != "birthday"
                    );
                    setErrorField([...newErrList]);
                    setUserData({ ...userData, birthday: str });
                  }}
                  value={
                    userData.birthday == ""
                      ? ""
                      : moment(userData.birthday, dateFormat)
                  }
                />
              </Col>
              <Col>
                <div style={{ paddingBottom: "5px" }}>Gender*</div>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("gender")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ fontWeight: 400 }}
                  value={userData.gender == undefined ? null : userData.gender}
                  onSelect={(v) => {
                    if (errorField.includes("gender")) {
                      let errors = errorField.filter((x) => x != "gender");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, gender: v });
                  }}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Col>
              <Col>
                <div style={{ paddingBottom: "5px" }}>Grade*</div>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("grade")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ fontWeight: 400 }}
                  value={userData.grade == undefined ? null : userData.grade}
                  onSelect={(v) => {
                    if (errorField.includes("grade")) {
                      let errors = errorField.filter((x) => x != "grade");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, grade: v });
                  }}
                >
                  <Option value="0">K</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                </Select>
              </Col>
            </Row>
          </div>
        </div>
        <div className="block" style={{ marginTop: 10 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Parent/Guardian</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <Row gutter={[16, 6]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name*</div>
                <Input
                  value={userData.p_first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "p_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      p_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                <Input
                  value={userData.p_last_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_last_name")) {
                      let errors = errorField.filter((x) => x != "p_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      p_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Home Phone*</div>
                <Input
                  type="text"
                  value={userData.p_home_phone}
                  placeholder="___-___-____"
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_home_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e, w) => {
                    let str = e.target.value;
                    let filStr = str.replace(/[- ]/g, "");
                    if (filStr == "" || filStr.length == 0) {
                      setUserData({
                        ...userData,
                        p_home_phone: "",
                      });
                      return;
                    }
                    if (!isNumber(filStr)) {
                      return;
                    }
                    if (filStr.length > 10) {
                      return;
                    }
                    if (errorField.includes("p_home_phone")) {
                      let errors = errorField.filter(
                        (y) => y != "p_home_phone"
                      );
                      setErrorField([...errors]);
                    }
                    if (filStr.length > 3 && filStr.length < 7) {
                      str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                    }

                    if (filStr.length > 6) {
                      str =
                        filStr.slice(0, 3) +
                        "-" +
                        filStr.slice(3, 6) +
                        "-" +
                        filStr.slice(6);
                    }

                    setUserData({
                      ...userData,
                      p_home_phone: str,
                    });
                  }}
                />
                {/* <MaskedInput
                  mask={"000-000-0000"}
                  value={homePhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_home_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_home_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "p_home_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setHomephone(e.target.value);
                  }}
                /> */}
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Cell Phone*</div>
                {/* <MaskedInput
                  mask={"000-000-0000"}
                  value={cellPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "p_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setCellphone(e.target.value);
                  }}
                /> */}
                <Input
                  type="text"
                  value={userData.p_cell_phone}
                  placeholder="___-___-____"
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e, w) => {
                    let str = e.target.value;
                    let filStr = str.replace(/[- ]/g, "");

                    if (filStr == "" || filStr.length == 0) {
                      setUserData({
                        ...userData,
                        p_cell_phone: "",
                      });
                      return;
                    }
                    if (!isNumber(filStr)) {
                      return;
                    }
                    if (filStr.length > 10) {
                      return;
                    }
                    if (errorField.includes("p_cell_phone")) {
                      let errors = errorField.filter(
                        (y) => y != "p_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    if (filStr.length > 3 && filStr.length < 7) {
                      str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                    }

                    if (filStr.length > 6) {
                      str =
                        filStr.slice(0, 3) +
                        "-" +
                        filStr.slice(3, 6) +
                        "-" +
                        filStr.slice(6);
                    }

                    setUserData({
                      ...userData,
                      p_cell_phone: str,
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Email*</div>
                <Input
                  type={"email"}
                  value={userData.p_email}
                  className={
                    errorField !== undefined && errorField.includes("p_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_email")) {
                      let errors = errorField.filter((x) => x != "p_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, p_email: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Relation*</div>
                <Input
                  value={userData.p_relation}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_relation")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_relation")) {
                      let errors = errorField.filter((x) => x != "p_relation");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, p_relation: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <div style={{ paddingBottom: "5px" }}>Address*</div>
                <Input
                  value={userData.address}
                  className={
                    errorField !== undefined && errorField.includes("address")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("address")) {
                      let errors = errorField.filter((x) => x != "address");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, address: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <div style={{ paddingBottom: "5px" }}>State/Province*</div>
                <Select
                  showSearch
                  className={
                    errorField !== undefined && errorField.includes("state")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ width: "100%", fontWeight: 400 }}
                  value={userData.state == undefined ? null : userData.state}
                  onSelect={(v) => {
                    if (errorField.includes("state")) {
                      let errors = errorField.filter((x) => x != "state");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, state: v });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="AL">Alabama</Option>
                  <Option value="AK">Alaska</Option>
                  <Option value="AZ">Arizona</Option>
                  <Option value="AR">Arkansas</Option>
                  <Option value="CA">California</Option>
                  <Option value="CO">Colorado</Option>
                  <Option value="CT">Connecticut</Option>
                  <Option value="DE">Delaware</Option>
                  <Option value="FL">Florida</Option>
                  <Option value="GA">Georgia</Option>
                  <Option value="HI">Hawaii</Option>
                  <Option value="ID">Idaho</Option>
                  <Option value="IL">Illinois</Option>
                  <Option value="IN">Indiana</Option>
                  <Option value="IA">Iowa</Option>
                  <Option value="KS">Kansas</Option>
                  <Option value="KY">Kentucky</Option>
                  <Option value="LA">Louisiana</Option>
                  <Option value="ME">Maine</Option>
                  <Option value="MD">Maryland</Option>
                  <Option value="MA">Massachusetts</Option>
                  <Option value="MI">Michigan</Option>
                  <Option value="MN">Minnesota</Option>
                  <Option value="MS">Mississippi</Option>
                  <Option value="MO">Missouri</Option>
                  <Option value="MT">Montana</Option>
                  <Option value="NE">Nebraska</Option>
                  <Option value="NV">Nevada</Option>
                  <Option value="NH">New Hampshire</Option>
                  <Option value="NJ">New Jersey</Option>
                  <Option value="NM">New Mexico</Option>
                  <Option value="NY">New York</Option>
                  <Option value="NC">North Carolina</Option>
                  <Option value="ND">North Dakota</Option>
                  <Option value="OH">Ohio</Option>
                  <Option value="OK">Oklahoma</Option>
                  <Option value="OR">Oregon</Option>
                  <Option value="PA">Pennsylvania</Option>
                  <Option value="RI">Rhode Island</Option>
                  <Option value="SC">South Carolina</Option>
                  <Option value="SD">South Dakota</Option>
                  <Option value="TN">Tennessee</Option>
                  <Option value="TX">Texas</Option>
                  <Option value="UT">Utah</Option>
                  <Option value="VT">Vermont</Option>
                  <Option value="VA">Virginia</Option>
                  <Option value="WA">Washington</Option>
                  <Option value="WV">West Virginia</Option>
                  <Option value="WI">Wisconsin</Option>
                  <Option value="WY">Wyoming</Option>
                </Select>
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>City*</div>
                <Input
                  value={userData.city}
                  className={
                    errorField !== undefined && errorField.includes("city")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("city")) {
                      let errors = errorField.filter((x) => x != "city");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, city: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Zip/Postal Code*</div>
                <Input
                  value={userData.zip}
                  className={
                    errorField !== undefined && errorField.includes("zip")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("zip")) {
                      let errors = errorField.filter((x) => x != "zip");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, zip: e.target.value });
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="block" style={{ marginTop: 10 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Emergency Contact</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <Row gutter={[16, 6]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name</div>
                <Input
                  value={
                    userData.c_first_name == "null" ? "" : userData.c_first_name
                  }
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "c_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name</div>
                <Input
                  value={
                    userData.c_last_name == "null" ? "" : userData.c_last_name
                  }
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_last_name")) {
                      let errors = errorField.filter((x) => x != "c_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Email</div>
                <Input
                  type="email"
                  value={userData.c_email == "null" ? "" : userData.c_email}
                  className={
                    errorField !== undefined && errorField.includes("c_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_email")) {
                      let errors = errorField.filter((x) => x != "c_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, c_email: e.target.value });
                  }}
                />
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Phone Number</div>
                <Input
                  type="text"
                  value={userData.c_cell_phone}
                  placeholder="___-___-____"
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e, w) => {
                    let str = e.target.value;
                    let filStr = str.replace(/[- ]/g, "");

                    if (filStr == "" || filStr.length == 0) {
                      setUserData({
                        ...userData,
                        c_cell_phone: "",
                      });
                      return;
                    }
                    if (!isNumber(filStr)) {
                      return;
                    }
                    if (filStr.length > 10) {
                      return;
                    }
                    if (errorField.includes("c_cell_phone")) {
                      let errors = errorField.filter(
                        (y) => y != "c_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    if (filStr.length > 3 && filStr.length < 7) {
                      str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                    }

                    if (filStr.length > 6) {
                      str =
                        filStr.slice(0, 3) +
                        "-" +
                        filStr.slice(3, 6) +
                        "-" +
                        filStr.slice(6);
                    }

                    setUserData({
                      ...userData,
                      c_cell_phone: str,
                    });
                  }}
                />
                {/* <MaskedInput
                  mask={"000-000-0000"}
                  value={contactPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "c_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setContactPhone(e.target.value);
                  }}
                /> */}
              </Col>
            </Row>
          </div>
          <Row align="middle" justify="center" style={{ marginTop: 30 }}>
            <Col>
              <CommonButton
                style={{ padding: "8px 24px" }}
                onClick={() => {
                  onUpdateData();
                }}
              >
                Update
              </CommonButton>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
}

export default RightDetailWidget;
