import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { numberWithCommas } from "utils/GlobalFunctions";

function TransactionTable(props) {
  const { dataList, setVisible, setUserData, deleteData, sport_type } = props;
  if (dataList === undefined) return <div />;
  let columns = [];
  columns = [
    {
      title: "Registration ID",
      dataIndex: "user_id",
      width: 90,
      sorter: (a, b) => parseInt(a.user_id) - parseInt(b.user_id),
    },
    {
      title: "Description",
      dataIndex: "description",
      fixed: "left",
      width: 160,
    },
    {
      title: "Child Num",
      dataIndex: "child_num",
      width: 90,
      sorter: (a, b) => parseInt(a.child_num) - parseInt(b.child_num),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 90,
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
    },

    {
      title: "Est Fee",
      width: 150,
      dataIndex: "est_fee",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => parseFloat(a.est_fee) - parseFloat(b.est_fee)
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      width: 150,
      sorter: (a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp),
      render: (record) => {
        let date = new Date(record * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth() + 1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        let hour = date.getHours();

        let minute = date.getMinutes();

        // Will display time in 10:30:23 format
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let pm = " AM";
        if (hour >= 12) {
          pm = " PM";
        }
        if (hour > 12) {
          hour = hour - 12;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        year = year + "";
        let formattedTime =
          month + "/" + day + "/" + year + " " + hour + ":" + minute + pm;
        return formattedTime
      },
    }
  ];

  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
        return { ...data, key: index };
      });

  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 900 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default withRouter(TransactionTable);
