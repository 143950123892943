// A wrapper for <Route> that redirects to the login

import { ROUTE_LOGIN, ROUTE_REGISTER } from "navigation/CONSTANTS";
import React, { createContext, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  if (auth.userData.token !== undefined && auth.userData.token !== "") {
    return <Route {...rest} />;
  } else {
    return (
      <Redirect
      to={{
          pathname: ROUTE_LOGIN,
          state: { from: rest.location },
        }}
      />
    );
  }
}
export default PrivateRoute;
