import React, { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./SchedulePage.css";
import { Row, Spin, Col, message, Select, Switch } from "antd";
import { useState } from "react";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import {
  ExpandAltOutlined,
  ExportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import EventTable from "./EventTable/EventTable";
import {
  apiCancelEvent,
  apiCancelPractice,
  apiDeleteEvent,
  apiDeletePractice,
  apiDeleteStage,
  apiGetDivisionAll,
  apiGetDivisionSchedule,
  apiGetEventList,
  apiGetGameResult,
  apiGetScheduleBaseInfo,
  apiGetScheduleList,
  apiGetStagedGames,
  apiUpdateStagedList,
} from "services/divisionService";
import { showScheduleModal } from "./ScheduleModal/showScheduleModal";
import { showEventModal } from "./EventModal/showEventModal";
import { showResultModal } from "./ResultModal/showResultModal";
import StageTable from "./StageTable/StageTable";
import { showCreateScheduleModal } from "./CreateScheduleModal/showCreateScheduleModal";
import { showEventEditModal } from "./EventEditModal/showEventEditModal";
import { showPracticeModal } from "./PracticeModal/showPracticeModal";
import { showLocationModal } from "./LocationModal/showLocationModal";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
const { Option } = Select;
function SchedulePage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [isBusy, setIsBusy] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [eventSrcList, setEventSrcList] = useState([]);
  const [eventDateList, setEventDateList] = useState([]);
  const [eventTeamList, setEventTeamList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [filterTeamList, setFilterTeamList] = useState([]);
  const [filterDayList, setFilterDayList] = useState([]);
  const [filterLocationList, setFilterLocationList] = useState([]);
  const [step, setStep] = useState(true);
  const [curOption, setCurOption] = useState("");
  const [curOptionVal, setCurOptionVal] = useState("");
  const [curOptionType, setCurOptionType] = useState("");
  useEffect(() => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      getBaseData();
    } else {
      message.error("Please select club and registration");
    }
  }, []);
  useEffect(() => {
    if (step) {
      getEventData();
    } else {
      getStagedGames();
    }
  }, [step]);
  const getBaseData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiGetScheduleBaseInfo(params)
      .then((res) => {
        setDivisionList(
          res.division.map((data, index) => {
            return { ...data, key: `parent:` + index };
          })
        );
        setFilterTeamList(res.team);
        setFilterDayList(res.date);
        setFilterLocationList(res.location);
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getStagedGames = (filter = "", filter_val = "") => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("filter", filter);
    params.append("filter_val", filter_val);
    setIsBusy(true);
    apiGetStagedGames(params)
      .then((res) => {
        setStageList(res);
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onSearchEvent = (filter = "", filter_val = "", filter_type = "") => {
    let tmpList = [...eventSrcList];
    let tmpSearchResult = [];
    if (filter == "division") {
      if (filter_type == "game") {
        tmpSearchResult = tmpList.filter(
          (x) => x.division == filter_val && !x.practice
        );
      } else if (filter_type == "practice") {
        tmpSearchResult = tmpList.filter(
          (x) => x.division == filter_val && x.practice
        );
      } else {
        tmpSearchResult = tmpList.filter((x) => x.division == filter_val);
      }
    } else if (filter == "date") {
      if (filter_type == "game") {
        tmpSearchResult = tmpList.filter(
          (x) => x.start_date == filter_val && !x.practice
        );
      } else if (filter_type == "practice") {
        tmpSearchResult = tmpList.filter(
          (x) => x.start_date == filter_val && x.practice
        );
      } else {
        tmpSearchResult = tmpList.filter((x) => x.start_date == filter_val);
      }
    } else if (filter == "location") {
      if (filter_type == "game") {
        tmpSearchResult = tmpList.filter(
          (x) => x.location_detail.ID == filter_val && !x.practice
        );
      } else if (filter_type == "practice") {
        tmpSearchResult = tmpList.filter(
          (x) => x.location_detail.ID == filter_val && x.practice
        );
      } else {
        tmpSearchResult = tmpList.filter(
          (x) => x.location_detail.ID == filter_val
        );
      }
    } else if (filter == "team") {
      if (filter_type == "game") {
        tmpSearchResult = tmpList.filter(
          (x) =>
            (x.TEAM_NAME == filter_val || x.AWAY_TEAM_NAME == filter_val) &&
            !x.practice
        );
      } else if (filter_type == "practice") {
        tmpSearchResult = tmpList.filter(
          (x) =>
            (x.TEAM_NAME == filter_val || x.AWAY_TEAM_NAME == filter_val) &&
            x.practice
        );
      } else {
        tmpSearchResult = tmpList.filter(
          (x) => x.TEAM_NAME == filter_val || x.AWAY_TEAM_NAME == filter_val
        );
      }
    } else {
      tmpSearchResult = [...tmpList];
    }
    setEventList(tmpSearchResult);
  };
  const getEventData = (filter_flag = false) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    setIsBusy(true);
    apiGetEventList(params)
      .then((res) => {
        if (res.length > 0) {
          let tmpList = [];
          let tmpDateList = [];
          let tmpTeamList = [];
          let tmpGameEventList = [];
          let tmpPracticeEventList = [];
          for (let i = 0; i < res.length; i++) {
            let tmpEvent = res[i];
            if (tmpEvent["games"].length > 0) {
              tmpGameEventList = [...tmpGameEventList, ...tmpEvent["games"]];
            }
            if (tmpEvent["practices"].length > 0) {
              tmpPracticeEventList = [
                ...tmpPracticeEventList,
                ...tmpEvent["practices"],
              ];
            }
          }
          for (let j = 0; j < tmpGameEventList.length; j++) {
            let oneGame = tmpGameEventList[j];
            if (oneGame["IS_HOME"] == "1" && oneGame["REL_GAME_ID"] != null) {
              let real_id = oneGame["REL_GAME_ID"];
              let real_id_index = tmpGameEventList.findIndex(
                (x) => x.ID == real_id
              );
              oneGame["AWAY_TEAM_NAME"] =
                oneGame["opponent_detail"]["OPPONENT_NAME"];

              if (oneGame["HR"] != "" && oneGame["HR"].includes(" ")) {
                let tmpDay = oneGame["HR"].split(" ")[0];
                let tmpNewDay = tmpDay.split("-");
                let newDay =
                  tmpNewDay[1] + "/" + tmpNewDay[2] + "/" + tmpNewDay[0];
                oneGame["start_date"] = newDay;
                oneGame["unix"] = dateToUnix(
                  oneGame.DATE_YEAR,
                  oneGame.DATE_MONTH,
                  oneGame.DATE_DAY_OF_MONTH,
                  oneGame.START_TIME_AMPM == "PM"
                    ? parseInt(oneGame.START_TIME_HOUR) + 12
                    : oneGame.START_TIME_HOUR,
                  oneGame.START_TIME_MIN,
                  0
                );
                if (tmpDateList.findIndex((x) => x == newDay) == -1) {
                  tmpDateList = [...tmpDateList, newDay];
                }
              } else {
                oneGame["start_date"] = "";
                oneGame["unix"] = 0;
              }

              if (
                tmpTeamList.findIndex((x) => x == oneGame.AWAY_TEAM_NAME) == -1
              ) {
                tmpTeamList = [...tmpTeamList, oneGame.AWAY_TEAM_NAME];
              }
              if (tmpTeamList.findIndex((x) => x == oneGame.TEAM_NAME) == -1) {
                tmpTeamList = [...tmpTeamList, oneGame.TEAM_NAME];
              }
              oneGame["practice"] = false;
              tmpList = [...tmpList, oneGame];
            }
          }
          for (let j = 0; j < tmpPracticeEventList.length; j++) {
            let oneGame = tmpPracticeEventList[j];
            oneGame["practice"] = true;
            oneGame["AWAY_TEAM_NAME"] = "PRACTICE";

            if (oneGame["HR"] != "" && oneGame["HR"].includes(" ")) {
              let tmpDay = oneGame["HR"].split(" ")[0];
              let tmpNewDay = tmpDay.split("-");
              let newDay =
                tmpNewDay[1] + "/" + tmpNewDay[2] + "/" + tmpNewDay[0];
              oneGame["start_date"] = newDay;
              oneGame["unix"] = dateToUnix(
                oneGame.DATE_YEAR,
                oneGame.DATE_MONTH,
                oneGame.DATE_DAY_OF_MONTH,
                oneGame.START_TIME_AMPM == "PM"
                  ? parseInt(oneGame.START_TIME_HOUR) + 12
                  : oneGame.START_TIME_HOUR,
                oneGame.START_TIME_MIN,
                0
              );
              if (tmpDateList.findIndex((x) => x == newDay) == -1) {
                tmpDateList = [...tmpDateList, newDay];
              }
              if (tmpTeamList.findIndex((x) => x == oneGame.TEAM_NAME) == -1) {
                tmpTeamList = [...tmpTeamList, oneGame.TEAM_NAME];
              }
            } else {
              oneGame["start_date"] = "";
              oneGame["unix"] = 0;
            }
            tmpList = [...tmpList, oneGame];
          }
          tmpList.sort(function (a, b) {
            return parseInt(a.unix) - parseInt(b.unix);
          });
          setEventDateList(tmpDateList);
          setEventTeamList(tmpTeamList);
          setEventSrcList(tmpList);
          if (filter_flag) {
            let tmpEventList = [...tmpList];
            let tmpSearchResult = [];
            if (curOption == "division") {
              tmpSearchResult = tmpEventList.filter(
                (x) => x.division == curOptionVal
              );
            } else if (curOption == "date") {
              tmpSearchResult = tmpEventList.filter(
                (x) => x.start_date == curOptionVal
              );
            } else if (curOption == "location") {
              tmpSearchResult = tmpEventList.filter(
                (x) => x.location_detail.ID == curOptionVal
              );
            } else if (curOption == "team") {
              tmpSearchResult = tmpEventList.filter(
                (x) =>
                  x.TEAM_NAME == curOptionVal ||
                  x.AWAY_TEAM_NAME == curOptionVal
              );
            } else {
              tmpSearchResult = [...tmpEventList];
            }
            setEventList(tmpSearchResult);
          } else {
            setEventList(tmpList);
          }
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  function dateToUnix(year, month, day, hour, minute, second) {
    return (
      new Date(Date.UTC(year, month - 1, day, hour, minute, second)).getTime() /
      1000.0
    );
  }
  const getDivisionList = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionSchedule(params)
      .then((res) => {
        if (res != null) {
          if (res.length > 0) {
            setDivisionList(
              res.map((data, index) => {
                return { ...data, key: `parent:` + index };
              })
            );
          } else {
            setDivisionList([]);
          }
        } else {
          setDivisionList([]);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onAddEventDlg = async () => {
    let dlgRes = await showEventModal({
      title: "Add New Event",
      token: userDataStore.token,
      divisionList: divisionList,
      step: step,
    });
    if (dlgRes == null) return;
    setCurOption("");
    setCurOptionVal("");
    if (step) {
      getEventData(true);
    } else {
      getStagedGames(curOption, curOptionVal);
    }
  };
  const onAutoDlg = async () => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      var resInfo = await showScheduleModal({
        title: "Schedule",
        token: userDataStore.token,
        divisionList: divisionList,
        club_id: appDataStore.club.id,
        registration_id: appDataStore.curRegistration.ID,
      });
      if (resInfo == null) return;
      getDivisionList();
      setCurOption("");
      setCurOptionVal("");
      if (resInfo == "schedule") {
        getStagedGames(curOption, curOptionVal);
      } else {
        getEventData(true);
      }
    }
  };
  const onResultView = (record) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("game_id", record.ID);
    setIsBusy(true);
    apiGetGameResult(params)
      .then((res) => {
        setIsBusy(false);
        onShowResultDlg(res);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onShowResultDlg = async (info) => {
    let dlgRes = await showResultModal({
      info: info,
      token: userDataStore.token,
    });
    if (dlgRes == null) return;
  };
  const onCreateScheduleEvent = async () => {
    if (stageList.length == 0) {
      return;
    }
    var res = await showCreateScheduleModal({
      content: "Creating schedule in Beclutch ...",
      stageList: stageList,
      token: userDataStore.token,
    });
    if (res == null) return;
    if (res == false) {
      message.error("creating failed");
      return;
    }
    onDeleteStagedGame(res, true);
  };

  const onDeleteStagedGame = async (id, flag = false) => {
    setIsBusy(true);
    const params1 = new URLSearchParams();
    params1.append("token", userDataStore.token);
    params1.append("ids", id);
    await apiDeleteStage(params1)
      .then((res) => {
        setIsBusy(false);
        if (!step) {
          getStagedGames(curOption, curOptionVal);
        }
        if (flag) {
          message.success("Schedule Created Successfully in BeClutch");
        } else {
          message.success("Deleted successfully");
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onEditStagedGame = async (info) => {
    let dlgRes = await showEventModal({
      title: "Edit Event",
      token: userDataStore.token,
      divisionList: divisionList,
      step: step,
      info: info,
    });
    if (dlgRes == null) return;
    getStagedGames(curOption, curOptionVal);
  };
  const onEditEvent = async (info) => {
    let tmpList = [...divisionList];
    let tmpDID = tmpList.filter((x) => x.division_name == info.division)[0].ID;
    let dlgInfo = { ...info };
    dlgInfo["division_id"] = tmpDID;
    let dlgRes = await showEventEditModal({
      title: "Edit Event",
      token: userDataStore.token,
      divisionList: divisionList,
      step: step,
      info: dlgInfo,
    });
    if (dlgRes == null) return;
    getEventData(true);
  };

  const onCancelEvent = async (info) => {
    if (info.practice) {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", userDataStore.token);
      params.append("practice_id", info.ID);
      params.append("notify", info.NEED_NOTIFY);
      await apiCancelPractice(params)
        .then((res) => {
          setIsBusy(false);
          getEventData(true);
          message.success("Cancelled successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", userDataStore.token);
      params.append("game_id", info.ID);
      params.append("notify", info.NEED_NOTIFY);
      await apiCancelEvent(params)
        .then((res) => {
          setIsBusy(false);
          getEventData(true);
          message.success("Cancelled successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };

  const onDeleteEvent = async (info) => {
    if (info.practice) {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", userDataStore.token);
      params.append("kind", 0);
      params.append("id", info.ID);
      await apiDeletePractice(params)
        .then((res) => {
          setIsBusy(false);
          getEventData(true);
          message.success("Deleted successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    } else {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", userDataStore.token);
      params.append("id", info.ID);
      await apiDeleteEvent(params)
        .then((res) => {
          setIsBusy(false);
          getEventData(true);
          message.success("Deleted successfully.");
        })
        .catch((err) => {
          setIsBusy(false);
          message.error(err);
        });
    }
  };
  const onAddPracticeDlg = async () => {
    let dlgRes = await showPracticeModal({
      title: "Add New Practice",
      token: userDataStore.token,
      divisionList: divisionList,
      step: step,
    });
    if (dlgRes == null) return;
  };
  const onLocationDlg = async () => {
    let dlgRes = await showLocationModal({
      title: "Add New Location",
      token: userDataStore.token,
    });
    if (dlgRes == null) return;
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Row justify="end" align="middle">
          <Col>
            <div>
              <CommonButton
                onClick={() => {
                  onLocationDlg();
                }}
                style={{ marginRight: 20 }}
              >
                <PlusOutlined style={{ marginRight: 10 }} />
                Add Location
              </CommonButton>
              <CommonButton
                onClick={() => {
                  onAddEventDlg();
                }}
                style={{ marginRight: 20 }}
              >
                <ExportOutlined style={{ marginRight: 10 }} />
                Add Event
              </CommonButton>
              {step && (
                <CommonButton
                  onClick={() => {
                    onAddPracticeDlg();
                  }}
                >
                  <ExportOutlined style={{ marginRight: 10 }} />
                  Add Practice
                </CommonButton>
              )}

              {!step && stageList.length > 0 && (
                <CommonButton
                  onClick={async () => {
                    let res = await showConfirmDlgWidget({
                      title: "Create Schedule in BeClutch",
                      content: "This will publish the Schedule to BeClutch.",
                      content2:
                        "Are you sure you want to publish " +
                        stageList.length +
                        " games?",
                    });
                    if (res == false) return;
                    onCreateScheduleEvent();
                  }}
                  style={{ marginRight: 20 }}
                >
                  <ExportOutlined style={{ marginRight: 10 }} />
                  Create Schedule in BeClutch
                </CommonButton>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Switch
              onChange={(val) => {
                setStep(val);
              }}
              checked={step}
            ></Switch>
            <span style={{ marginLeft: 20 }}>
              {step ? "Schedule" : "Staged Schedule"}
            </span>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
          <Col>
            {!step && (
              <CommonButton
                onClick={() => {
                  onAutoDlg();
                }}
              >
                <ExportOutlined style={{ marginRight: 10 }} />
                Auto Schedule
              </CommonButton>
            )}
          </Col>
          <Col>
            <div className="club-box">
              <Select
                className="select-club"
                style={{ width: 200 }}
                placeholder="Filter"
                value={curOption == "" ? null : curOption}
                onSelect={(v) => {
                  setCurOption(v);
                  setCurOptionVal("");
                  setCurOptionType("");
                }}
              >
                <Option value={"division"}>Division</Option>
                <Option value={"date"}>Date</Option>
                <Option value={"location"}>Location</Option>
                <Option value={"team"}>Team</Option>
              </Select>
              <Select
                className="select-club"
                style={{ width: 300, marginLeft: 10 }}
                placeholder="Filter By"
                value={curOptionVal == "" ? null : curOptionVal}
                onSelect={(v) => {
                  setCurOptionVal(v);
                  if (step) {
                    setCurOptionType("");
                  } else {
                    getStagedGames(curOption, v);
                  }
                }}
              >
                {curOption == "division" &&
                  step &&
                  divisionList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.division_name} key={index}>
                        {optionInfo.division_name}
                      </Option>
                    );
                  })}
                {curOption == "division" &&
                  !step &&
                  divisionList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.ID} key={index}>
                        {optionInfo.division_name}
                      </Option>
                    );
                  })}
                {curOption == "date" &&
                  step &&
                  eventDateList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo} key={index}>
                        {optionInfo}
                      </Option>
                    );
                  })}
                {curOption == "date" &&
                  !step &&
                  filterDayList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.game_start_date} key={index}>
                        {optionInfo.game_start_date}
                      </Option>
                    );
                  })}
                {curOption == "location" &&
                  filterLocationList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.ID} key={index}>
                        {optionInfo.LOCATION_NAME}
                      </Option>
                    );
                  })}
                {curOption == "team" &&
                  step &&
                  eventTeamList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo} key={index}>
                        {optionInfo}
                      </Option>
                    );
                  })}
                {curOption == "team" &&
                  !step &&
                  filterTeamList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.ID} key={index}>
                        {optionInfo.team_name}
                      </Option>
                    );
                  })}
              </Select>
              {step && (
                <Select
                  className="select-club"
                  style={{ width: 200, marginLeft: 10 }}
                  placeholder="Event Type"
                  value={curOptionType == "" ? null : curOptionType}
                  onSelect={(v) => {
                    setCurOptionType(v);
                    onSearchEvent(curOption, curOptionVal, v);
                  }}
                >
                  <Option value={"game"}>Game</Option>
                  <Option value={"practice"}>Practice</Option>
                  <Option value={"both"}>Both</Option>
                </Select>
              )}

              <CommonButton
                style={{ marginLeft: 30 }}
                onClick={() => {
                  setCurOption("");
                  setCurOptionVal("");
                  setCurOptionType("");
                  if (step) {
                    let tmpList = [...eventSrcList];
                    setEventList(tmpList);
                  } else {
                    getStagedGames();
                  }
                }}
              >
                Clear
              </CommonButton>
            </div>
          </Col>
        </Row>

        {step ? (
          <EventTable
            dataList={eventList}
            onResultView={onResultView}
            editInfo={onEditEvent}
            deleteInfo={onDeleteEvent}
            cancelInfo={onCancelEvent}
          />
        ) : (
          <StageTable
            dataList={stageList}
            editInfo={onEditStagedGame}
            deleteInfo={onDeleteStagedGame}
          />
        )}
      </Spin>
    </CommonDivWidget>
  );
}

export default SchedulePage;
