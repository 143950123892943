import React from "react";
import { Tag, Popconfirm, Space, message, Row, Col } from "antd";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { DeleteOutlined, EditFilled, EditOutlined, MailOutlined } from "@ant-design/icons";
export function showColumnAction(info, setVisible, sendMessage, setUserData, deleteData) {
  return (
    <Row gutter={16}>
      <Col>
        <a
          style={{ color: "var(--mainColor)" }}
          onClick={async () => {
            sendMessage(info);
          }}
        >
          <MailOutlined /> Send Message
        </a>
      </Col>
      <Col>
        <a
          style={{ color: "var(--blueColor)" }}
          onClick={async () => {
            setVisible(info)
            setUserData(info);
          }}
        >
          <EditFilled /> Edit
        </a>
      </Col>
      <Col>
        <a
          style={{ color: "red" }}
          onClick={async () => {
            var res = await showConfirmDlgWidget({
              title: "Delete Confirmation",
              content: "Are you sure you want to delete this user?",
            });
            if (res == false) return;
            deleteData(info.id);
          }}
        >
          <DeleteOutlined /> Delete
        </a>
      </Col>
    </Row>
  );
}
