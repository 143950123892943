import React, { useEffect } from "react";
import {
  Col,
  DatePicker,
  Row,
  Select,
  Input,
  TimePicker,
  message,
  Spin,
  Space,
} from "antd";
import moment from "moment";
import { useState } from "react";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showLocationModal } from "../LocationModal/showLocationModal";
import {
  apiCreateSchedule,
  apiGetLocation,
  apiGetSetupInfo,
} from "services/divisionService";
import { isEmpty } from "utils/GlobalFunctions";
import { CloseOutlined } from "@ant-design/icons";
function ScheduleDlgContent(props) {
  const {
    club_id,
    registration_id,
    selDivision,
    oneSchedule,
    setOneSchedule,
    errorField,
    setErrorField,
    token,
    onOk,
  } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [dayList, setDayList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [blackOutDays, setBlackOutDays] = useState([]);

  const dateFormat = "MM-DD-YYYY";
  const format = "hh:mm a";
  const { Option } = Select;
  useEffect(() => {
    if (selDivision != undefined) {
      let tmpDayList = [
        {
          key: 0,
          label: "Su",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 1,
          label: "M",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 2,
          label: "T",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 3,
          label: "W",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 4,
          label: "Th",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 5,
          label: "F",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
        {
          key: 6,
          label: "Sa",
          isSel: false,
          time: "12:00 pm",
          location_id: [],
          location_name: [],
        },
      ];
      setDayList(tmpDayList);
      getLocationData();
      if (selDivision.setup_id != "") {
        getSetupInfo(tmpDayList);
      }
    }
  }, [selDivision]);

  const getDayLabel = (label) => {
    let dayLabel = "";
    switch (label) {
      case "Su":
        dayLabel = "Sunday";
        break;
      case "M":
        dayLabel = "Monday";
        break;
      case "T":
        dayLabel = "Tuesday";
        break;
      case "W":
        dayLabel = "Wednesday";
        break;
      case "Th":
        dayLabel = "Thursday";
        break;
      case "F":
        dayLabel = "Friday";
        break;
      case "Sa":
        dayLabel = "Saturday";
        break;
    }
    return dayLabel;
  };
  const dayUi = dayList.map((x, index) => {
    return (
      <Col key={`day-rect-${index}`}>
        <div
          key={`day-rect-sub-${index}`}
          className={x.isSel ? `day-rect active` : `day-rect`}
          onClick={() => {
            onSelDay(x.key);
          }}
        >
          {x.label}
        </div>
      </Col>
    );
  });
  const timeUi = dayList.map((x, index) => {
    return x.isSel ? (
      <div key={`time-parent-rect-${index}`} className="time-parent-rect">
        <div className="time-rect">
          <span style={{ marginLeft: 12 }}>{getDayLabel(x.label)}</span>
        </div>
        <div style={{ margin: 10 }}>
          <Row gutter={16} justify="center" align={"middle"}>
            <Col>
              <TimePicker
                format={format}
                use12Hours={true}
                value={moment(x.time, format)}
                onChange={(time, timeStr) => {
                  let tmpList = [...dayList];
                  tmpList[index]["time"] = timeStr == "" ? "12:00 pm" : timeStr;
                  setDayList(tmpList);
                }}
              />
            </Col>
            <Col flex={"auto"}>
              <Row align="middle" gutter={16}>
                <Col style={{ width: "calc(100% - 85px)" }}>
                  <Select
                    className="group-select"
                    value={null}
                    placeholder="Add Location"
                    style={{ width: "100%" }}
                    onSelect={(v, a) => {
                      let tmpLocationList = [...locationList];
                      let locName = tmpLocationList.filter(
                        (oneLoc) => oneLoc.ID == v
                      )[0].LOCATION_NAME;

                      let tmpIDList = [...x.location_id];
                      let tmpNameList = [...x.location_name];
                      if (tmpIDList.includes(v)) {
                        message.error("You already added this location");
                        return;
                      }
                      tmpIDList = [...tmpIDList, v];
                      tmpNameList = [...tmpNameList, locName];
                      let tmpDayList = [...dayList];
                      tmpDayList = tmpDayList.map((y) => {
                        if (y.key == index) {
                          return {
                            ...y,
                            location_id: [...tmpIDList],
                            location_name: [...tmpNameList],
                          };
                        } else {
                          return y;
                        }
                      });
                      setDayList(tmpDayList);
                    }}
                  >
                    {locationList.map((z, zindex) => {
                      return (
                        <Option value={z.ID} key={zindex}>
                          <div>
                            <div>{z.LOCATION_NAME}</div>
                            <div style={{ color: "#6e6e6e", fontSize: 10 }}>
                              {z.LOCATION_STREET}, , {z.LOCATION_CITY},{" "}
                              {z.LOCATION_STATE}, , {z.LOCATION_ZIP}
                            </div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col>
                  <div style={{ textAlign: "end" }}>
                    <CommonButton
                      style={{ padding: "8px 20px" }}
                      onClick={() => {
                        onLocationDlg();
                      }}
                    >
                      Add
                    </CommonButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
          {x.location_name.map((location, locIndex) => {
            return (
              <div className="black-day-item" key={`location-item-${locIndex}`}>
                <Space>
                  <div>{location}</div>
                  <div
                    className="black-day-item-delete-button"
                    onClick={() => {
                      let tmpIDList = [...x.location_id];
                      let tmpNameList = [...x.location_name];

                      tmpIDList = tmpIDList.filter(
                        (locID, locIDindex) => locIDindex != locIndex
                      );
                      tmpNameList = tmpNameList.filter(
                        (locName, locNameindex) => locNameindex != locIndex
                      );
                      let tmpDayList = [...dayList];
                      tmpDayList = tmpDayList.map((y) => {
                        if (y.key == index) {
                          return {
                            ...y,
                            location_id: [...tmpIDList],
                            location_name: [...tmpNameList],
                          };
                        } else {
                          return y;
                        }
                      });
                      setDayList(tmpDayList);
                    }}
                  >
                    <CloseOutlined />
                  </div>
                </Space>
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <div key={`time-parent-rect-${index}`}></div>
    );
  });
  const onSelDay = (key) => {
    let tmpList = [...dayList];
    let oneItem = tmpList[key];
    oneItem["isSel"] = !oneItem["isSel"];
    tmpList[key] = oneItem;
    setDayList(tmpList);
  };
  const getSetupInfo = (tmpDaySrcList) => {
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("id", selDivision.setup_id);
    setIsBusy(true);
    apiGetSetupInfo(params)
      .then((res) => {
        setIsBusy(false);
        if (res != undefined && res.ID != undefined) {
          let tmpDayList = res.days_times.split(",");
          tmpDayList.map((x, k) => {
            let tmpDayIndex = x.split("-")[0];
            let tmpTime = x.split("-")[1];
            let tmpList = [...tmpDaySrcList];
            tmpList[tmpDayIndex]["isSel"] = true;
            tmpList[tmpDayIndex]["location_id"] = res.location_id.split(",")[k].split("-")
            tmpList[tmpDayIndex]["location_name"] = res.location_name.split(",")[k].split("-")
            tmpList[tmpDayIndex]["time"] = tmpTime;
            setDayList(tmpList);
          });
          setOneSchedule({
            ...oneSchedule,
            start_date: res.start_date,
            game_number: res.games_team,
            max_game: res.games_day,
            game_length: res.default_game_length,
            time_between: res.time_between_games,
          });
          if (res.blackout_days != null && res.blackout_days != "") {
            setBlackOutDays(res.blackout_days.split(","));
          }
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getLocationData = () => {
    const params = new URLSearchParams();
    params.append("token", token);
    setIsBusy(true);
    apiGetLocation(params)
      .then((res) => {
        setIsBusy(false);
        setLocationList(res);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onLocationDlg = async () => {
    let dlgRes = await showLocationModal({
      title: "Add New Location",
      token: token,
    });
    if (dlgRes == null) return;
    getLocationData();
  };
  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(oneSchedule, "start_date", errorList);
    errorList = isEmpty(oneSchedule, "game_number", errorList);
    errorList = isEmpty(oneSchedule, "max_game", errorList);
    errorList = isEmpty(oneSchedule, "game_length", errorList);
    errorList = isEmpty(oneSchedule, "time_between", errorList);

    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreateSchedule = () => {
    if (!validateFields()) return;
    let tmpList = [...dayList];
    tmpList = tmpList.filter((x) => x.isSel == true);
    let localValid = true;
    let timestr = tmpList
      .map((y) => {
        if (y.location_id.length == 0) {
          localValid = false;
        } else {
          return y.key + "-" + y.time;
        }
      })
      .join(",");
    if (!localValid) {
      message.error("Please add location per day");
      return;
    }
    if (timestr == "") {
      message.error("Please select Days of Week for Club League Play");
      return;
    }
    let locationIDstr = tmpList
      .map((y) => {
        return y.location_id.join("-");
      })
      .join(",");
    let locationNamestr = tmpList
      .map((y) => {
        return y.location_name.join("-");
      })
      .join(",");
    let tmpLocationList = [...locationList];
    let oneLocation =
      tmpLocationList[
        tmpLocationList.findIndex((x) => x.ID == oneSchedule.location)
      ];
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("club_id", club_id);
    params.append(
      "setup_id",
      selDivision.setup_id != undefined && selDivision.setup_id != ""
        ? selDivision.setup_id
        : ""
    );
    params.append("registration_id", registration_id);
    params.append("division_id", selDivision.ID);
    params.append("start_date", oneSchedule.start_date);
    params.append("location_id", locationIDstr);
    params.append("location_name", locationNamestr);
    params.append("games_team", oneSchedule.game_number);
    params.append("games_day", oneSchedule.max_game);
    params.append("default_game_length", oneSchedule.game_length);
    params.append("time_between_games", oneSchedule.time_between);
    params.append("days_times", timestr);
    params.append(
      "blackout_days",
      blackOutDays.length == 0 ? "" : blackOutDays.join(",")
    );
    setIsBusy(true);
    apiCreateSchedule(params)
      .then((res) => {
        setIsBusy(false);
        onOk();
        if (selDivision.setup_id != undefined && selDivision.setup_id != "") {
          message.success("Auto-Schedule Setup Updated Successfully");
        } else {
          message.success("Auto-Schedule Setup Saved Successfully");
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <div>Start Date</div>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              placeholder=""
              className={
                errorField !== undefined && errorField.includes("start_date")
                  ? `common-input-error`
                  : `common-input`
              }
              format={dateFormat}
              allowClear={false}
              onChange={(v, str) => {
                let newErrList = errorField.filter(
                  (x, index) => x != "start_date"
                );
                setErrorField([...newErrList]);
                setOneSchedule({ ...oneSchedule, start_date: str });
              }}
              value={
                oneSchedule.start_date == undefined
                  ? ""
                  : moment(oneSchedule.start_date, dateFormat)
              }
            />
          </Col>
          <Col xs={24} sm={12}></Col>
          <Col xs={24} sm={12}>
            <div>Number of games per team*</div>
            <Input
              value={oneSchedule.game_number}
              type="number"
              className={
                errorField !== undefined && errorField.includes("game_number")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("game_number")) {
                  let errors = errorField.filter((x) => x != "game_number");
                  setErrorField([...errors]);
                }
                setOneSchedule({
                  ...oneSchedule,
                  game_number: e.target.value,
                });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div>Max games per team per day*</div>
            <Input
              value={oneSchedule.max_game}
              type="number"
              className={
                errorField !== undefined && errorField.includes("max_game")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("max_game")) {
                  let errors = errorField.filter((x) => x != "max_game");
                  setErrorField([...errors]);
                }
                setOneSchedule({
                  ...oneSchedule,
                  max_game: e.target.value,
                });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div>{`Game Length (minutes)*`}</div>
            <Input
              value={oneSchedule.game_length}
              type="number"
              className={
                errorField !== undefined && errorField.includes("game_length")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("game_length")) {
                  let errors = errorField.filter((x) => x != "game_length");
                  setErrorField([...errors]);
                }
                setOneSchedule({
                  ...oneSchedule,
                  game_length: e.target.value,
                });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div>{`Time Between Games (minutes)*`}</div>
            <Input
              value={oneSchedule.time_between}
              className={
                errorField !== undefined && errorField.includes("time_between")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("time_between")) {
                  let errors = errorField.filter((x) => x != "time_between");
                  setErrorField([...errors]);
                }
                setOneSchedule({
                  ...oneSchedule,
                  time_between: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <div style={{ marginTop: 10 }}>
          Select Days of Week for Club League Play
        </div>
        <Row gutter={8}>{dayUi}</Row>
        <div style={{ marginTop: 10 }}>
          Select Start Time for each day of League Play
        </div>
        <div>{timeUi}</div>
        <div style={{ marginTop: 10 }}>
          Blackout Days:
          <DatePicker
            style={{
              cursor: "pointer",
              padding: "5px 6px",
              marginLeft: 20,
            }}
            placeholder=""
            className={
              errorField !== undefined && errorField.includes("start_date")
                ? `common-input-error`
                : `common-input`
            }
            format={dateFormat}
            allowClear={false}
            onChange={(v, str) => {
              setBlackOutDays([...blackOutDays, str]);
            }}
            value={""}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          {blackOutDays.map((days, index) => {
            return (
              <div className="black-day-item" key={`black-day-item-${index}`}>
                <Space>
                  <div>{days}</div>
                  <div
                    className="black-day-item-delete-button"
                    onClick={() => {
                      let tmpList = [...blackOutDays];
                      setBlackOutDays(tmpList.filter((x) => x != days));
                    }}
                  >
                    <CloseOutlined />
                  </div>
                </Space>
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: 20, textAlign: "end" }}>
          <CommonButton
            style={{ padding: "10px 30px" }}
            onClick={() => {
              onCreateSchedule();
            }}
          >
            Ok
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
}

export default ScheduleDlgContent;
