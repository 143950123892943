import { UPDATE_SUBSCRIPTION, UPDATE_USER, UPDATE_BALANCE, UPDATE_CLOSE } from "redux/actions/userActions";

const initialState = {
  
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_BALANCE:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_CLOSE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};
