import React, { useState } from "react";
import { Col, Image, Input, Row, Spin, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CloseOutlined } from "@ant-design/icons";
import { showImageReadDlg } from "components/ImageReadDlg/showImageReadDlg";
import { apiSaveHomePageInfo } from "services/dashboardService";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const HomeTab = (props) => {
  const { TextArea } = Input;
  const { currentLogoUrl, setCurrentLogoUrl, currentInfoUrl, setCurrentInfoUrl, currentBackgroundUrl, setCurrentBackgroundUrl, registrationID, settingInfo } =
    props;

  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  const [title, setTitle] = useState("");

  const [selBackgroundFile, setSelBackgroundFile] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [selLogoFile, setSelLogoFile] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [selInfoFile, setSelInfoFile] = useState("");
  const [infoUrl, setInfoUrl] = useState("");

  useEffect(() => {
    if (settingInfo != undefined) {
      setAnnouncement(
        settingInfo.registration_info.homepage_announcement == null
          ? ""
          : settingInfo.registration_info.homepage_announcement
      );
      setTitle(
        settingInfo.registration_info.homepage_title == null
          ? ""
          : settingInfo.registration_info.homepage_title
      );
      setCurrentBackgroundUrl(
        settingInfo.registration_info.homepage_background == null
          ? ""
          : settingInfo.registration_info.homepage_background
      );
      setCurrentLogoUrl(
        settingInfo.registration_info.homepage_logo == null
          ? ""
          : settingInfo.registration_info.homepage_logo
      );
      let tmpUrl = ""
      if (settingInfo.registration_info.homepage_info) {
        tmpUrl = settingInfo.registration_info.homepage_info.split("uploads/home_info/")[1]
      }
      setCurrentInfoUrl(tmpUrl);
    }
  }, [settingInfo]);


  const validateFields = () => {
    let errorList = Array();

    if (title == "") {
      errorList = [...errorList, "title"];
    }
    if (announcement == "") {
      errorList = [...errorList, "announcement"];
    }
    if (selBackgroundFile == "" && currentBackgroundUrl == "") {
      errorList = [...errorList, "background"];
    }

    if (errorList.length == 1 && errorList.includes("background")) {
      message.error("Please upload logo");
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onSave = () => {
    if (!validateFields()) return;

    setIsBusy(true);
    let params = {
      token: userDataStore.token,
      registrationID: registrationID,
      announcement: announcement,
      title: title,
      home_background: selBackgroundFile,
      home_logo: selLogoFile,
      home_info: selInfoFile,
    };
    apiSaveHomePageInfo(params)
      .then((res) => {
        if (res != undefined && res.logo != undefined) {
          setCurrentBackgroundUrl(res.background);
          setCurrentLogoUrl(res.logo);
          let tmpUrl = ""
          if (res.info) {
            tmpUrl = res.info.split("uploads/home_info/")[1]
          }
          setCurrentInfoUrl(tmpUrl);
          message.success("Home Page information saved successfully.");
        } else {
          message.error(res?.msg);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onDropImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    setBackgroundUrl(URL.createObjectURL(files[0]));
    setSelBackgroundFile(files[0]);
    if (errorField.includes("background")) {
      let errors = errorField.filter((x) => x != "background");
      setErrorField([...errors]);
    }
    // if (files[0].size / 1000 / 1000 > 2) {
    //   message.error("Image size should not be greater than 2Mb.");
    //   return;
    // }
    // setModificationInfo({
    //   scale: 1,
    //   rotation: 0,
    // });
    // setNewImageFile(files[0]);
  };
  const onDropInfo = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setInfoUrl(files[0].name);
    setSelInfoFile(files[0]);
  };
  const onDropLogo = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    setLogoUrl(URL.createObjectURL(files[0]));
    setSelLogoFile(files[0]);
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col xs={24} sm={24}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Home Page Title*
            </div>
            <Input
              value={title}
              className={
                errorField !== undefined && errorField.includes("title")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("title")) {
                  let errors = errorField.filter((x) => x != "title");
                  setErrorField([...errors]);
                }
                setTitle(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col xs={24} sm={24}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Home Page Announcement*
            </div>
            <Input
              value={announcement}
              className={
                errorField !== undefined && errorField.includes("announcement")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("announcement")) {
                  let errors = errorField.filter((x) => x != "announcement");
                  setErrorField([...errors]);
                }
                setAnnouncement(e.target.value);
              }}
            />
          </Col>
        </Row>
        {/* <div style={{ marginTop: 10, fontWeight: 600 }}>
          Home Page Description*
        </div>
        <div>
          <TextArea
            rows={6}
            value={description}
            className={
              errorField !== undefined && errorField.includes("description")
                ? `common-input-error`
                : `common-input`
            }
            onChange={(e) => {
              if (errorField.includes("description")) {
                let errors = errorField.filter((y) => y != "description");
                setErrorField([...errors]);
              }
              setDescription(e.target.value);
            }}
            onPressEnter={(e) => {
              // console.log(e.target.value);
              // let tmp = realDescription == "" ? description : realDescription;
              // tmp = tmp + "\n";
              // console.log(tmp);
              // setRealDescription(tmp);
            }}
          />
        </div> */}
        <Row align={"middle"} gutter={16} style={{ marginTop: 20 }}>
          <Col>
            <div style={{ fontWeight: 600 }}>Home Page Background Image*</div>
          </Col>
          <Col>
            {/* <CommonButton
              onClick={() => {
                onShowImageDlg('background');
              }}
            >
              Browse
            </CommonButton> */}
            <div className="back_image_uploader">
              <label htmlFor="back_image_uploader">
                <input
                  type="file"
                  id="back_image_uploader"
                  name="back_image_uploader"
                  style={{ display: "none" }}
                  accept="image/x-png, image/jpeg, image/jpg"
                  onChange={onDropImage}
                />
                <span className="custom-button common-button">
                  Browse
                </span>
              </label>
            </div>
          </Col>
          <Col>
            <div>(Recommended Size 1360 * 370)</div>
          </Col>
        </Row>
        {backgroundUrl == "" && currentBackgroundUrl != "" && (
          <div style={{ marginTop: 10 }}>
            <Image width={120} src={currentBackgroundUrl} />
          </div>
        )}
        {backgroundUrl != "" && (
          <div style={{ marginTop: 10 }}>
            <Image width={120} src={backgroundUrl} />
          </div>
        )}
        <Row align={"middle"} gutter={16} style={{ marginTop: 20 }}>
          <Col>
            <div style={{ fontWeight: 600 }}>Home Page Logo</div>
          </Col>
          <Col>
            <div className="logo_uploader">
              <label htmlFor="logo_uploader">
                <input
                  type="file"
                  id="logo_uploader"
                  name="logo_uploader"
                  style={{ display: "none" }}
                  accept="image/x-png, image/jpeg, image/jpg"
                  onChange={onDropLogo}
                />
                <span className="custom-button common-button">
                  Browse
                </span>
              </label>
            </div>
          </Col>
          <Col>
            <div>(Recommended Size 250 * 250)</div>
          </Col>
        </Row>
        {logoUrl == "" && currentLogoUrl != "" && (
          <div style={{ marginTop: 10 }}>
            <Image width={120} src={currentLogoUrl} />
          </div>
        )}
        {logoUrl != "" && (
          <div style={{ marginTop: 10 }}>
            <Image width={120} src={logoUrl} />
          </div>
        )}
        <Row align={"middle"} gutter={16} style={{ marginTop: 20 }}>
          <Col>
            <div style={{ fontWeight: 600 }}>Information Page</div>
          </Col>
          <Col>
            <div className="info_uploader">
              <label htmlFor="info_uploader">
                <input
                  type="file"
                  id="info_uploader"
                  name="info_uploader"
                  style={{ display: "none" }}
                  accept="*"
                  onChange={onDropInfo}
                />
                <span className="custom-button common-button">
                  Browse
                </span>
              </label>
            </div>
          </Col>
        </Row>
        {infoUrl == "" && currentInfoUrl != "" && (
          <div style={{ marginTop: 10 }}>
            {currentInfoUrl}
          </div>
        )}
        {infoUrl != "" && (
          <div style={{ marginTop: 10 }}>{infoUrl}</div>
        )}
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <CommonButton
            style={{ padding: "12px 45px" }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
};
export default HomeTab;
