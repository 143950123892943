import React, { useState } from "react";
import { Col, Empty, Image, Input, Row, Spin, Switch, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { showImageReadDlg } from "components/ImageReadDlg/showImageReadDlg";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isEmpty } from "utils/GlobalFunctions";
import { apiSaveGroupInfo } from "services/dashboardService";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ExtraTab = (props) => {
  const { TextArea } = Input;
  const { groupList, setGroupList, registrationID, settingInfo } = props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (settingInfo != undefined) {
      let tmpList = [...settingInfo.group_list];
      tmpList = tmpList.map((x) => {
        return {
          ID: x.ID,
          visible: 1,
          name: x.registration_group_name,
          description: x.registration_group_short_name,
          registration_group_sort_order: x.registration_group_sort_order,
          amount: x.registration_group_fee,
          available: x.discount_available == "1" ? true : false,
          status: x.open_close == "1" ? true : false,
        };
      });
      setGroupList(tmpList);
    }
  }, [settingInfo]);

  const onAddGroup = () => {
    let tmpList = [...groupList];
    let oneGroup = {
      name: "",
      description: "",
      amount: "",
      available: false,
      status: true,
      visible: 1,
    };
    tmpList = [...tmpList, oneGroup];
    setGroupList(tmpList);
  };
  const validateFields = () => {
    let errorList = Array();
    let tmpList = [...groupList];
    tmpList.map((x, index) => {
      errorList = isEmpty(x, "name", errorList, index);
      errorList = isEmpty(x, "description", errorList, index);
      errorList = isEmpty(x, "amount", errorList, index);
    });
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onSave = () => {
    if (groupList.length == 0) {
      message.error("Please add registration group.");
      return;
    }
    if (!validateFields()) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", registrationID);
    params.append("group_list", JSON.stringify(groupList));
    setIsBusy(true);
    apiSaveGroupInfo(params)
      .then((res) => {
        setIsBusy(false);
        if (res != undefined && res.group_list != undefined) {
          let tmpList = [...res.group_list];
          tmpList = tmpList.map((x) => {
            return {
              ID: x.ID,
              visible: 1,
              name: x.registration_group_name,
              description: x.registration_group_short_name,
              registration_group_sort_order: x.registration_group_sort_order,
              amount: x.registration_group_fee,
              available: x.discount_available == "1" ? true : false,
              status: x.open_close == "1" ? true : false,
            };
          });
          setGroupList(tmpList);
          message.success("Registration Group information saved successfully.");
        } else {
          message.error(res?.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col>
            <div style={{ fontWeight: 600 }}>Registration Groups</div>
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onAddGroup();
              }}
            >
              Add New Group
            </CommonButton>
          </Col>
        </Row>
        {groupList.length > 0 ? (
          <>
            {groupList.map((x, index) => {
              if (x.visible == 1) {
                return (
                  <div
                    key={index}
                    style={{
                      padding: 10,
                      marginTop: 15,
                      marginBottom: 10,
                      border: "1px solid #dedede",
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Name*
                        </div>
                        <Input
                          value={x.name}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "name")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "name")) {
                              let errors = errorField.filter(
                                (y) => y != index + "name"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpGroupList = [...groupList];
                            tmpGroupList[index].name = e.target.value;
                            setGroupList(tmpGroupList);
                          }}
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        marginTop: 10,
                        fontWeight: 600,
                        paddingBottom: "5px",
                      }}
                    >
                      Description*
                    </div>
                    <div>
                      <TextArea
                        allowClear
                        rows={6}
                        value={x.description}
                        className={
                          errorField !== undefined &&
                            errorField.includes(index + "description")
                            ? `common-input-error`
                            : `common-input`
                        }
                        onChange={(e) => {
                          if (errorField.includes(index + "description")) {
                            let errors = errorField.filter(
                              (y) => y != index + "description"
                            );
                            setErrorField([...errors]);
                          }
                          let tmpGroupList = [...groupList];
                          tmpGroupList[index].description = e.target.value;
                          setGroupList(tmpGroupList);
                        }}
                      // onPressEnter={(e) => {
                      //   let tmpGroupList = [...groupList];
                      //   tmpGroupList[index].description = e.target.value;
                      //   setGroupList(tmpGroupList);
                      // }}
                      />
                    </div>
                    <Row
                      style={{ marginTop: 10 }}
                      gutter={[16, 16]}
                      justify={"space-between"}
                      align={"bottom"}
                    >
                      <Col xs={24} sm={12}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Fee*
                        </div>
                        <Input
                          value={x.amount}
                          type={"number"}
                          prefix="$"
                          suffix="USD"
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "amount")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "amount")) {
                              let errors = errorField.filter(
                                (y) => y != index + "amount"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpGroupList = [...groupList];
                            tmpGroupList[index].amount = e.target.value;
                            setGroupList(tmpGroupList);
                          }}
                        />
                      </Col>
                      <Col xs={10} sm={5}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Discount Available
                        </div>
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={x.available}
                          style={{ marginBottom: 10 }}
                          onChange={(e) => {
                            let tmpGroupList = [...groupList];
                            tmpGroupList[index].available = e;
                            setGroupList(tmpGroupList);
                          }}
                        />
                      </Col>
                      <Col xs={10} sm={5}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Status
                        </div>
                        <Switch
                          checkedChildren="Open"
                          unCheckedChildren="Close"
                          checked={x.status}
                          style={{ marginBottom: 10 }}
                          onChange={(e) => {
                            let tmpGroupList = [...groupList];
                            tmpGroupList[index].status = e;
                            setGroupList(tmpGroupList);
                          }}
                        />
                      </Col>
                      <Col>
                        <CloseCircleOutlined
                          style={{
                            color: "red",
                            marginBottom: 15,
                            cursor: "pointer",
                            fontSize: 16,
                          }}
                          onClick={() => {
                            let tmpGroupList = [...groupList];
                            tmpGroupList[index].visible = 0;
                            setGroupList(tmpGroupList);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              }
            })}
          </>
        ) : (
          <Empty description="No Groups" style={{ marginTop: 20 }} />
        )}

        <div style={{ marginTop: 60, textAlign: "center" }}>
          <CommonButton
            style={{ padding: "12px 45px" }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
};
export default ExtraTab;
