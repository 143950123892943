import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, message, TreeSelect, Radio, Input, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import "./ScheduleModal.css";
import ScheduleDlgContent from "./ScheduleDlgContent";
import DivisionTable from "./DivisionTable";
import { apiSetupSchedule } from "services/divisionService";

function ScheduleModal(props) {
  const {
    show,
    proceed,
    title,
    divisionList,
    token,
    club_id,
    registration_id,
  } = props;

  const [titleLabel, setTitleLabel] = useState("");
  const [selDivision, setSelDivision] = useState();
  const [oneSchedule, setOneSchedule] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (title != undefined) {
      setTitleLabel(title);
    }
  }, []);

  const onCreate = () => {
    proceed(true);
  };
  const editSetup = (info) => {
    setIsBusy(true);
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("setup_id", info.setup_id);
    setIsBusy(true);
    apiSetupSchedule(params)
      .then((res) => {
        setIsBusy(false);
        proceed("schedule")
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="schedule-dlg"
      closable={true}
      maskClosable={false}
      title={titleLabel}
      okText="Ok"
      footer={null}
    >
      <Spin spinning={isBusy}>
        {selDivision ? (
          <ScheduleDlgContent
            oneSchedule={oneSchedule}
            setOneSchedule={setOneSchedule}
            errorField={errorField}
            setErrorField={setErrorField}
            token={token}
            club_id={club_id}
            registration_id={registration_id}
            selDivision={selDivision}
            onOk={onCreate}
          />
        ) : (
          <DivisionTable
            dataList={divisionList}
            setSelDivision={setSelDivision}
            setTitleLabel={setTitleLabel}
            editSetup={editSetup}
          />
        )}
      </Spin>
    </Modal>
  );
}

export default confirmable(ScheduleModal);
