import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Input, Row, message } from "antd";
import Modal from "antd/lib/modal/Modal";

import "./DivisionModal.css"
import { isEmpty } from "utils/GlobalFunctions";
function DivisionModal(props) {
  const { show, proceed, title, selDivision } = props;
  const [division, setDivision] = useState({});
  const [errorField, setErrorField] = useState([]);
  useEffect( ()=>{
      if( selDivision !== undefined ){
          setDivision(selDivision)
      }
  }, [selDivision])

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(division, "division_name", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if(validateFields()){
      proceed(division);
    }
  };
  
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="division-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      onOk={onCreate}
    >
      <Row style={{marginTop:10}}>
          <div style={{ paddingBottom: "5px" }}>Division Name*</div>
          <Input
              className="custom-input"
              value={division.division_name}
              style={{
                  border: errorField.includes("division_name")
                  ? "1px solid red"
                  : "",
              }}
              onChange={(e) => {
                  if (errorField.includes("division_name")) {
                      let errors = errorField.filter((x) => x != "division_name");
                      setErrorField([...errors]);
                  }
                  setDivision({...division,division_name: e.target.value});
              }}
          />

      </Row>
    </Modal>
  );
}

export default confirmable(DivisionModal);
