import { axiosGet, axiosPost } from "./ajaxServices";
import {
  urlGetDivisionAll,
  urlCreateDivision,
  urlCreateTeam,
  urlGetDivision,
  urlUpdateDivision,
  urlUpdateTeam,
  urlDeleteDivision,
  urlDeleteTeam,
  urlCreateDivisionFromReg,
  urlGetAvailableTeamList,
  urlAddRoster,
  urlGetDivisionFilters,
  urlGetTeamList,
  urlDeleteRoster,
  urlGetDivisionTree,
  urlSendMessageGroup,
  urlGetMessageList,
  urlSendMessage,
  urlAddLocation,
  urlUpdateLocation,
  urlGetLocation,
  urlCreateSchedule,
  urlGetScheduleList,
  urlCreateBeclutchTeam,
  urlCreateEvent,
  urlGetEventList,
  urlGetGameResult,
  urlUpdateGameScore,
  urlGetDivisionSchedule,
  urlGetSetupInfo,
  urlCreateRosterInvitation,
  urlUpdateRosterInvitation,
  urlSetupSchedule,
  urlGetStagedGames,
  urlUpdateStagedList,
  urlCreateClubEvent,
  urlUpdateEvent,
  urlCancelEvent,
  urlDeleteEvent,
  urlCreatePractice,
  urlCancelPractice,
  urlDeletePractice,
  urlDeleteStage,
  urlGetScheduleBaseInfo,
  urlGetMessageDetailList,
} from "./CONSTANTS";

export const apiGetDivisionAll = (param) => {
  const url = urlGetDivisionAll;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetDivisionSchedule = (param) => {
  const url = urlGetDivisionSchedule;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetScheduleBaseInfo = (param) => {
  const url = urlGetScheduleBaseInfo;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetDivisionTreeList = (param) => {
  const url = urlGetDivisionTree;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetDivisionFilters = (param) => {
  const url = urlGetDivisionFilters;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetDivisionList = (param) => {
  const url = urlGetDivision;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateDivisionFromRegistration = (param) => {
  const url = urlCreateDivisionFromReg;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateDivision = (param) => {
  const url = urlCreateDivision;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateDivision = (param) => {
  const url = urlUpdateDivision;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateTeam = (param) => {
  const url = urlCreateTeam;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateTeam = (param) => {
  const url = urlUpdateTeam;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteDivision = (param) => {
  const url = urlDeleteDivision;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteTeam = (param) => {
  const url = urlDeleteTeam;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateBeclutchTeam = (param) => {
  const url = urlCreateBeclutchTeam;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiGetTeamList = (param) => {
  const url = urlGetTeamList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateRoster = (param) => {
  const url = urlAddRoster;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteRoster = (param) => {
  const url = urlDeleteRoster;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateRosterInvitation = (param) => {
  const url = urlCreateRosterInvitation;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateRosterInvitation = (param) => {
  const url = urlUpdateRosterInvitation;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiSendMessageGroup(param) {
  const url = urlSendMessageGroup;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("token", param.token);
  formData.append("sport_type", param.sport_type);
  formData.append("member_ids", param.member_ids);
  formData.append("team_ids", param.team_ids);
  formData.append("parent_ids", param.parent_ids);
  formData.append("cc_member_ids", param.cc_member_ids);
  formData.append("cc_team_ids", param.cc_team_ids);
  formData.append("cc_parent_ids", param.cc_parent_ids);
  formData.append("bcc_member_ids", param.bcc_member_ids);
  formData.append("bcc_team_ids", param.bcc_team_ids);
  formData.append("bcc_parent_ids", param.bcc_parent_ids);
  formData.append("subject", param.subject);
  formData.append("content", param.content);
  formData.append("sel_type", param.sel_type);
  formData.append("message_title", param.message_title);
  formData.append("registration_id", param.registration_id);
  formData.append("mFlag", param.mFlag);
  formData.append("ccFlag", param.ccFlag);
  formData.append("bccFlag", param.bccFlag);
  if (param.attachment != "") {
    if (param.attachment instanceof File) {
      formData.append("attachment", param.attachment);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const apiSendMessage = (param) => {
  const url = urlSendMessage;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("token", param.token);
  formData.append("user_id", param.user_id);
  formData.append("sport_type", param.sport_type);
  formData.append("toID", param.toID);
  formData.append("toName", param.toName);
  formData.append("toEmail", param.toEmail);
  formData.append("subject", param.subject);
  formData.append("content", param.content);
  formData.append("message_title", param.message_title);
  if (param.attachment != "") {
    if (param.attachment instanceof File) {
      formData.append("attachment", param.attachment);
    }
  }
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetMessageList = (param) => {
  const url = urlGetMessageList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetMessageDetailList = (param) => {
  const url = urlGetMessageDetailList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetLocation = (param) => {
  const url = urlGetLocation;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiAddLocation = (param) => {
  const url = urlAddLocation;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateLocation = (param) => {
  const url = urlUpdateLocation;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetScheduleList = (param) => {
  const url = urlGetScheduleList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateSchedule = (param) => {
  const url = urlCreateSchedule;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetSetupInfo = (param) => {
  const url = urlGetSetupInfo;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateEvent = (param) => {
  const url = urlCreateEvent;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateEvent = (param) => {
  const url = urlUpdateEvent;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCancelEvent = (param) => {
  const url = urlCancelEvent;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteEvent = (param) => {
  const url = urlDeleteEvent;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreateClubEvent = (param) => {
  const url = urlCreateClubEvent;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetEventList = (param) => {
  const url = urlGetEventList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetStagedGames = (param) => {
  const url = urlGetStagedGames;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiGetGameResult = (param) => {
  const url = urlGetGameResult;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateGameScore = (param) => {
  const url = urlUpdateGameScore;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiSetupSchedule = (param) => {
  const url = urlSetupSchedule;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiUpdateStagedList = (param) => {
  const url = urlUpdateStagedList;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeleteStage = (param) => {
  const url = urlDeleteStage;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCreatePractice = (param) => {
  const url = urlCreatePractice;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiCancelPractice = (param) => {
  const url = urlCancelPractice;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const apiDeletePractice = (param) => {
  const url = urlDeletePractice;
  return new Promise((resolve, reject) => {
    axiosPost(url, param)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
