import React from "react";
import { Spin } from "antd";
import "./NormalButton.css";
function NormalButton(props) {
  return (
    <button
        className="green-button custom-button"
        onClick={props.onClick}
        style={props.style}
        ref={props.ref}
      >
        <Spin spinning={props.isBusy === true}>{props.children}</Spin>
      </button>
  );
}

export default NormalButton;
