import { DeleteOutlined, EditFilled, MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ChildTable from "./ChildTable";
import ChildTableEmpty from "./ChildTableEmpty";

function ParentTable(props) {
    const { userInfo, updateDivision, deleteDivision, updateTeam, deleteTeam, sendMail, createBeclutchTeam, indexKey, indexID } = props;
    const [expanded, setExpand] = useState(false)
    const toggleExpander = () => {
        if (!expanded) {
            setExpand(true)
        } else {
            setExpand(false)
        }
    }
    
    // useEffect(()=>{
    //     if (indexKey != undefined && indexID != undefined) {
    //         if (indexKey == indexID) {
    //             setExpand(true)
    //         }
    //     }
    // }, [indexID])

    return (
        <>
            <tr data-row-key={userInfo.key} className="ant-table-row ant-table-row-level-0 parent-table-row">
                <td className="ant-table-cell ant-table-row-expand-icon-cell">
                    {expanded ? <MinusSquareOutlined style={{fontSize: 20}} onClick={() => {
                        toggleExpander()
                    }} /> : <PlusSquareOutlined style={{fontSize: 20}} onClick={() => {
                        toggleExpander()
                    }} />}

                    {/* <button type="button" className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Collapse row"></button>
                    <button type="button" class="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Expand row"></button> */}
                </td>
                <td className="ant-table-cell">{userInfo.division_name}</td>
                <td className="ant-table-cell">{userInfo.children.length}</td>
                <td className="ant-table-cell">
                    <div className="ant-row" style={{ marginLeft: '-4px', marginRight: '-4px', rowGap: 0 }}>
                        <div className="ant-col" style={{ paddingLeft: 4, paddingRight: 4 }}>
                            <a
                                style={{ color: "var(--blueColor)" }}
                                onClick={async () => {
                                    updateDivision(userInfo)
                                }}
                            >
                                <EditFilled />
                            </a>
                        </div>
                        <div className="ant-col" style={{ paddingLeft: 4, paddingRight: 4 }}>
                            <a
                                style={{ color: "red" }}
                                onClick={async () => {
                                    var res = await showConfirmDlgWidget({
                                        title: "Delete Confirmation",
                                        content: "Are you sure you want to delete this division?",
                                    });
                                    if (res == false) return;
                                    deleteDivision(userInfo.ID)
                                }}
                            >
                                <DeleteOutlined />
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
            {expanded && <>
                {userInfo.children.length > 0 ?
                    <>
                        <tr className="ant-table-expanded-row ant-table-expanded-row-level-1">
                            <td colSpan="5" className="ant-table-cell">
                                <div className="ant-table-wrapper">
                                    <div className="ant-spin-nested-loading">
                                        <div className="ant-spin-container">
                                            <div className="ant-table">
                                                <div className="ant-table-container">
                                                    <div className="ant-table-content">
                                                        <table style={{ tableLayout: 'auto' }}>
                                                            <thead className="ant-table-thead">
                                                                <tr>
                                                                    <th className="ant-table-cell">Teams</th>
                                                                    <th className="ant-table-cell">Coach</th>
                                                                    <th className="ant-table-cell">Email</th>
                                                                    <th className="ant-table-cell">Status</th>
                                                                    <th className="ant-table-cell ant-table-row-action">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ant-table-tbody">
                                                                {userInfo.children.map((x, index) => {
                                                                    return <ChildTable
                                                                        userInfo={x}
                                                                        key={index}
                                                                        updateTeam={updateTeam}
                                                                        deleteTeam={deleteTeam}
                                                                        sendMail={sendMail}
                                                                        createBeclutchTeam={createBeclutchTeam} />
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                    : <ChildTableEmpty />
                }
            </>}
        </>
    )
}
export default ParentTable;