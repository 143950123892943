import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, message, TreeSelect, Radio, Input, Col, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import parse from "html-react-parser";
import "./EncryptModal.css";

const { SHOW_PARENT } = TreeSelect;
function EncryptModal(props) {
  const { show, proceed, title, user_data } =
    props;

  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    setIsBusy(true);
    setTimeout(() => {
      setIsBusy(false);
      proceed(null);
      window.open(user_data.camper_record, '_blank');
    }, 2000);
  }, [])
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="encrypt-dlg"
      closable={true}
      maskClosable={false}
      footer={null}
      okText="Ok"
      onOk={() => {
        proceed(null);
      }}
    >
      <div style={{textAlign: 'center'}}>
        <Spin spinning={isBusy} tip={title}></Spin>
      </div>
    </Modal>
  );
}

export default confirmable(EncryptModal);
