import "utils/dropConsole";
// antd framework style
import "antd/dist/antd.less";
// ROUTER
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";

import "./App.css";
import { ProvideAuth } from "navigation/Auth/ProvideAuth";
// Redux
import { Provider } from "react-redux";

import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";


function App() {
  return (
    <>
      <div>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <ProvideAuth>
              <BrowserRouter>
                <RouterConfig />
              </BrowserRouter>
            </ProvideAuth>
          </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default App;

