// You can use CONSTANTS.js file for below definitions of constants and import here.
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const UPDATE_BALANCE = 'UPDATE_BALANCE'
export const UPDATE_CLOSE = 'UPDATE_CLOSE'

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: {...user}
})

export const updateSubscription = (subscription) => ({
  type: UPDATE_SUBSCRIPTION,
  payload: {...subscription}
})

export const updateBalance = (balance) => ({
  type: UPDATE_BALANCE,
  payload: {...balance}
})
export const updateClose = (close) => ({
  type: UPDATE_CLOSE,
  payload: {...close}
})
