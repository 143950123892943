import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Divider, Spin, Row, Col, message } from "antd";
import "./RosterPage.css";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import RegUserTable from "./RegUserTable/RegUserTable";
import {
  apiGetAvailableParticipant,
  apiGetRegistrationDetail,
  apiGetRegistrationDetailByGroup,
} from "services/dashboardService";
import DivisionRow from "./DivisionDetailTable/DivisionRow";
import DivisionEmpty from "./DivisionDetailTable/DivisionEmpty";
import {
  apiCreateRoster,
  apiCreateRosterInvitation,
  apiDeleteRoster,
  apiGetDivisionAll,
  apiGetDivisionFilters,
  apiGetDivisionList,
  apiUpdateRosterInvitation,
} from "services/divisionService";
import { showRosterAddModal } from "./RosterAddModal/showRosterAddModal";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { showMyAlertDialog } from "components/MyAlertDialog/showMyAlertDialog";
import { showProgressbarDlg } from "components/ProgressbarDlg/showProgressbarDlg";

const { Option } = Select;
function RosterPage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [userList, setUserList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [dlgDivisionList, setDlgDivisionList] = useState([]);
  const [curOption, setCurOption] = useState("");
  const [selRosters, setSelRosters] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [key, setKey] = useState(-1);
  const [isBusy, setIsBusy] = useState(false);
  useEffect(() => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      getData();
      getParticipantAll();
      getOptionList();
    } else {
      setUserList([]);
    }
    setCurOption("");
  }, [appDataStore]);
  const getData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionAll(params)
      .then((res) => {
        if (res != null) {
          setKey(res.index);
          if (res.list.length > 0) {
            setDivisionList(res.list);
          } else {
            setDivisionList([]);
          }
        } else {
          setDivisionList([]);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getParticipantAll = (optionValue = "") => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("filter", optionValue);
    apiGetAvailableParticipant(params)
      .then((res) => {
        if (res == null || res.length == 0) {
          setUserList([])
        }
        if (res != null && res.detail.length > 0) {
          setUserList(res.detail);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const getOptionList = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("id", appDataStore.curRegistration.ID);
    params.append("group", "fee_label");
    apiGetRegistrationDetailByGroup(params)
      .then((res) => {
        if (res.length > 0) {
          let tmpList = [];
          res.map((x) => {
            tmpList = [...tmpList, x.label_name];
          });
          setOptionList(tmpList);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  const onAddUser = (selInfo) => {
    if (appDataStore.club == undefined) {
      message.error("Please select club");
      return;
    }
    if (appDataStore.curRegistration == undefined) {
      message.error("Please select registration");
      return;
    }
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionList(params)
      .then((res) => {
        setIsBusy(false);
        setDlgDivisionList(res);
        if (res.length == 0) {
          message.error("Please create division");
          return;
        }
        if (selRosters.length == 0) {
          onAddRoster(res, selInfo, false);
        } else {
          onAddRoster(res, selRosters, true);
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };

  const onAddRoster = async (list, selInfo, multiple) => {
    var resInfo = await showRosterAddModal({
      title: "Add Participant to team",
      token: userDataStore.token,
      divisionList: list,
      selRoster: selInfo,
      multiple: multiple,
    });
    if (resInfo == null) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("division_id", resInfo.division_id);
    params.append("team_id", resInfo.team_id);
    params.append("participant_id", resInfo.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiCreateRoster(params)
      .then((res) => {
        setIsBusy(false);
        let tmpList = [...userList];
        if (multiple) {
          getParticipantAll(curOption);
          setSelectedRowKeys([]);
        } else {
          setUserList(tmpList.filter((x) => x.id != resInfo.id));
        }
        getData();
        setSelRosters([]);
        message.success("Participant has been added successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onInviteRoster = async (info) => {
    if (info.locked == "0") {
      var res = await showMyAlertDialog({
        title: "Invitation Failed",
        content: "Team has not yet been created in BeClutch",
      });
      if (res == false) return;
      return;
    }
    if (info.invite_avialble.length == 0) {
      var res = await showMyAlertDialog({
        title: "Invitation Failed",
        content: "There are no available participants",
      });
      if (res == false) return;
      return;
    }
    onSendInvite(info);
  };
  const onSendInvite = async (info) => {
    var res = await showProgressbarDlg({
      content: "Sending invitation...",
      info: info,
      token: userDataStore.token,
    });
    if (res == null) return;
    if (res == false) {
      message.error("Invitation failed");
      return;
    }
    setIsBusy(true);
    const params1 = new URLSearchParams();
    params1.append("token", userDataStore.token);
    params1.append("invite_ids", res);
    await apiUpdateRosterInvitation(params1)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("Invitation has been sent successfully.");
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onDeleteRoster = (info) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("id", info.ID);
    setIsBusy(true);
    apiDeleteRoster(params)
      .then((res) => {
        setIsBusy(false);
        let tmpList = [...divisionList];
        let selDivisionIndex = tmpList.findIndex(
          (x) => x.ID == info.division_id
        );
        let selDivision = tmpList[selDivisionIndex];
        let availableTeams = [...selDivision.children];
        let selTeamIndex = availableTeams.findIndex(
          (x) => x.ID == info.team_id
        );
        let selTeam = availableTeams[selTeamIndex];
        let tmpRosterList = selTeam.children.filter((x) => x.ID != info.ID);
        selTeam["children"] = tmpRosterList;
        availableTeams[selTeamIndex] = selTeam;
        selDivision["children"] = availableTeams;
        selDivision["roster_count"] = selDivision["roster_count"] - 1;
        tmpList[selDivisionIndex] = selDivision;
        setDivisionList(tmpList);
        getParticipantAll();
        setCurOption("");
        message.success("Participant has been removed successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="registration-page">
          <ClubBoxWidget />
          <div>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <div className="club-box">
              <span className="club-name" style={{ paddingRight: 32 }}>
                Registration Group:
              </span>
              <Select
                className="select-club"
                style={{ width: 300 }}
                placeholder="Filter By"
                value={curOption == "" ? null : curOption}
                onSelect={(v) => {
                  setCurOption(v);
                  getParticipantAll(v);
                }}
              >
                {optionList != undefined &&
                  optionList.length > 0 &&
                  optionList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo} key={index}>
                        {optionInfo}
                      </Option>
                    );
                  })}
              </Select>
              <CommonButton
                style={{ marginLeft: 30 }}
                onClick={() => {
                  setCurOption("");
                  getParticipantAll();
                }}
              >
                Clear
              </CommonButton>
            </div>
            <Row gutter={32} style={{ marginTop: 20 }}>
              <Col sm={24} md={12}>
                <RegUserTable
                  dataList={userList}
                  addUser={onAddUser}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  selRosters={selRosters}
                  setSelectedUsers={setSelRosters}
                />
              </Col>
              <Col sm={24} md={12}>
                <div>
                  <div className="ant-table">
                    <div className="ant-table-container">
                      <div className="ant-table-content">
                        <table style={{ tableLayout: "auto" }}>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell ant-table-row-expand-icon-cell"></th>
                              <th className="ant-table-cell">Division Name</th>
                              <th className="ant-table-cell ant-table-row-action">
                                Count
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            {divisionList.length > 0 ? (
                              divisionList.map((x, index) => {
                                return (
                                  <DivisionRow
                                    userInfo={x}
                                    key={x.index}
                                    indexKey={key}
                                    indexID={index}
                                    deleteRoster={onDeleteRoster}
                                    inviteRoster={onInviteRoster}
                                  />
                                );
                              })
                            ) : (
                              <DivisionEmpty />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </CommonDivWidget>
  );
}

export default RosterPage;
