import { logoUrl } from "config/CONSTANTS";
import React from "react";
import "./AuthDialogWidget.css";
function AuthDialogWidget(props) {
  return (
    <div className="auth-dialog-widget">
      <img src={logoUrl} className="auth-logo" />
      <div className={"title"}>{props.title}</div>
      <div className={"sub_title"}>Club Administration</div>
      {props.children}
    </div>
  );
}

export default AuthDialogWidget;
