// All user related database operations can be defined here.
import axios from "axios";
import { REGISTER_WITH, SYSTEM_ERROR } from "config/CONSTANTS";
import { axiosGet, axiosPut } from "./ajaxServices";
import {
  urlUserLogin,
} from "./CONSTANTS";

export const apiLogin = (userDetail) => {
  const { register_with, name, phone, password } = userDetail;
  const url = urlUserLogin;
  let param = { password: password, register_with: register_with };
  if (register_with === REGISTER_WITH.email) {
    param["email"] = name;
  } else {
    param["phone"] = phone;
  }
 
  // const config = {
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  // };
  var params = new URLSearchParams();
  params.append("email", param.email)
  params.append("pwd", param.password)

  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
        .post(url, params)
        .then((res) => {
          const { data } = res;
          if (data.result === true) {
            resolve(data);
          } else {
            reject(data.msg);
          }
        })
        .catch((err) => {
          reject("Error in Api axios!");
        });
    } catch (error) {
      console.error("in userServices > apiLogin, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

