import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import {
  Switch,
  Row,
  Col,
  message,
  Select,
  Checkbox,
  Input,
  Spin,
  DatePicker,
  TimePicker,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./EventEditModal.css";
import { isEmpty, weekDay } from "utils/GlobalFunctions";
import moment from "moment";
import {
  apiAddoneEvent,
  apiCreateClubEvent,
  apiCreateEvent,
  apiGetLocation,
  apiGetTeamList,
  apiUpdateEvent,
} from "services/divisionService";
import { showLocationModal } from "../LocationModal/showLocationModal";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const { Option } = Select;
function EventEditModal(props) {
  const { show, proceed, title, token, divisionList, step, info } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [oneEvent, setOneEvent] = useState({
    event_type: "Game",
    start_time: "",
    arrival_time: "",
    duration: "",
    memo: "",
    notify: "0"
  });
  const [errorField, setErrorField] = useState([]);
  const [homeTeamList, setHomeTeamList] = useState([]);
  const [awayTeamList, setAwayTeamList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  useEffect(() => {
    getLocationData();
  }, []);
  useEffect(() => {
    if (info != undefined) {
      let oneDay = info.DATE_DAY_OF_MONTH;
      if (oneDay.length == 1 && parseInt(oneDay) < 10) {
        oneDay = "0" + oneDay.toString();
      }
      setOneEvent({
        ...oneEvent,
        division_id: info.division_id,
        week: info.DAY_OF_WEEK.length == 1 ? parseInt(info.DAY_OF_WEEK) : info.DAY_OF_WEEK,
        event_date: info.DATE_MONTH + "-" + oneDay + "-" + info.DATE_YEAR,
        start_time:
          info.START_TIME_HOUR +
          ":" +
          info.START_TIME_MIN +
          " " +
          info.START_TIME_AMPM,
        duration: info.DURATION_HOUR + ":" + info.DURATION_MIN,
        location: info.LOCATION_ID,
        memo: info.MEMO,
        notify: info.NEED_NOTIFY,
        arrival_time:
          info.ARRIVAL_TIME_HOUR +
          ":" +
          info.ARRIVAL_TIME_MIN +
          " " +
          info.ARRIVAL_TIME_AMPM,
      });
    }
  }, [info]);
  useEffect(() => {
    if (oneEvent.division_id != undefined) {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", token);
      params.append("division_id", oneEvent.division_id);
      params.append("locked", true);
      setIsBusy(true);
      apiGetTeamList(params)
        .then((res) => {
          setIsBusy(false);
          let tmpAwayRes = [...res];
          let tmpHomeRes = [...res];
          let tmpAwayTeamid = tmpAwayRes.filter(
            (x) => x.beclutch_opponent_id == info.opponent_detail.ID
          )[0].ID;
          let tmpHomeTeamid = tmpHomeRes.filter(
            (x) => x.beclutch_team_id == info.TEAM_ID
          )[0].ID;
          setOneEvent({
            ...oneEvent,
            hometeam_id: tmpHomeTeamid,
            awayteam_id: tmpAwayTeamid,
          });
          setHomeTeamList(res);
          setAwayTeamList(res);
        })
        .catch((err) => {
          message.error(err);
          setIsBusy(false);
        });
    }
  }, [oneEvent.division_id]);
  const getLocationData = () => {
    const params = new URLSearchParams();
    params.append("token", token);
    setIsBusy(true);
    apiGetLocation(params)
      .then((res) => {
        setIsBusy(false);
        setLocationList(res);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onLocationDlg = async () => {
    let dlgRes = await showLocationModal({
      title: "Add New Location",
      token: token,
    });
    if (dlgRes == null) return;
    getLocationData();
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(oneEvent, "event_type", errorList);
    errorList = isEmpty(oneEvent, "division_id", errorList);
    errorList = isEmpty(oneEvent, "hometeam_id", errorList);
    errorList = isEmpty(oneEvent, "awayteam_id", errorList);
    errorList = isEmpty(oneEvent, "event_date", errorList);
    errorList = isEmpty(oneEvent, "start_time", errorList);
    if (step) {
      errorList = isEmpty(oneEvent, "arrival_time", errorList);
    }
    errorList = isEmpty(oneEvent, "duration", errorList);
    errorList = isEmpty(oneEvent, "location", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if (!validateFields()) return;
    let home_team_id =
      homeTeamList[homeTeamList.findIndex((x) => x.ID == oneEvent.hometeam_id)]
        .beclutch_team_id;
    let home_oppo_id =
      homeTeamList[homeTeamList.findIndex((x) => x.ID == oneEvent.hometeam_id)]
        .beclutch_opponent_id;
    let away_team_id =
      awayTeamList[awayTeamList.findIndex((x) => x.ID == oneEvent.awayteam_id)]
        .beclutch_team_id;
    let away_oppo_id =
      awayTeamList[awayTeamList.findIndex((x) => x.ID == oneEvent.awayteam_id)]
        .beclutch_opponent_id;
    let tmpLocation =
      locationList[locationList.findIndex((x) => x.ID == oneEvent.location)];
    let addr =
      tmpLocation.LOCATION_STREET +
      ", " +
      tmpLocation.LOCATION_CITY +
      ", " +
      tmpLocation.LOCATION_STATE +
      ", " +
      tmpLocation.LOCATION_ZIP;
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("id", info.ID);
    params.append("home_team_id", home_team_id);
    params.append("away_team_id", away_team_id);
    params.append("home_opponent_id", home_oppo_id);
    params.append("away_opponent_id", away_oppo_id);
    params.append("year", oneEvent.event_date.split("-")[2]);
    params.append("month", oneEvent.event_date.split("-")[0]);
    params.append("day_of_month", oneEvent.event_date.split("-")[1]);
    params.append(
      "start_time_hour",
      oneEvent.start_time.split(" ")[0].split(":")[0]
    );
    params.append(
      "start_time_min",
      oneEvent.start_time.split(" ")[0].split(":")[1]
    );
    params.append("start_time_ampm", oneEvent.start_time.split(" ")[1]);
    params.append(
      "arrival_time_hour",
      oneEvent.arrival_time.split(" ")[0].split(":")[0]
    );
    params.append(
      "arrival_time_min",
      oneEvent.arrival_time.split(" ")[0].split(":")[1]
    );
    params.append("arrival_time_ampm", oneEvent.arrival_time.split(" ")[1]);
    params.append("location_id", oneEvent.location);
    params.append("addr", addr);
    params.append("memo", oneEvent.memo);
    params.append("notify", oneEvent.notify);
    params.append("duration_hour", oneEvent.duration.split(":")[0]);
    params.append("duration_min", oneEvent.duration.split(":")[1]);
    params.append("day_of_week", getDay(oneEvent.week));
    setIsBusy(true);
    apiUpdateEvent(params)
      .then((res) => {
        setIsBusy(false);
        proceed(oneEvent);
        message.success("Event has been edited successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getDay = (num) => {
    let day = num;
    switch (num) {
      case 0:
        day = "Sun";
        break;
      case 1:
        day = "Mon";
        break;
      case 2:
        day = "Tue";
        break;
      case 3:
        day = "Wed";
        break;
      case 4:
        day = "Thu";
        break;
      case 5:
        day = "Fri";
        break;
      case 6:
        day = "Sat";
        break;
    }
    return day;
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="event-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText={info == undefined ? "Add" : "Edit"}
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        <Row style={{ marginTop: 10 }}>
          <div style={{ paddingBottom: "5px" }}>Event Type*</div>
          <Input
            value={oneEvent.event_type}
            disabled
            className={
              errorField !== undefined && errorField.includes("event_type")
                ? `common-input-error`
                : `common-input event-type`
            }
            onChange={(e) => {
              if (errorField.includes("event_type")) {
                let errors = errorField.filter((x) => x != "event_type");
                setErrorField([...errors]);
              }
              setOneEvent({ ...oneEvent, event_type: e.target.value });
            }}
          />
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={24}>
            <div style={{ paddingBottom: "5px" }}>Division</div>
            <Select
              placeholder="Choose Division"
              showSearch
              className={
                errorField !== undefined && errorField.includes("division_id")
                  ? `group-select-error`
                  : `group-select`
              }
              value={
                oneEvent.division_id == undefined ? null : oneEvent.division_id
              }
              onSelect={(v1, v2) => {
                if (errorField.includes("division_id")) {
                  let errors = errorField.filter((x) => x != "division_id");
                  setErrorField([...errors]);
                }
                setOneEvent({ ...oneEvent, division_id: v1 });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {divisionList.map((x, index) => {
                return (
                  <Option value={x.ID} key={index}>
                    {x.division_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} gutter={16}>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Home Team</div>
            <Select
              placeholder="Choose Home Team"
              showSearch
              className={
                errorField !== undefined && errorField.includes("hometeam_id")
                  ? `group-select-error`
                  : `group-select`
              }
              value={
                oneEvent.hometeam_id == undefined ? null : oneEvent.hometeam_id
              }
              onSelect={(v1, v2) => {
                if (errorField.includes("hometeam_id")) {
                  let errors = errorField.filter((x) => x != "hometeam_id");
                  setErrorField([...errors]);
                }
                if (
                  oneEvent.awayteam_id == undefined ||
                  oneEvent.awayteam_id != v1
                ) {
                  setOneEvent({ ...oneEvent, hometeam_id: v1 });
                } else {
                  message.error("Please select other team");
                }
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {homeTeamList.map((x, index) => {
                return (
                  <Option value={x.ID} key={index}>
                    {x.team_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Away Team</div>
            <Select
              placeholder="Choose Away Team"
              showSearch
              className={
                errorField !== undefined && errorField.includes("awayteam_id")
                  ? `group-select-error`
                  : `group-select`
              }
              value={
                oneEvent.awayteam_id == undefined ? null : oneEvent.awayteam_id
              }
              onSelect={(v1, v2) => {
                if (errorField.includes("awayteam_id")) {
                  let errors = errorField.filter((x) => x != "awayteam_id");
                  setErrorField([...errors]);
                }
                if (
                  oneEvent.hometeam_id == undefined ||
                  oneEvent.hometeam_id != v1
                ) {
                  setOneEvent({ ...oneEvent, awayteam_id: v1 });
                } else {
                  message.error("Please select other team");
                }
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {awayTeamList.map((x, index) => {
                return (
                  <Option value={x.ID} key={index}>
                    {x.team_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: 10 }}>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Event Date*</div>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={
                errorField !== undefined && errorField.includes("event_date")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.event_date == undefined
                  ? ""
                  : moment(oneEvent.event_date, "MM-DD-YYYY")
              }
              onChange={(e, str) => {
                if (errorField.includes("event_date")) {
                  let errors = errorField.filter((x) => x != "event_date");
                  setErrorField([...errors]);
                }
                let week = weekDay(e.weekday());
                setOneEvent({ ...oneEvent, event_date: str, week: week });
              }}
              format="MM-DD-YYYY"
            />
          </Col>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Start Time*</div>
            <TimePicker
              format={"hh:mm A"}
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={
                errorField !== undefined && errorField.includes("start_time")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.start_time == ""
                  ? ""
                  : moment(oneEvent.start_time, "hh:mm A")
              }
              onChange={(time, timeStr) => {
                setOneEvent({
                  ...oneEvent,
                  start_time: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Arrival Time*</div>
            <TimePicker
              format={"hh:mm A"}
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={
                errorField !== undefined && errorField.includes("arrival_time")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.arrival_time == ""
                  ? ""
                  : moment(oneEvent.arrival_time, "hh:mm A")
              }
              onChange={(time, timeStr) => {
                setOneEvent({
                  ...oneEvent,
                  arrival_time: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Duration</div>
            <TimePicker
              format={"HH:mm"}
              placeholder="0 hour(s) 00 minute(s)"
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              showNow={false}
              className={
                errorField !== undefined && errorField.includes("duration")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.duration == ""
                  ? ""
                  : moment(oneEvent.duration, "HH:mm")
              }
              onChange={(time, timeStr) => {
                setOneEvent({
                  ...oneEvent,
                  duration: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Location</div>
            <Row align="middle" gutter={16}>
              <Col style={{ width: "calc(100% - 85px)" }}>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("location")
                      ? `group-select-error`
                      : `group-select`
                  }
                  value={
                    oneEvent.location == undefined ? null : oneEvent.location
                  }
                  style={{ width: "100%" }}
                  onSelect={(v) => {
                    if (errorField.includes("location")) {
                      let errors = errorField.filter((x) => x != "location");
                      setErrorField([...errors]);
                    }
                    setOneEvent({ ...oneEvent, location: v });
                  }}
                >
                  {locationList.map((x, index) => {
                    return (
                      <Option value={x.ID} key={index}>
                        <div>
                          <div>{x.LOCATION_NAME}</div>
                          <div style={{ color: "#6e6e6e", fontSize: 10 }}>
                            {x.LOCATION_STREET}, {x.LOCATION_CITY},{" "}
                            {x.LOCATION_STATE}, {x.LOCATION_ZIP}
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                <div style={{ textAlign: "end" }}>
                  <CommonButton
                    style={{ padding: "8px 20px" }}
                    onClick={() => {
                      onLocationDlg();
                    }}
                  >
                    Add
                  </CommonButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={24} sm={24}>
            <div style={{ paddingBottom: "5px" }}>Memo</div>
            <Input
              value={oneEvent.memo}
              className="common-input"
              onChange={(e) => {
                setOneEvent({ ...oneEvent, memo: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={24} sm={24}>
            <Checkbox
              onChange={(e) => {
                setOneEvent({ ...oneEvent, notify: e.target.checked ? "1" : "0" });
              }}
              checked={oneEvent.notify == "1"}
            >
              Notify
            </Checkbox>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(EventEditModal);
