import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Empty, message, Select, Spin } from "antd";

import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./RegistrationPage.css";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import {
  apiDeleteRegInfo,
  apiGetRegGroupData,
  apiGetRegistrationDetail,
  apiGetRegistrationDetailByGroup,
  apiGetRegistrationDetailByLabel,
  apiGetRegistrationList,
  apiUpdateCampRegInfo,
  apiUpdateRegInfo,
} from "services/dashboardService";
import RegFormTable from "./RegFormTable/RegFormTable";
import SearchWidget from "./SearchWidget/SearchWidget";
import GroupTable from "./GroupTable/GroupTable";
import RightDetailWidget from "./RightDetailWidget/RightDetailWidget";
import CampRightDetailWidget from "./RightDetailWidget/CampRightDetailWidget";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_REGISTRATION_DETAIL } from "navigation/CONSTANTS";
const RegistrationPage = (props) => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const history = useHistory()
  const [isBusy, setIsBusy] = useState(false);
  const [userList, setUserList] = useState([]);
  const [srcList, setSrcList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [srcGroupList, setSrcGroupList] = useState([]);
  const [groupVal, setGroupVal] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isGroup, setIsGroup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState({});
  const [groupLabel, setGroupLabel] = useState("");
  const [telePhone, setTelephone] = useState("___-___-____");
  const [homePhone, setHomephone] = useState("___-___-____");
  const [cellPhone, setCellphone] = useState("___-___-____");
  const [contactPhone, setContactPhone] = useState("___-___-____");
  const [regGroupList, setRegGroupList] = useState([]);
  useEffect(() => {
    if (appDataStore.curRegistration != undefined) {
      getRegGroupData();
      getRegistrationDetail();
    } else {
      setUserList([]);
      setSrcList([]);
    }
  }, [appDataStore]);

  useEffect(() => {
    if (appDataStore.curRegistration == undefined) {
      setGroupVal("");
      return;
    }
    if (groupVal != "") {
      setIsGroup(true);
      getRegistrationByGroupDetail(groupVal);
      let label = "";
      if (groupVal == "fee_label") {
        label = "Registration Group";
      } else if (groupVal == "timestamp") {
        label = "Reg Date";
      } else if (groupVal == "gender") {
        label = "Gender";
      } else if (groupVal == "discount_code") {
        label = "Discount Code";
      } else if (groupVal == "payment_method") {
        label = "Payment method";
      }
      setGroupLabel(label);
    } else {
      setGroupLabel("");
    }
  }, [groupVal]);

  const onClear = () => {
    setSearchKey("");
    setGroupVal("");
    setIsGroup(false);
    getRegistrationDetail();
  };

  const onTransactionDetail = () => {
    history.push(ROUTE_REGISTRATION_DETAIL)
  }

  const onSearch = (v) => {
    setSearchKey(v);
    let tmpList = [];
    if (isGroup) {
      tmpList = [...srcGroupList];
    } else {
      tmpList = [...srcList];
    }
    if (isGroup) {
      if (v == "") {
        setGroupList(tmpList);
      } else {
        setGroupList(
          tmpList.filter((x) => {
            if (x.fee_label.toLowerCase().includes(v.toLowerCase())) {
              return x;
            }
          })
        );
      }
    } else {
      if (v == "") {
        setUserList(tmpList);
      } else {
        if (userDataStore?.sport_type == "camp") {
          tmpList = tmpList.filter((x) => {
            if (x.name.toLowerCase().includes(v.toLowerCase())) {
              return x;
            }
          });
        } else {
          tmpList = tmpList.filter((x) => {
            if (
              x.p_email.toLowerCase().includes(v.toLowerCase()) ||
              x.name.toLowerCase().includes(v.toLowerCase())
            ) {
              return x;
            }
          });
        }
        setUserList(tmpList);
      }
    }
  };

  const getRegGroupData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", appDataStore.curRegistration.ID);
    apiGetRegGroupData(params)
      .then((res) => {
        if (res.length > 0) {
          setRegGroupList(res);
        } else {
          setRegGroupList([]);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const getRegistrationDetail = () => {
    setUserList([]);
    setSrcList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiGetRegistrationDetail(params)
      .then((res) => {
        setUserList(res.detail);
        setSrcList(res.detail);
        let tmpList = [...res.group_list];
        let tmpSrcList = tmpList.map((x, index) => {
          return {
            id: x.ID,
            title: x.registration_group_short_name,
            description: x.registration_group_name,
            price: x.registration_group_fee,
            checked: false,
            discount_available: x.discount_available,
          };
        });
        setTripList(tmpSrcList);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const getRegistrationByGroupDetail = (group) => {
    setGroupList([]);
    setSrcGroupList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", appDataStore.curRegistration.ID);
    params.append("group", group);
    setIsBusy(true);
    apiGetRegistrationDetailByGroup(params)
      .then((res) => {
        setGroupList(res);
        setSrcGroupList(res);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const updateData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", userData.id);
    params.append("first_name", userData.first_name);
    params.append("last_name", userData.last_name);
    params.append("tele_phone", userData.tele_phone);
    params.append("birthday", userData.birthday);
    params.append("address", userData.address);
    params.append("city", userData.city);
    params.append("state", userData.state);
    params.append("gender", userData.gender);
    params.append("zip", userData.zip);
    params.append("grade", userData.grade);
    params.append("fee_label", userData.fee_label);
    params.append("p_first_name", userData.p_first_name);
    params.append("p_home_phone", userData.p_home_phone);
    params.append("p_last_name", userData.p_last_name);
    params.append("p_cell_phone", userData.p_cell_phone);
    params.append("p_relation", userData.p_relation);
    params.append("p_email", userData.p_email);
    params.append("c_first_name", userData.c_first_name);
    params.append("c_last_name", userData.c_last_name);
    params.append("c_email", userData.c_email);
    params.append("c_cell_phone", userData.c_cell_phone);
    setIsBusy(true);
    apiUpdateRegInfo(params)
      .then((res) => {
        let selData = {
          ...userData,
          name: userData.first_name + " " + userData.last_name,
        };
        let tmpList = [...userList];
        let selId = tmpList.findIndex((x) => (x.id == userData.id));
        tmpList[selId] = selData;
        setUserList(tmpList);
        let tmpSrcList = [...srcList];
        let selsrcId = tmpSrcList.findIndex((x) => (x.id == userData.id));
        tmpSrcList[selsrcId] = selData;
        setSrcList(tmpSrcList);
        message.success("Updated successfully.");
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const updateCampData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", userData.id);

    params.append("camper_first_name", userData.camper_first_name);
    params.append("camper_last_name", userData.camper_last_name);
    params.append("camper_age", userData.camper_age);
    params.append("camper_grade", userData.camper_grade);
    params.append("camper_street", userData.camper_street);
    params.append("camper_city", userData.camper_city);
    params.append("camper_state", userData.camper_state);
    params.append("camper_zip", userData.camper_zip);
    params.append("camper_t_shirt", userData.camper_t_shirt);
    params.append("camper_health", userData.camper_health);
    params.append("camper_sel_trip", userData.camper_sel_trip);
    params.append("p_first_name", userData.p_first_name);
    params.append("p_last_name", userData.p_last_name);
    params.append("p_relation", userData.p_relation);
    params.append("p_email", userData.p_email);
    params.append("p_cell_phone", userData.p_cell_phone);
    params.append("p_home_phone", userData.p_home_phone);
    params.append("c_first_name", userData.c_first_name);
    params.append("c_last_name", userData.c_last_name);
    params.append("c_email", userData.c_email);
    params.append("c_cell_phone", userData.c_cell_phone);
    setIsBusy(true);
    apiUpdateCampRegInfo(params)
      .then((res) => {
        let selData = {
          ...userData,
          name: userData.camper_first_name + " " + userData.camper_last_name,
        };
        let tmpList = [...userList];
        let selId = tmpList.findIndex((x) => (x.id == userData.id));
        tmpList[selId] = selData;
        setUserList(tmpList);
        let tmpSrcList = [...srcList];
        let selsrcId = tmpSrcList.findIndex((x) => (x.id == userData.id));
        tmpSrcList[selsrcId] = selData;
        setSrcList(tmpSrcList);
        message.success("Updated successfully.");
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const deleteData = (id) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", id);
    setIsBusy(true);
    apiDeleteRegInfo(params)
      .then((res) => {
        let tmpList = [...userList];
        setUserList(tmpList.filter((x) => x.id != id));
        let tmpSrcList = [...srcList];
        setSrcList(tmpSrcList.filter((x) => x.id != id));
        message.success("Deleted successfully.");
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onDetail = (label) => {
    setUserList([]);
    setSrcList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("label", label);
    params.append("type", groupVal);
    setIsBusy(true);
    apiGetRegistrationDetailByLabel(params)
      .then((res) => {
        if (res.detail != undefined) {
          setUserList(res.detail);
          setSrcList(res.detail);
        }
        setIsBusy(false);
        setIsGroup(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onVisible = (info) => {
    setVisible(true);
    setTelephone(info.tele_phone);
    setHomephone(info.p_home_phone);
    setCellphone(info.p_cell_phone);
    setContactPhone(info.c_cell_phone);
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="registration-page">
          <ClubBoxWidget />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <SearchWidget
              dataList={userList}
              groupList={groupList}
              isGroup={isGroup}
              groupVal={groupVal}
              setGroupVal={setGroupVal}
              onClear={onClear}
              onTransactionDetail={onTransactionDetail}
              onSearch={onSearch}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              sport_type={userDataStore?.sport_type}
            />
            {isGroup ? (
              <GroupTable
                dataList={groupList}
                group={groupLabel}
                onDetail={onDetail}
                sport_type={userDataStore?.sport_type}
              />
            ) : (
              <RegFormTable
                dataList={userList}
                setVisible={onVisible}
                setUserData={setUserData}
                deleteData={deleteData}
                sport_type={userDataStore?.sport_type}
              />
            )}
          </div>
          {userDataStore?.sport_type == "camp" ? (
            <CampRightDetailWidget
              visible={visible}
              setVisible={setVisible}
              userData={userData}
              setUserData={setUserData}
              updateData={updateCampData}
              telePhone={telePhone}
              setTelephone={setTelephone}
              homePhone={homePhone}
              setHomephone={setHomephone}
              cellPhone={cellPhone}
              setCellphone={setCellphone}
              contactPhone={contactPhone}
              setContactPhone={setContactPhone}
              regGroupList={regGroupList}
              tripList={tripList}
            />
          ) : (
            <RightDetailWidget
              visible={visible}
              setVisible={setVisible}
              userData={userData}
              setUserData={setUserData}
              updateData={updateData}
              telePhone={telePhone}
              setTelephone={setTelephone}
              homePhone={homePhone}
              setHomephone={setHomephone}
              cellPhone={cellPhone}
              setCellphone={setCellphone}
              contactPhone={contactPhone}
              setContactPhone={setContactPhone}
              regGroupList={regGroupList}
            />
          )}
        </div>
      </Spin>
    </CommonDivWidget>
  );
};

export default RegistrationPage;
