import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TeamRow from "./TeamRow";
import TeamEmpty from "./TeamEmpty";

function DivisionRow(props) {
    const { userInfo, indexKey, indexID, deleteRoster, inviteRoster } = props;
    const [expanded, setExpand] = useState(false)
    const toggleExpander = () => {
        if (!expanded) {
            setExpand(true)
        } else {
            setExpand(false)
        }
    }

    // useEffect(() => {
    //     if (indexKey != undefined && indexID != undefined) {
    //         if (indexKey == indexID) {
    //             setExpand(true)
    //         }
    //     }
    // }, [indexID])

    return (
        <>
            <tr key={Date.now()} className="ant-table-row ant-table-row-level-0 parent-table-row">
                <td className="ant-table-cell ant-table-row-expand-icon-cell">
                    {expanded ? <MinusSquareOutlined style={{ fontSize: 20 }} onClick={() => {
                        toggleExpander()
                    }} /> : <PlusSquareOutlined style={{ fontSize: 20 }} onClick={() => {
                        toggleExpander()
                    }} />}

                    {/* <button type="button" className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Collapse row"></button>
                    <button type="button" class="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Expand row"></button> */}
                </td>
                <td className="ant-table-cell">{userInfo.division_name}</td>
                <td className="ant-table-cell">{userInfo.roster_count}</td>
            </tr>
            {expanded && <>
                {userInfo.children.length > 0 ?
                    <>
                        <tr  key={Date.now()} className="ant-table-expanded-row ant-table-expanded-row-level-1">
                            <td colSpan="4" className="ant-table-cell">
                                <div className="ant-table-wrapper">
                                    <div className="ant-spin-nested-loading">
                                        <div className="ant-spin-container">
                                            <div className="ant-table">
                                                <div className="ant-table-container">
                                                    <div className="ant-table-content">
                                                        <table style={{ tableLayout: 'auto' }}>
                                                            <thead className="ant-table-thead">
                                                                <tr>
                                                                    <th className="ant-table-cell ant-table-row-expand-icon-cell"></th>
                                                                    <th className="ant-table-cell">Teams</th>
                                                                    <th className="ant-table-cell">Count</th>
                                                                    <th className="ant-table-cell ant-table-row-action">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ant-table-tbody">
                                                                {userInfo.children.map((x, index) => {
                                                                    return <TeamRow
                                                                        userInfo={x}
                                                                        key={index}
                                                                        deleteRoster={deleteRoster}
                                                                        inviteRoster={inviteRoster} />
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                    : <TeamEmpty />
                }
            </>}
        </>
    )
}
export default DivisionRow;