import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Layout, Menu, Divider } from "antd";
import { MENU_ITEMS, GetCurrentMainRoute, } from "navigation/CONSTANTS";
import collapseIcon from '../../assets/icons/ic_collapse.svg'
import "./SidebarWidget.css";

import { IconWidget } from "components/IconWidget/IconWidget";

import { ChangeMenuCollapsed, UpdateCurrentRoot } from "redux/actions/appActions";

const SidebarWidget = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const appDataStore = useSelector(x => x.appDataStore);
    const userDataStore = useSelector(x => x.userDataStore);
    const { collapsed, width } = appDataStore;
    const url = history.location.pathname;
    const mainRoute = GetCurrentMainRoute(url);
    const menuUI = MENU_ITEMS.map((info, index) => {
        return <Menu.Item className="c-menu-item" key={info.route} icon={info.icon} onClick={() => { history.push(info.route) }}>
            {info.title}
        </Menu.Item>
    })

    const onToggleCollapsed = () => {
        dispatch(ChangeMenuCollapsed({ collapsed: !collapsed }))
    }
    return (
        <div>
            <div className="logo">
                {
                    !collapsed ?
                        <div>
                            <img
                                src={'/assets/images/logo-large.png'}
                                alt="BeClutch"
                                className="logo-img"
                            />
                            <span style={{ float: 'right', fontSize: '1.0rem' }}>Ver 0.1.0</span>
                        </div>
                        : <div>
                            <div style={{ marginTop: 30, marginBottom: 10 }}><span style={{ fontSize: '1.4rem', fontFamily: "semiBoldFont", marginLeft: 0 }}>BeClutch</span></div>
                            <div><span style={{ fontSize: '1.0rem', marginLeft: 0 }}>Ver 0.1.0</span></div>
                        </div>
                }
                {
                    width > 1024 ?
                        <div className="side-collapse" onClick={() => { onToggleCollapsed(); }}>
                            <IconWidget Icon={collapseIcon} />
                        </div>
                        : <div></div>}
            </div>
            <Divider />
            <div className="layout-store-name"></div>
            <Menu
                mode="inline"
                defaultSelectedKeys={[mainRoute]}
                selectedKeys={[mainRoute]}
                className="c-menu"
                onSelect={(v) => {
                    dispatch(UpdateCurrentRoot({ currentRoute: v.key }))
                }}
            >
                {menuUI}
            </Menu>
            <Layout>
                {props.children}
            </Layout>
        </div>
    );
};

export default SidebarWidget;