import React from "react";
import { withRouter } from "react-router";
import { Col, Row, Table } from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
function StageTable(props) {
  const { dataList, editInfo, deleteInfo } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "Home Team",
      dataIndex: "home_team_name",
      key: "home_team_name",
    },
    {
      title: "Away Team",
      dataIndex: "away_team_name",
      key: "away_team_name",
    },

    {
      title: "Date",
      key: "start_date",
      dataIndex: "start_date",
    },
    {
      title: "Time",
      key: "start_time",
      dataIndex: "start_time",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
    },
    {
      title: "Division",
      dataIndex: "division",
      key: "division",
    },
    {
      title: "Created Date",
      key: "created",
      render: (record) => {
        var date = new Date(record.created_at * 1000);
        // Hours part from the timestamp
        var day = date.getDate();
        // Minutes part from the timestamp
        var month = date.getMonth() + 1;
        // Seconds part from the timestamp
        var year = date.getFullYear();

        var hour = date.getHours();

        var minute = date.getMinutes();

        // Will display time in 10:30:23 format
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let pm = " AM";
        if (hour >= 12) {
          pm = " PM";
        }
        if (hour > 12) {
          hour = hour - 12;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        year = year + "";
        var formattedTime =
          month +
          "/" +
          day +
          "/" +
          year.substr(-2) +
          " " +
          hour +
          ":" +
          minute +
          pm;
        return formattedTime;
      },
      sorter: (a, b) => a.created_at - b.created_at,
    },
    {
      title: "Action",
      dataIndex: "operator",
      width: 155,
      fixed: "right",
      render: (op, info) => {
        return (
          <Row gutter={16}>
            <Col>
              <a
                style={{ color: "var(--blueColor)" }}
                onClick={async () => {
                  editInfo(info);
                }}
              >
                <EditFilled /> Edit
              </a>
            </Col>
            <Col>
              <a
                style={{ color: "red" }}
                onClick={async () => {
                  var res = await showConfirmDlgWidget({
                    title: "Delete Confirmation",
                    content: "Are you sure you want to delete this game?",
                  });
                  if (res == false) return;
                  deleteInfo(info.ID);
                }}
              >
                <DeleteOutlined /> Delete
              </a>
            </Col>
          </Row>
        );
      },
    },
  ];
  const paginationProps = {
    total: dataList.length,
    showSizeChanger: true,
    defaultPageSize: 20,
    pageSizeOptions: ["10", "20", "30", "50"],
    locale: { items_per_page: "" },
  };
  return (
    <div className="schedule-list-widget" style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        dataSource={dataList}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={paginationProps}
      />
    </div>
  );
}

export default withRouter(StageTable);
