import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "navigation/NotFound";
import { BackTop } from "antd";
import {
  ROUTE_LOGIN,
  ROUTE_DASHBOARD,
  ROUTE_USER_PROFILE,
  ROOT,
  ROUTE_STORY,
  ROUTE_REGISTRATION,
  ROUTE_DIVISION,
  ROUTE_ROSTER,
  ROUTE_SCHEDULE,
  ROUTE_MESSAGE,
  ROUTE_MEMBER,
  ROUTE_STANDING,
  ROUTE_HOME_SETTING,
  ROUTE_REGISTRATION_DETAIL,
} from "navigation/CONSTANTS";
import PrivateRoute from "./Auth/PrivateRoute";
import LoginPage from "pages/LoginPage/LoginPage";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
import DashboardPage from "pages/DashboardPage/Dashboard";
import SidebarLayout from "layouts/SidebarLayout/SidebarLayout";
import { useDispatch } from "react-redux";
import { ChangeSize } from "redux/actions/appActions";
import MainLayout from "layouts/MainLayout/MainLayout";
import UserProfilePage from "pages/ProfilePage/UserProfile/UserProfilePage";
import RegistrationPage from "pages/RegistrationPage/RegistrationPage";
import DivisionPage from "pages/DivisionPage/DivisionPage";
import RosterPage from "pages/RosterPage/RosterPage";
import SchedulePage from "pages/SchedulePage/SchedulePage";
import MessagePage from "pages/MessagePage/MessagePage";
import MemberPage from "pages/MemberPage/MemberPage";
import StandingPage from "pages/StandingPage/StandingPage";
import HomeSettingPage from "pages/HomeSettingPage/HomeSettingPage";
import RegistrationTransactionPage from "pages/RegistrationPage/RegistrationTransaction/RegistrationTransactionPage";
export const RouterConfig = () => {
  const screenSize = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      ChangeSize({ width: screenSize.width, height: screenSize.height })
    );
  }, [screenSize]);

  return (
    <div>
      <Switch>
        <Route exact path={ROOT} component={LoginPage} />
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <SidebarLayout>
          <TopbarLayout>
            <MainLayout>
              <Switch>
                <PrivateRoute exact path={ROOT} component={DashboardPage} />
                <PrivateRoute
                  exact
                  path={ROUTE_DASHBOARD}
                  component={DashboardPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_HOME_SETTING}
                  component={HomeSettingPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_HOME_SETTING + "/:" + "id"}
                  component={HomeSettingPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_REGISTRATION}
                  component={RegistrationPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_REGISTRATION_DETAIL}
                  component={RegistrationTransactionPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_DIVISION}
                  component={DivisionPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_ROSTER}
                  component={RosterPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_SCHEDULE}
                  component={SchedulePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_STANDING}
                  component={StandingPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_MESSAGE}
                  component={MessagePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_MEMBER}
                  component={MemberPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTE_USER_PROFILE}
                  component={UserProfilePage}
                />
                <Route path="*" component={NotFound} />
              </Switch>

              <BackTop />
            </MainLayout>
          </TopbarLayout>
        </SidebarLayout>
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
