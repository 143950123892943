import {
  DeleteOutlined,
  EditFilled,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import React, { useEffect } from "react";
import { useState } from "react";
import RosterEmpty from "./RosterEmpty";
import RosterRow from "./RosterRow";
import TeamEmpty from "./TeamEmpty";

function TeamRow(props) {
  const { userInfo, deleteRoster, inviteRoster } = props;
  const [expanded, setExpand] = useState(false);
  const [unlockedCnt, setUnlockedCnt] = useState(0)
  useEffect(() => {
    if (userInfo != undefined && userInfo != null && userInfo.children != null) {
      let tmpList = [...userInfo.children]
      tmpList = tmpList.filter((x) => x.locked == "0")
      setUnlockedCnt(tmpList.length)
    }
  }, [userInfo])
  const toggleExpander = () => {
    if (!expanded) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  };

  return (
    <>
      <tr className="ant-table-row ant-table-row-level-0 parent-table-row">
        <td className="ant-table-cell ant-table-row-expand-icon-cell">
          {expanded ? (
            <MinusSquareOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                toggleExpander();
              }}
            />
          ) : (
            <PlusSquareOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                toggleExpander();
              }}
            />
          )}
        </td>
        <td className="ant-table-cell">{userInfo.team_name}</td>
        <td className="ant-table-cell">{userInfo.children.length}</td>
        <td className="ant-table-cell ant-table-row-action">
          <div
            className="ant-row"
            style={{ marginLeft: "-4px", marginRight: "-4px", rowGap: 0 }}
          >
            <div
              className="ant-col"
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              {userInfo.locked == "1" && (
                <Button
                  type={"primary"}
                  shape="round"
                  size={"small"}
                  onClick={async () => {
                    let res = await showConfirmDlgWidget({
                      title: "Invitation",
                      content:
                        "This will send invitations to all the unlocked players.",
                      content2:
                        "Are you sure you want to send " +
                        unlockedCnt +
                        " invitations?",
                    });
                    if (res == false) return;
                    inviteRoster(userInfo);
                  }}
                  style={{
                    color: "var(--mainColor)",
                    background: "transparent",
                  }}
                >
                  {unlockedCnt != 0 &&
                    "Invite Players to BeClutch"}
                </Button>
              )}
            </div>
          </div>
        </td>
      </tr>
      {expanded && (
        <>
          {userInfo.children.length > 0 ? (
            <>
              <tr className="ant-table-expanded-row ant-table-expanded-row-level-1">
                <td colSpan="4" className="ant-table-cell">
                  <div className="ant-table-wrapper">
                    <div className="ant-spin-nested-loading">
                      <div className="ant-spin-container">
                        <div className="ant-table">
                          <div className="ant-table-container">
                            <div className="ant-table-content">
                              <table style={{ tableLayout: "auto" }}>
                                <thead className="ant-table-thead">
                                  <tr>
                                    <th className="ant-table-cell">
                                      Participant
                                    </th>
                                    <th className="ant-table-cell">Gender</th>
                                    <th className="ant-table-cell">Status</th>
                                    <th className="ant-table-cell ant-table-row-action">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                  {userInfo.children.map((x, index) => {
                                    return (
                                      <RosterRow
                                        userInfo={x}
                                        key={index}
                                        deleteRoster={deleteRoster}
                                        inviteRoster={inviteRoster}
                                      />
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <RosterEmpty />
          )}
        </>
      )}
    </>
  );
}
export default TeamRow;
