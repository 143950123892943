import React from "react";
import { withRouter } from "react-router";
import { Empty, Switch, Table } from "antd";
import {
  showInfoAlert,
  showColumnFeature,
  showColumnStoreType,
  showColumnAction,
} from "./ClubTableColumn";
import "./ClubTable.css";
import QRGenerator from "components/QRCodeGenerator/qrGenerator";
import moment from "moment";
import { numberWithCommas } from "utils/GlobalFunctions";
function ClubTable(props) {
  const { dataList, onChangeStatus, onDetail } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "Registration Form",
      dataIndex: "name",
      render: (text) => <a onClick={onDetail}>{text}</a>,
    },
    {
      title: "Status",
      sorter: (a, b) => `${a.status}`.localeCompare(`${b.status}`),
      render: (info) => {
        return (
          <Switch
            checkedChildren="OPEN"
            unCheckedChildren="CLOSE"
            checked={info.status == "OPEN"}
            style={{ marginBottom: 10 }}
            onChange={(e) => {
              onChangeStatus(info);
            }}
          />
        );
      },
    },
    {
      title: "Participants",
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Total Fees",
      dataIndex: "fee",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a.fee - b.fee,
    },
    {
      title: "Discounts",
      dataIndex: "discount",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Net Fees",
      dataIndex: "net_fee",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a - b,
    },
    {
      title: "Refunds",
      dataIndex: "refund",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a.refund - b.refund,
    },
    {
      title: "Transfers",
      dataIndex: "transfer",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a.transfer - b.transfer,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (info) => {
        return "$" + numberWithCommas(info);
      },
      sorter: (a, b) => a.balance - b.balance,
    },
  ];

  const dataSource = dataList.map((data, index) => {
    return { ...data, key: index };
  });
  return (
    <Table
      className="club-table"
      columns={columns}
      dataSource={dataSource}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      locale={{
        emptyText: (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Registrations Found"
            ></Empty>
          </div>
        ),
      }}
      scroll={{ x: 900 }}
      pagination={false}
    />
  );
}

export default withRouter(ClubTable);
