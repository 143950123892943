import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Empty, message, Select, Spin } from "antd";

import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import {
  apiDeleteRegInfo,
  apiGetRegGroupData,
  apiGetRegistrationDetail,
  apiGetRegistrationDetailByGroup,
  apiGetRegistrationDetailByLabel,
  apiGetRegistrationList,
  apiGetRegistrationTransaction,
  apiGetRegistrationTransactionByGroup,
  apiUpdateCampRegInfo,
  apiUpdateRegInfo,
} from "services/dashboardService";
import RegFormTable from "../RegFormTable/RegFormTable";
import SearchWidget from "../SearchWidget/SearchWidget";
import GroupTable from "../GroupTable/GroupTable";
import RightDetailWidget from "../RightDetailWidget/RightDetailWidget";
import CampRightDetailWidget from "../RightDetailWidget/CampRightDetailWidget";
import TransactionSearchWidget from "./TransactionSearchWidget";
import TransactionTable from "./TransactionTable";
import TransactionGroupTable from "./TransactionGroupTable";
const RegistrationTransactionPage = (props) => {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [isBusy, setIsBusy] = useState(false);
  const [userList, setUserList] = useState([]);
  const [srcList, setSrcList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [srcGroupList, setSrcGroupList] = useState([]);
  const [groupVal, setGroupVal] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isGroup, setIsGroup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState({});
  const [groupLabel, setGroupLabel] = useState("");
  useEffect(() => {
    if (appDataStore.curRegistration != undefined) {
      getRegistrationTransactionData();
    } else {
      setUserList([]);
      setSrcList([]);
    }
  }, [appDataStore]);

  useEffect(() => {
    if (appDataStore.curRegistration == undefined) {
      setGroupVal("");
      return;
    }
    if (groupVal != "") {
      setIsGroup(true);
      getRegistrationByGroupDetail(groupVal);
      let label = "";
      if (groupVal == "description") {
        label = "Registration Group";
      }
      setGroupLabel(label);
    } else {
      setGroupLabel("");
    }
  }, [groupVal]);

  const onClear = () => {
    setSearchKey("");
    setGroupVal("");
    setIsGroup(false);
    getRegistrationTransactionData();
  };

  const onTransactionDetail = () => {
    
  }

  const onSearch = (v) => {
    setSearchKey(v);
    let tmpList = [];
    if (isGroup) {
      tmpList = [...srcGroupList];
    } else {
      tmpList = [...srcList];
    }
    if (isGroup) {
      if (v == "") {
        setGroupList(tmpList);
      } else {
        setGroupList(
          tmpList.filter((x) => {
            if (x.fee_label.toLowerCase().includes(v.toLowerCase())) {
              return x;
            }
          })
        );
      }
    } else {
      if (v == "") {
        setUserList(tmpList);
      } else {
        if (userDataStore?.sport_type == "camp") {
          tmpList = tmpList.filter((x) => {
            if (x.name.toLowerCase().includes(v.toLowerCase())) {
              return x;
            }
          });
        } else {
          tmpList = tmpList.filter((x) => {
            if (
              x.p_email.toLowerCase().includes(v.toLowerCase()) ||
              x.name.toLowerCase().includes(v.toLowerCase())
            ) {
              return x;
            }
          });
        }
        setUserList(tmpList);
      }
    }
  };

  const getRegistrationTransactionData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", appDataStore.curRegistration.ID);
    apiGetRegistrationTransaction(params)
      .then((res) => {
        setUserList(res);
        setSrcList(res);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const getRegistrationByGroupDetail = (group) => {
    setGroupList([]);
    setSrcGroupList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore?.sport_type);
    params.append("id", appDataStore.curRegistration.ID);
    params.append("group", group);
    setIsBusy(true);
    apiGetRegistrationTransactionByGroup(params)
      .then((res) => {
        setGroupList(res);
        setSrcGroupList(res);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
 
  
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="registration-page">
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <TransactionSearchWidget
              dataList={userList}
              groupList={groupList}
              isGroup={isGroup}
              groupVal={groupVal}
              setGroupVal={setGroupVal}
              onClear={onClear}
              onSearch={onSearch}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              sport_type={userDataStore?.sport_type}
            />
            {isGroup ? (
              <TransactionGroupTable
                dataList={groupList}
                group={groupLabel}
                sport_type={userDataStore?.sport_type}
              />
            ) : (
              <TransactionTable
                dataList={userList}
                setUserData={setUserData}
                sport_type={userDataStore?.sport_type}
              />
            )}
          </div>
          
        </div>
      </Spin>
    </CommonDivWidget>
  );
};

export default RegistrationTransactionPage;
