import React from "react";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExportExcelWidget = ({ excelData, fileName }) => {
  const file_type = "";
  const file_extension = ".xlsx";

  const exportToExcel = async (fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: file_type });
    FileSaver.saveAs(data, fileName + file_extension);
  };
  return (
    <>
      <CommonButton
        onClick={() => {
          if (excelData.length == 0) return;
          exportToExcel(fileName);
        }}
      >
        Excel Export
      </CommonButton>
    </>
  );
};
export default ExportExcelWidget;
