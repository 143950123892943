import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./RegFormColumn";
import "./RegFormTable.css";
import { numberWithCommas } from "utils/GlobalFunctions";

function RegFormTable(props) {
  const {
    dataList,
    sendMessage,
    setVisible,
    setUserData,
    deleteData,
    sport_type,
  } = props;
  if (dataList === undefined) return <div />;
  let columns = [];
  if (sport_type != undefined && sport_type == "camp") {
    columns = [
      {
        title: "Partipant Name",
        dataIndex: "name",
        fixed: "left",
        width: 160,
        sorter: (a, b) =>
          `${a.camper_last_name}`.localeCompare(`${b.camper_last_name}`),
      },
      {
        title: "Age",
        dataIndex: "camper_age",
        width: 90,
        sorter: (a, b) => `${a.camper_age}`.localeCompare(`${b.camper_age}`),
      },
      {
        title: "Grade",
        dataIndex: "camper_grade",
        width: 90,
        sorter: (a, b) => parseInt(a.camper_grade) - parseInt(b.camper_grade),
        render: (info) => {
          return info == 0 ? "K" : info;
        },
      },

      {
        title: "Registration Date",
        width: 150,
        dataIndex: "reg_date",
        render: (record) => {
          return record.replaceAll("-", "/");
        },
        sorter: (a, b) => a.reg_date - b.reg_date,
      },
      // {
      //   title: "Team Name",
      //   dataIndex: "team_name",
      //   width: 150,
      //   sorter: (a, b) => `${a.team_name}`.localeCompare(`${b.team_name}`),
      // },
      {
        title: "Total CheckOut",
        dataIndex: "total_checkout",
        width: 100,
        render: (info) => {
          return "$" + numberWithCommas(info);
        },
        sorter: (a, b) =>
          parseFloat(a.total_checkout) - parseFloat(b.total_checkout),
      },
      {
        title: "Fee",
        dataIndex: "processing_fee",
        width: 100,
        render: (info) => {
          return "$" + numberWithCommas(info);
        },
        sorter: (a, b) =>
          parseFloat(a.processing_fee) - parseFloat(b.processing_fee),
      },
      {
        title: "Payment Method",
        dataIndex: "payment_method",
        width: 150,
        sorter: (a, b) =>
          `${a.payment_method}`.localeCompare(`${b.payment_method}`),
      },
      {
        title: "Action",
        dataIndex: "operator",
        width: 155,
        fixed: "right",
        render: (op, info) => {
          return showColumnAction(
            info,
            setVisible,
            sendMessage,
            setUserData,
            deleteData
          );
        },
      },
    ];
  } else {
    columns = [
      {
        title: "First Name",
        dataIndex: "first_name",
        width: 130,
        sorter: (a, b) => `${a.first_name}`.localeCompare(`${b.first_name}`),
      },
      {
        title: "Last Name",
        width: 130,
        dataIndex: "last_name",
        sorter: (a, b) => `${a.last_name}`.localeCompare(`${b.last_name}`),
      },
      {
        title: "Email",
        width: 250,
        dataIndex: "email",
        sorter: (a, b) => `${a.email}`.localeCompare(`${b.email}`),
      },
      {
        title: "Telephone",
        dataIndex: "tele_phone",
        width: 150,
        sorter: (a, b) => `${a.tele_phone}`.localeCompare(`${b.tele_phone}`),
      },
      {
        title: "DOB",
        dataIndex: "birthday",
        width: 150,
        render: (info) => {
          var formattedTime = "";
          if (info !== undefined && (info != null) & (info != "")) {
            let year = info.split("-")[0];
            let month = info.split("-")[1];
            let day = info.split("-")[2];
            formattedTime = month + "/" + day + "/" + year.substr(-2);
          }

          return formattedTime;
        },
        sorter: (a, b) => `${a.birthday}`.localeCompare(`${b.birthday}`),
      },
      {
        title: "Address",
        dataIndex: "address",
        width: 150,
        sorter: (a, b) => `${a.address}`.localeCompare(`${b.address}`),
      },
      {
        title: "State",
        dataIndex: "state",
        width: 70,
        sorter: (a, b) => `${a.state}`.localeCompare(`${b.state}`),
      },
      {
        title: "City",
        dataIndex: "city",
        width: 150,
        sorter: (a, b) => `${a.city}`.localeCompare(`${b.city}`),
      },
      {
        title: "Zip",
        dataIndex: "zip",
        width: 80,
        sorter: (a, b) => `${a.zip}`.localeCompare(`${b.zip}`),
      },
      {
        title: "Grade",
        dataIndex: "grade",
        width: 70,
        sorter: (a, b) => `${a.grade}`.localeCompare(`${b.grade}`),
        render: (info) => {
          return info == 0 ? "K" : info;
        },
      },
      {
        title: "Height",
        dataIndex: "height",
        width: 70,
        sorter: (a, b) => `${a.height}`.localeCompare(`${b.height}`),
      },
      {
        title: "Experience",
        dataIndex: "experience",
        width: 150,
        sorter: (a, b) => `${a.experience}`.localeCompare(`${b.experience}`),
      },
      {
        title: "Parent First Name",
        dataIndex: "p_first_name",
        width: 150,
        sorter: (a, b) =>
          `${a.p_first_name}`.localeCompare(`${b.p_first_name}`),
      },
      {
        title: "Parent Last Name",
        dataIndex: "p_last_name",
        width: 150,
        sorter: (a, b) => `${a.p_last_name}`.localeCompare(`${b.p_last_name}`),
      },
      {
        title: "Parent Home Phone",
        dataIndex: "p_home_phone",
        width: 150,
        sorter: (a, b) =>
          `${a.p_home_phone}`.localeCompare(`${b.p_home_phone}`),
      },
      {
        title: "Parent Cell Phone",
        dataIndex: "p_cell_phone",
        width: 150,
        sorter: (a, b) =>
          `${a.p_cell_phone}`.localeCompare(`${b.p_cell_phone}`),
      },
      {
        title: "Relation",
        dataIndex: "p_relation",
        width: 150,
        sorter: (a, b) => `${a.p_relation}`.localeCompare(`${b.p_relation}`),
      },
      {
        title: "Parent Email",
        dataIndex: "p_email",
        width: 250,
        sorter: (a, b) => `${a.p_email}`.localeCompare(`${b.p_email}`),
      },
      {
        title: "Contact First Name",
        dataIndex: "c_first_name",
        width: 150,
        sorter: (a, b) =>
          `${a.c_first_name}`.localeCompare(`${b.c_first_name}`),
      },
      {
        title: "Contact Last Name",
        dataIndex: "c_last_name",
        width: 150,
        sorter: (a, b) => `${a.c_last_name}`.localeCompare(`${b.c_last_name}`),
      },
      {
        title: "Contact Email",
        dataIndex: "c_email",
        width: 250,
        sorter: (a, b) => `${a.c_email}`.localeCompare(`${b.c_email}`),
      },
      {
        title: "Contact Cell Phone",
        dataIndex: "c_cell_phone",
        width: 150,
        sorter: (a, b) =>
          `${a.c_cell_phone}`.localeCompare(`${b.c_cell_phone}`),
      },
      {
        title: "Action",
        width: 300,
        fixed: "right",
        dataIndex: "operator",
        render: (op, info) => {
          return showColumnAction(
            info,
            setVisible,
            sendMessage,
            setUserData,
            deleteData
          );
        },
      },
    ];
  }
  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
          return { ...data, key: index };
        });

  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 900 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default withRouter(RegFormTable);
