import React from 'react';
import { Modal, Spin } from 'antd';
import "./ConfirmDlgWidget.css"
import { confirmable } from 'react-confirm';
const ConfirmDlgWidget = (props) => {
    const { show, proceed, title, okText, cancelText, content, content2, className } = props;
    return (
        <div>
            <Modal
                visible={show}
                destroyOnClose={true}
                onCancel={() => {
                    proceed(false);
                }}
                closable={false}
                maskClosable={false}
                className={className == undefined ? "confirm-modal" : "confirm-modal confirm-large-modal"}
                title={title}
                okText={okText === undefined ? "Yes" : okText}
                cancelText={cancelText === undefined ? "Cancel":cancelText}
                onOk={() => {
                    proceed(true);
                }}>
                <div>
                    <p className='confirm-text'>{content}</p>
                    {content2 != undefined && <p className='confirm-text'>{content2}</p>}
                </div>
            </Modal>
        </div>
    );
};

export default confirmable(ConfirmDlgWidget);