import React from "react";
import { Typography } from "antd";
import { LinkRoute } from "components/LinkRoute";
import { ROOT } from "./CONSTANTS";

export const NotFound = () => {
  return (
    <div style={{fontSize: 30, width: '100%', height: '100%', textAlign: 'center' }}>
      <div style={{paddingTop: 200}}>
        <LinkRoute to={ROOT}>Dashboard</LinkRoute>
        <p>404: page not found!</p>
      </div>
    </div>
  );
};
