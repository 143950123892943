import Icon, {
  BarsOutlined,
  DollarCircleOutlined,
  GroupOutlined,
  PieChartOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactComponent as dashboardIcon } from "../assets/icons/ic_dashboard.svg";
import { ReactComponent as storeIcon } from "../assets/icons/ic_store.svg";
import { ReactComponent as analyticsIcon } from "../assets/icons/ic_analytics.svg";
import { ReactComponent as promotionIcon } from "../assets/icons/ic_promotion.svg";
import { ReactComponent as bestdealIcon } from "../assets/icons/ic_bestdeal.svg";
import { ReactComponent as productIcon } from "../assets/icons/ic_product.svg";
import { ReactComponent as postIcon } from "../assets/icons/ic_post.svg";
import { ReactComponent as followerIcon } from "../assets/icons/ic_follower.svg";
import { ReactComponent as subscriptionIcon } from "../assets/icons/ic_subscription.svg";
import { ReactComponent as agentIcon } from "../assets/icons/ic_agent.svg";

export const ROOT = "/";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_HOME_SETTING = "/home_setting";
export const ROUTE_USER_PROFILE = "/profile";
export const ROUTE_REGISTRATION = "/registration";
export const ROUTE_REGISTRATION_DETAIL = "/reg_transaction_detail";
export const ROUTE_DIVISION = "/division";
export const ROUTE_ROSTER = "/roster";
export const ROUTE_SCHEDULE = "/schedule";
export const ROUTE_MESSAGE = "/message";
export const ROUTE_MEMBER = "/member";
export const ROUTE_STANDING = "/standing";

export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";

export const MENU_ITEMS = [
  {
    route: ROUTE_DASHBOARD,
    icon: <Icon component={dashboardIcon} className="menu-item-icon" />,
    title: "Overview",
    type: "sidebar",
  },
  {
    route: ROUTE_REGISTRATION,
    icon: <UsergroupAddOutlined className="menu-item-icon" />,
    title: "Registration",
    type: "sidebar",
  },
  {
    route: ROUTE_DIVISION,
    icon: <GroupOutlined className="menu-item-icon" />,
    title: "Divisions & Teams",
    type: "sidebar",
  },
  {
    route: ROUTE_ROSTER,
    icon: <BarsOutlined className="menu-item-icon" />,
    title: "Rosters",
    type: "sidebar",
  },
  {
    route: ROUTE_SCHEDULE,
    icon: <ScheduleOutlined className="menu-item-icon" />,
    title: "Schedule",
    type: "sidebar",
  },
  {
    route: ROUTE_STANDING,
    icon: <PieChartOutlined className="menu-item-icon" />,
    title: "Standings",
    type: "sidebar",
  },
  {
    route: ROUTE_MESSAGE,
    icon: <Icon component={promotionIcon} className="menu-item-icon" />,
    title: "Messages",
    type: "sidebar",
  },
  {
    route: ROUTE_MEMBER,
    icon: <Icon component={followerIcon} className="menu-item-icon" />,
    title: "All Members",
    type: "sidebar",
  },
];

export const GetCurrentMainRoute = (pathname) => {
  if (pathname.startsWith(ROUTE_DASHBOARD)) return ROUTE_DASHBOARD;

  if (pathname.startsWith(ROUTE_REGISTRATION)) return ROUTE_REGISTRATION;
  if (pathname.startsWith(ROUTE_REGISTRATION_DETAIL)) return ROUTE_REGISTRATION_DETAIL;
  if (pathname.startsWith(ROUTE_DIVISION)) return ROUTE_DIVISION;
  if (pathname.startsWith(ROUTE_ROSTER)) return ROUTE_ROSTER;
  if (pathname.startsWith(ROUTE_SCHEDULE)) return ROUTE_SCHEDULE;
  if (pathname.startsWith(ROUTE_STANDING)) return ROUTE_STANDING;
  if (pathname.startsWith(ROUTE_MESSAGE)) return ROUTE_MESSAGE;
  if (pathname.startsWith(ROUTE_MEMBER)) return ROUTE_MEMBER;
  if (pathname.startsWith(ROUTE_USER_PROFILE)) return ROUTE_USER_PROFILE;
  if (pathname.startsWith(ROUTE_HOME_SETTING)) return ROUTE_HOME_SETTING;

  return ROUTE_DASHBOARD;
};
