import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./StandingColumn";
function StandingTable(props) {
  const { dataList, addUser } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "Team",
      dataIndex: "team_name",
      //sorter: (a, b) => `${a.team_name}`.localeCompare(`${b.team_name}`),
    },

    {
      title: "MP",
      dataIndex: "mp",
      //sorter: (a, b) => a.mp - b.mp,
    },
    {
      title: "Win",
      dataIndex: "win",
      //sorter: (a, b) => a.win - b.win,
    },
    {
      title: "Tie",
      dataIndex: "tie",
      //sorter: (a, b) => a.tie - b.tie,
    },
    {
      title: "Loss",
      dataIndex: "loss",
      //sorter: (a, b) => a.loss - b.loss,
    },
    {
      title: "PF",
      dataIndex: "pf",
      //sorter: (a, b) => a.pf - b.pf,
    },
    {
      title: "PA",
      dataIndex: "pa",
      //sorter: (a, b) => a.pa - b.pa,
    },
    {
      title: "PTS",
      dataIndex: "pts",
      //sorter: (a, b) => a.pts - b.pts,
    },
  ];

  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
          return { ...data, key: index };
        });

  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 400 }}
        pagination={{ pageSize: 12 }}
      />
    </div>
  );
}

export default withRouter(StandingTable);
