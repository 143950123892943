import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showMessageDetailModal } from "../MessageDetailModal/showMessageDetailModal";
function MesssageTable(props) {
  const { dataList, onDetail, isDetail } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "ID",
      key: "key",
      dataIndex: "key",
      render: (key) => {
        return key + 1;
      },
    },
    {
      title: "Email Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Email Sent To",
      dataIndex: "toName",
      key: "to",
    },
    {
      title: "Email Address",
      key: "toEmail",
      render: (record) => {
        return (
          <a
            onClick={async () => {
              var date = new Date(record.created * 1000);
              // Hours part from the timestamp
              var day = date.getDate();
              // Minutes part from the timestamp
              var month = date.getMonth() + 1;
              // Seconds part from the timestamp
              var year = date.getFullYear();

              var hour = date.getHours();

              var minute = date.getMinutes();

              // Will display time in 10:30:23 format
              if (month < 10) {
                month = "0" + month;
              }
              if (day < 10) {
                day = "0" + day;
              }
              let pm = " AM";
              if (hour >= 12) {
                pm = " PM";
              }
              if (hour > 12) {
                hour = hour - 12;
              }
              if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              year = year + "";
              let formattedTime =
                month + "/" + day + "/" + year + " " + hour + ":" + minute + pm;
              let resInfo = await showMessageDetailModal({
                title: record.toEmail,
                subject: record.subject,
                content: record.content,
                send_date: formattedTime,
                attachment: record.attachment
              });
              if (resInfo == null) return;
            }}
          >
            {record.toEmail}
          </a>
        );
      },
    },
    {
      title: "Email Sent Date",
      key: "created",
      render: (record) => {
        var date = new Date(record.created * 1000);
        // Hours part from the timestamp
        var day = date.getDate();
        // Minutes part from the timestamp
        var month = date.getMonth() + 1;
        // Seconds part from the timestamp
        var year = date.getFullYear();

        var hour = date.getHours();

        var minute = date.getMinutes();

        // Will display time in 10:30:23 format
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let pm = " AM";
        if (hour >= 12) {
          pm = " PM";
        }
        if (hour > 12) {
          hour = hour - 12;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        year = year + "";
        var formattedTime =
          month + "/" + day + "/" + year + " " + hour + ":" + minute + pm;
        return formattedTime;
      },
      sorter: (a, b) => a.created - b.created,
    },
    {
      title: "Send Type",
      dataIndex: "send_type",
      key: "send_type",
    },
    {
      title: "Action",
      key: "detail",
      render: (record) => {
        return isDetail ? (
          <></>
        ) : (
          <CommonButton
            onClick={() => {
              onDetail(record);
            }}
          >
            Detail
          </CommonButton>
        );
      },
    },
  ];

  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
          return { ...data, key: index };
        });
  const paginationProps = {
    total: dataList.length,
    showSizeChanger: true,
    defaultPageSize: 20,
    pageSizeOptions: ["10", "20", "30", "50"],
    locale: { items_per_page: "" },
  };
  return (
    <div className="message-list-widget" style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={paginationProps}
      />
    </div>
  );
}

export default withRouter(MesssageTable);
