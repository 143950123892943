import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import {
  Switch,
  Row,
  Col,
  message,
  Select,
  Checkbox,
  Input,
  Spin,
  DatePicker,
  TimePicker,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import "./PracticeModal.css";
import { isEmpty, weekDay } from "utils/GlobalFunctions";
import moment from "moment";
import {
  apiAddoneEvent,
  apiCreateClubEvent,
  apiCreateEvent,
  apiCreatePractice,
  apiGetLocation,
  apiGetTeamList,
} from "services/divisionService";
import { showLocationModal } from "../LocationModal/showLocationModal";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showRepeatModal } from "../RepeatModal/showRepeatModal";

const { Option } = Select;
function PracticeModal(props) {
  const { show, proceed, title, token, divisionList, step, info } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [oneEvent, setOneEvent] = useState({
    event_type: "Practice",
    start_time: "",
    arrival_time: "",
    duration: "",
    memo: "",
    notify: "0"
  });
  const [errorField, setErrorField] = useState([]);

  const [locationList, setLocationList] = useState([]);
  const [notRepeat, setNotRepeat] = useState("Does not repeat");
  const [dailyList, setDailyList] = useState([]);
  const [dailyStr, setDailyStrList] = useState([]);
  const [tillDate, setTillDate] = useState("");
  const [periodType, setPeriodType] = useState(0);

  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    getLocationData();
  }, []);
  useEffect(() => {
    if (info != undefined) {
      setOneEvent({
        ...oneEvent,
        division_id: info.division_id,
        hometeam_id: info.home_team_id,
        awayteam_id: info.away_team_id,
        event_date: info.start_date.replaceAll("-", "/"),
        start_time: info.start_time,
        duration: info.duration_hour + ":" + info.duration_min,
        location: info.location_id,
      });
    }
  }, [info]);
  useEffect(() => {
    if (oneEvent.division_id != undefined) {
      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", token);
      params.append("division_id", oneEvent.division_id);
      params.append("locked", true);
      setIsBusy(true);
      apiGetTeamList(params)
        .then((res) => {
          setIsBusy(false);
          setTeamList(res);
        })
        .catch((err) => {
          message.error(err);
          setIsBusy(false);
        });
    }
  }, [oneEvent.division_id]);
  const getLocationData = () => {
    const params = new URLSearchParams();
    params.append("token", token);
    setIsBusy(true);
    apiGetLocation(params)
      .then((res) => {
        setIsBusy(false);
        setLocationList(res);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onLocationDlg = async () => {
    let dlgRes = await showLocationModal({
      title: "Add New Location",
      token: token,
    });
    if (dlgRes == null) return;
    getLocationData();
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(oneEvent, "event_type", errorList);
    errorList = isEmpty(oneEvent, "division_id", errorList);
    errorList = isEmpty(oneEvent, "team_id", errorList);
    errorList = isEmpty(oneEvent, "event_date", errorList);
    errorList = isEmpty(oneEvent, "start_time", errorList);
    errorList = isEmpty(oneEvent, "duration", errorList);
    errorList = isEmpty(oneEvent, "location", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if (!validateFields()) return;
    let home_team_id =
      teamList[teamList.findIndex((x) => x.ID == oneEvent.team_id)]
        .beclutch_team_id;
    let tmpLocation =
      locationList[locationList.findIndex((x) => x.ID == oneEvent.location)];
    let addr =
      tmpLocation.LOCATION_STREET +
      ", " +
      tmpLocation.LOCATION_CITY +
      ", " +
      tmpLocation.LOCATION_STATE +
      ", " +
      tmpLocation.LOCATION_ZIP;
    let repeat = {
      type: periodType,
      till_date: tillDate,
      daily: dailyList.toString(),
    };
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("team_id", home_team_id);
    params.append("year", oneEvent.event_date.split("-")[2]);
    params.append("month", oneEvent.event_date.split("-")[0]);
    params.append("day_of_month", oneEvent.event_date.split("-")[1]);
    params.append(
      "start_time_hour",
      oneEvent.start_time.split(" ")[0].split(":")[0]
    );
    params.append(
      "start_time_min",
      oneEvent.start_time.split(" ")[0].split(":")[1]
    );
    params.append("start_time_ampm", oneEvent.start_time.split(" ")[1]);
    params.append(
      "arrival_time_hour",
      oneEvent.arrival_time == ""
        ? oneEvent.start_time.split(" ")[0].split(":")[0]
        : oneEvent.arrival_time.split(" ")[0].split(":")[0]
    );
    params.append(
      "arrival_time_min",
      oneEvent.arrival_time == ""
        ? oneEvent.start_time.split(" ")[0].split(":")[1]
        : oneEvent.arrival_time.split(" ")[0].split(":")[1]
    );
    params.append(
      "arrival_time_ampm",
      oneEvent.arrival_time == "" ? oneEvent.start_time.split(" ")[1] : oneEvent.arrival_time.split(" ")[1]
    );
    params.append("location_id", oneEvent.location);
    params.append("addr", addr);
    params.append("memo", oneEvent.memo);
    params.append("notify", oneEvent.notify);
    params.append("repeat", JSON.stringify(repeat));
    params.append("duration_hour", oneEvent.duration.split(":")[0]);
    params.append("duration_min", oneEvent.duration.split(":")[1]);
    params.append("day_of_week", oneEvent.week);
    setIsBusy(true);
    apiCreatePractice(params)
      .then((res) => {
        setIsBusy(false);
        proceed(oneEvent);
        message.success("Practice has been added successfully.");
      })
      .catch((err) => {
        message.error(err == "" ? "Something went wrong" : err);
        setIsBusy(false);
      });
  };
  const onShowRepeatDlg = async () => {
    let dlgRes = await showRepeatModal({
      title: "Choose Repeat Option",
      params: {
        not: notRepeat,
        tillDate: tillDate,
        dailyList: dailyList,
        period: periodType
      }
    });
    if (dlgRes == null) return;
    let str = "Does not Repeat";
    if (dlgRes.period == "Does not Repeat") {
      setPeriodType(0);
    } else if (dlgRes.period == "Daily") {
      str = "Daily Repeat till " + dlgRes.until;
      setPeriodType(1);
    } else if (dlgRes.period == "Weekly") {
      str = "Weekly Repeat till " + dlgRes.until;
      setPeriodType(2);
    } else if (dlgRes.period == "Monthly") {
      str = "Monthly Repeat till " + dlgRes.until;
      setPeriodType(3);
    } else if (dlgRes.period == "Yearly") {
      str = "Yearly Repeat till " + dlgRes.until;
      setPeriodType(4);
    }
    setTillDate(dlgRes.until);
    setDailyList(dlgRes.selWeeks.map((x) => x.id));
    setDailyStrList(dlgRes.selWeeks.map((x) => x.title).join(", "));

    setNotRepeat(str);
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="practice-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText={info == undefined ? "Add" : "Edit"}
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        {/* <Row style={{ marginTop: 10 }} gutter={16}>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Event Type*</div>
            <Input
              value={oneEvent.event_type}
              disabled
              className={
                errorField !== undefined && errorField.includes("event_type")
                  ? `common-input-error`
                  : `common-input event-type`
              }
              onChange={(e) => {
                if (errorField.includes("event_type")) {
                  let errors = errorField.filter((x) => x != "event_type");
                  setErrorField([...errors]);
                }
                setOneEvent({ ...oneEvent, event_type: e.target.value });
              }}
            />
          </Col>
        </Row> */}
        <Row style={{ marginTop: 10 }} gutter={16}>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Division</div>
            <Select
              placeholder="Choose Division"
              showSearch
              className={
                errorField !== undefined && errorField.includes("division_id")
                  ? `group-select-error`
                  : `group-select`
              }
              value={
                oneEvent.division_id == undefined ? null : oneEvent.division_id
              }
              onSelect={(v1, v2) => {
                if (errorField.includes("division_id")) {
                  let errors = errorField.filter((x) => x != "division_id");
                  setErrorField([...errors]);
                }
                setOneEvent({ ...oneEvent, division_id: v1 });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {divisionList.map((x, index) => {
                return (
                  <Option value={x.ID} key={index}>
                    {x.division_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Team</div>
            <Select
              placeholder="Choose Team"
              showSearch
              className={
                errorField !== undefined && errorField.includes("team_id")
                  ? `group-select-error`
                  : `group-select`
              }
              value={oneEvent.team_id == undefined ? null : oneEvent.team_id}
              onSelect={(v1, v2) => {
                if (errorField.includes("team_id")) {
                  let errors = errorField.filter((x) => x != "team_id");
                  setErrorField([...errors]);
                }
                setOneEvent({ ...oneEvent, team_id: v1 });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teamList.map((x, index) => {
                return (
                  <Option value={x.ID} key={index}>
                    {x.team_name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }} justify="center">
          <div
            className="no-repeat-practice"
            onClick={() => {
              onShowRepeatDlg();
            }}
          >
            {notRepeat}
            {dailyStr != "" && (
              <p style={{ marginBottom: 0, textAlign: "center" }}>
                ( {dailyStr} )
              </p>
            )}
          </div>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: 20 }}>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Event Date*</div>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={
                errorField !== undefined && errorField.includes("event_date")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.event_date == undefined
                  ? ""
                  : moment(oneEvent.event_date, "MM-DD-YYYY")
              }
              onChange={(e, str) => {
                if (errorField.includes("event_date")) {
                  let errors = errorField.filter((x) => x != "event_date");
                  setErrorField([...errors]);
                }
                let week = weekDay(e.weekday());
                setOneEvent({ ...oneEvent, event_date: str, week: week });
              }}
              format="MM-DD-YYYY"
            />
          </Col>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Start Time*</div>
            <TimePicker
              format={"hh:mm A"}
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={
                errorField !== undefined && errorField.includes("start_time")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.start_time == ""
                  ? ""
                  : moment(oneEvent.start_time, "hh:mm A")
              }
              onChange={(time, timeStr) => {
                if (errorField.includes("start_time")) {
                  let errors = errorField.filter((x) => x != "start_time");
                  setErrorField([...errors]);
                }
                setOneEvent({
                  ...oneEvent,
                  start_time: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
          <Col xs={24} md={8}>
            <div style={{ paddingBottom: "5px" }}>Arrival Time*</div>
            <TimePicker
              format={"hh:mm A"}
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              className={`common-input`}
              value={
                oneEvent.arrival_time == ""
                  ? ""
                  : moment(oneEvent.arrival_time, "hh:mm A")
              }
              onChange={(time, timeStr) => {
                setOneEvent({
                  ...oneEvent,
                  arrival_time: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Duration</div>
            <TimePicker
              format={"HH:mm"}
              placeholder="0 hour(s) 00 minute(s)"
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              showNow={false}
              className={
                errorField !== undefined && errorField.includes("duration")
                  ? `common-input-error`
                  : `common-input`
              }
              value={
                oneEvent.duration == ""
                  ? ""
                  : moment(oneEvent.duration, "HH:mm")
              }
              onChange={(time, timeStr) => {
                if (errorField.includes("duration")) {
                  let errors = errorField.filter((x) => x != "duration");
                  setErrorField([...errors]);
                }
                setOneEvent({
                  ...oneEvent,
                  duration: timeStr == "" ? "" : timeStr,
                });
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <div style={{ paddingBottom: "5px" }}>Location</div>
            <Row align="middle" gutter={16}>
              <Col style={{ width: 'calc(100% - 85px)' }}>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("location")
                      ? `group-select-error`
                      : `group-select`
                  }
                  value={
                    oneEvent.location == undefined ? null : oneEvent.location
                  }
                  style={{ width: "100%" }}
                  onSelect={(v) => {
                    if (errorField.includes("location")) {
                      let errors = errorField.filter((x) => x != "location");
                      setErrorField([...errors]);
                    }
                    setOneEvent({ ...oneEvent, location: v });
                  }}
                >
                  {locationList.map((x, index) => {
                    return (
                      <Option value={x.ID} key={index}>
                        <div>
                          <div>{x.LOCATION_NAME}</div>
                          <div style={{ color: "#6e6e6e", fontSize: 10 }}>
                            {x.LOCATION_STREET}, {x.LOCATION_CITY},{" "}
                            {x.LOCATION_STATE}, {x.LOCATION_ZIP}
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col>
                <div style={{ textAlign: "end" }}>
                  <CommonButton
                    style={{ padding: "8px 20px" }}
                    onClick={() => {
                      onLocationDlg();
                    }}
                  >
                    Add
                  </CommonButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={24} sm={24}>
            <div style={{ paddingBottom: "5px" }}>Memo</div>
            <Input
              value={oneEvent.memo}
              className="common-input"
              onChange={(e) => {
                setOneEvent({ ...oneEvent, memo: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={24} sm={24}>
            <Checkbox
              onChange={(e) => {
                setOneEvent({ ...oneEvent, notify: e.target.checked ? "1" : "0" });
              }}
              checked={oneEvent.notify == "1"}
            >
              Notify
            </Checkbox>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(PracticeModal);
