import { DeleteOutlined } from "@ant-design/icons";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import React from "react";
function RosterRow(props) {
    const { userInfo, deleteRoster } = props;

    return (
        <>
            <tr className="ant-table-row ant-table-row-level-0 child-table-row">
                <td className="ant-table-cell">{userInfo.name}</td>
                <td className="ant-table-cell">{userInfo.gender}</td>
                <td className="ant-table-cell">{userInfo.locked == "0" ? "Unlocked" : "Locked"}</td>
                <td className="ant-table-cell ant-table-row-action">
                    <div className="ant-row" style={{ marginLeft: '-4px', marginRight: '-4px', rowGap: 0 }}>
                        <div className="ant-col" style={{ paddingLeft: 4, paddingRight: 4 }}>
                            <a
                                style={{ color: "red" }}
                                onClick={async () => {
                                    var res = await showConfirmDlgWidget({
                                        title: "Delete Confirmation",
                                        content: "Are you sure you want to remove this participant?",
                                    });
                                    if (res == false) return;
                                    deleteRoster(userInfo)
                                }}
                            >
                                <DeleteOutlined />
                            </a>
                        </div>
                    </div>
                </td>
            </tr>

        </>
    )
}
export default RosterRow;