import {
  DeleteOutlined,
  EditFilled,
  FolderAddOutlined,
  MailOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { message, Tooltip } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import React from "react";
import { useState } from "react";
import { apiCreateBeclutchTeam } from "services/divisionService";

function ChildTable(props) {
  const { userInfo, updateTeam, deleteTeam, sendMail, createBeclutchTeam } = props;
  const [expanded, setExpand] = useState(false);
  const toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;
    if (!expanded) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  };

  return (
    <>
      <tr className="ant-table-row ant-table-row-level-0 child-table-row">
        <td className="ant-table-cell">{userInfo.team_name}</td>
        <td className="ant-table-cell">{userInfo.coach_name}</td>
        <td className="ant-table-cell">{userInfo.coach_email}</td>
        <td className="ant-table-cell">
          {userInfo.locked == "0" ? "Unlocked" : "Locked"}
        </td>
        <td className="ant-table-cell">
          <div
            className="ant-row"
            style={{ marginLeft: "-4px", marginRight: "-4px", rowGap: 0 }}
          >
            <div
              className="ant-col"
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <a
                style={{ color: "var(--blueColor)" }}
                onClick={async () => {
                  updateTeam(userInfo);
                }}
              >
                <EditFilled />
              </a>
            </div>
            <div
              className="ant-col"
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <a
                style={{ color: "red" }}
                onClick={async () => {
                  if (userInfo.locked == "1") {
                    message.error(
                      "This team is locked and therefore cannot be deleted.  Please contact BeClutch Support"
                    );
                  } else {
                    var res = await showConfirmDlgWidget({
                      title: "Delete Confirmation",
                      content: "Are you sure you want to delete this team?",
                    });
                    if (res == false) return;
                    deleteTeam(userInfo.ID);
                  }
                }}
              >
                <DeleteOutlined />
              </a>
            </div>
            <div
              className="ant-col"
              style={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <a
                style={{ color: "var(--mainColor)" }}
                onClick={async () => {
                  if (userInfo.locked == "0") {
                    message.error(
                      "This team is unlocked and therefore cannot be sent mail. Please contact BeClutch Support"
                    );
                    return;
                  } else if (userInfo.coach_email == "") {
                    message.error("Coach Email is Required before completing this action");
                    return;
                  } else if (userInfo.coach_first_name == "" || userInfo.coach_last_name == "") {
                    message.error("Coach First and Last Name are Required before completing this action");
                    return;
                  } else {
                    sendMail(userInfo);
                  }
                }}
              >
                <MailOutlined />
              </a>
            </div>
            {userInfo.locked == "0" && (
              <div
                className="ant-col"
                style={{ paddingLeft: 4, paddingRight: 4 }}
              >
                <a
                  style={{ color: "red" }}
                  onClick={async () => {
                    if (userInfo.coach_email == "") {
                      message.error("Coach Email is Required before completing this action");
                      return;
                    } else if (userInfo.coach_first_name == "" || userInfo.coach_last_name == "") {
                      message.error("Coach First and Last Name are Required before completing this action");
                      return;
                    } else {
                      createBeclutchTeam(userInfo.ID) 
                    }
                  }}
                >
                  <Tooltip title="Create Team in BeClutch App">
                    <PlusCircleOutlined />
                  </Tooltip>
                </a>
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}
export default ChildTable;
