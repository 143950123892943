import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import {
  Switch,
  Row,
  Col,
  message,
  Select,
  Radio,
  Input,
  Spin,
  Checkbox,
  DatePicker,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RepeatModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import { apiAddLocation } from "services/divisionService";
import moment from "moment";
const { Option } = Select;
function RepeatModal(props) {
  const { show, proceed, title, params } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [period, setPeriod] = useState("Does not Repeat");
  const [dayUntil, setDayUntil] = useState("");
  const [weekUntil, setWeekUntil] = useState("");
  const [monthUntil, setMonthUntil] = useState("");
  const [yearUntil, setYearUntil] = useState("");
  const [weekList, setWeekList] = useState([]);
  const [errorField, setErrorField] = useState([]);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (params != undefined) {
      if (params.period == "0") {
        setPeriod("Does not Repeat")
      } else if (params.period == "1") {
        setPeriod("Daily")
        setDayUntil(params.tillDate)
      } else if (params.period == "2") {
        setPeriod("Weekly")
        setWeekUntil(params.tillDate)
      } else if (params.period == "3") {
        setPeriod("Monthly")
        setMonthUntil(params.tillDate)
      } else if (params.period == "4") {
        setPeriod("Yearly")
        setYearUntil(params.tillDate)
      }

      let tmpList = [
        { id: 0, title: "Mon", checked: params.dailyList.includes(0) },
        { id: 1, title: "Tue", checked: params.dailyList.includes(1) },
        { id: 2, title: "Wed", checked: params.dailyList.includes(2) },
        { id: 3, title: "Thr", checked: params.dailyList.includes(3) },
        { id: 4, title: "Fri", checked: params.dailyList.includes(4) },
        { id: 5, title: "Sat", checked: params.dailyList.includes(5) },
        { id: 6, title: "Sun", checked: params.dailyList.includes(6) },
      ];
      setWeekList(tmpList);
    }
  }, [params])

  const validateFields = () => {
    let valid = true;
    if (period != "Does not Repeat") {
      if (period == "Daily" && dayUntil == "") {
        valid = false
      }
      if (period == "Weekly" && weekUntil == "") {
        valid = false
      }
      if (period == "Monthly" && monthUntil == "") {
        valid = false
      }
      if (period == "Yearly" && yearUntil == "") {
        valid = false
      }
      let tmpList = [...weekList]
      if (period == "Weekly" && tmpList.filter((x) => x.checked).length == 0) {
        valid = false
        message.error("Please select day")
      }
    }
    return valid
  };
  const onCreate = () => {
    if (!validateFields()) return;
    let tmpList = [...weekList]
    let selWeeks = tmpList.filter((x) => x.checked)
    let until = ""
    if (period == "Daily") {
      until = dayUntil
    } else if (period == "Weekly") {
      until = weekUntil
    } else if (period == "Monthly") {
      until = monthUntil
    } else if (period == "Yearly") {
      until = yearUntil
    }
    let params = {
      period,
      selWeeks,
      until
    }
    proceed(params)
  };
  const setFormat = (e) => {
    let tmpList = [
      { id: 0, title: "Mon", checked: false },
      { id: 1, title: "Tue", checked: false },
      { id: 2, title: "Wed", checked: false },
      { id: 3, title: "Thr", checked: false },
      { id: 4, title: "Fri", checked: false },
      { id: 5, title: "Sat", checked: false },
      { id: 6, title: "Sun", checked: false },
    ];
    if (e == "Does not Repeat") {
      setDayUntil("")
      setWeekUntil("")
      setMonthUntil("")
      setYearUntil("")
      setWeekList(tmpList);
    } else if (e == "Daily") {
      setWeekUntil("")
      setMonthUntil("")
      setYearUntil("")
      setWeekList(tmpList);
    } else if (e == "Weekly") {
      setDayUntil("")
      setMonthUntil("")
      setYearUntil("")
    } else if (e == "Monthly") {
      setDayUntil("")
      setWeekUntil("")
      setYearUntil("")
      setWeekList(tmpList);
    } else if (e == "Yearly") {
      setDayUntil("")
      setWeekUntil("")
      setMonthUntil("")
      setWeekList(tmpList);
    }

  }
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="repeat-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText="Save"
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        <Row style={{ marginTop: 20 }}>
          <Checkbox
            checked={period == "Does not Repeat"}
            onChange={(e) => {
              setPeriod("Does not Repeat");
              setFormat("Does not Repeat")
            }}
          >
            Does not Repeat
          </Checkbox>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }} align="middle">
          <Col>
            <Checkbox
              checked={period == "Daily"}
              onChange={(e) => {
                setPeriod("Daily");
                setFormat("Daily")
              }}
            >
              Daily
            </Checkbox>
          </Col>
          <Col style={{ paddingLeft: 30 }}>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              placeholder="Until"
              className={
                period == "Daily" && dayUntil == ""
                  ? `common-input-error`
                  : `common-input`
              }
              value={dayUntil == "" ? "" : moment(dayUntil, "MM-DD-YYYY")}
              onChange={(e, str) => {
                setDayUntil(str);
                setPeriod("Daily")
                setFormat("Daily")
              }}
              format="MM-DD-YYYY"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }} align="middle">
          <Col>
            <Checkbox
              checked={period == "Weekly"}
              onChange={(e) => {
                setPeriod("Weekly");
                setFormat("Weekly")
              }}
            >
              Weekly
            </Checkbox>
          </Col>
          <Col style={{ paddingLeft: 20 }}>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              placeholder="Until"
              className={
                period == "Weekly" && weekUntil == ""
                  ? `common-input-error`
                  : `common-input`
              }
              value={weekUntil == "" ? "" : moment(weekUntil, "MM-DD-YYYY")}
              onChange={(e, str) => {
                setWeekUntil(str);
                setPeriod("Weekly")
                setFormat("Weekly")
              }}
              format="MM-DD-YYYY"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          {weekList.map((x) => {
            return (
              <div className={`week-item ${x.checked ? "checked" : ""}`} onClick={() => {
                if (period == "Weekly") {
                  let tmpList = [...weekList]
                  setWeekList(tmpList.map((item) => {
                    if (x.id == item.id) {
                      return { ...item, checked: !item.checked }
                    } else {
                      return item
                    }
                  }))
                }
              }}>
                {x.title}
              </div>
            );
          })}
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }} align="middle">
          <Col>
            <Checkbox
              checked={period == "Monthly"}
              onChange={(e) => {
                setPeriod("Monthly");
                setFormat("Monthly")
              }}
            >
              Monthly
            </Checkbox>
          </Col>
          <Col style={{ paddingLeft: 14 }}>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              placeholder="Until"
              className={
                period == "Monthly" && monthUntil == ""
                  ? `common-input-error`
                  : `common-input`
              }
              value={monthUntil == "" ? "" : moment(monthUntil, "MM-DD-YYYY")}
              onChange={(e, str) => {
                setMonthUntil(str);
                setPeriod("Monthly")
                setFormat("Monthly")
              }}
              format="MM-DD-YYYY"
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }} align="middle">
          <Col>
            <Checkbox
              checked={period == "Yearly"}
              onChange={(e) => {
                setPeriod("Yearly");
                setFormat("Yearly")
              }}
            >
              Yearly
            </Checkbox>
          </Col>
          <Col style={{ paddingLeft: 27 }}>
            <DatePicker
              style={{
                width: "100%",
                cursor: "pointer",
                padding: "5px 6px",
              }}
              placeholder="Until"
              className={
                period == "Yearly" && yearUntil == ""
                  ? `common-input-error`
                  : `common-input`
              }
              value={yearUntil == "" ? "" : moment(yearUntil, "MM-DD-YYYY")}
              onChange={(e, str) => {
                setYearUntil(str);
                setPeriod("Yearly")
                setFormat("Yearly")
              }}
              format="MM-DD-YYYY"
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(RepeatModal);
