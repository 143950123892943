import React from 'react';
import './TwoLineButton.css'
const TwoLineButton = (props) => {
    const {onClick, customClass, style, text1, text2 } = props;
    return (
        <div style={style} className={`custom-button two-button ${customClass === undefined ? "" : customClass}`} onClick={onClick}>
            <div>{text1}</div>
            <div>{text2}</div>
        </div>
    );
};

export default TwoLineButton;