import "./ButtonWithIcon.css";
export const ButtonWithIcon = (props) => {
  const { icon, text, onClick, style, customClass } = props;
  return (
    <div className={`button-with-icon custom-button ${customClass === undefined ? "" : customClass}`} style={style} onClick={onClick}>
      <div>{icon} {` `} {text}</div>
    </div>
  );
};

export default ButtonWithIcon;
