import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, message, TreeSelect, Radio, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { FilePicker } from "react-file-picker";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./MessageModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

const { SHOW_PARENT } = TreeSelect;
function MessageModal(props) {
  const { show, proceed, title, dataList } = props;

  const [value, setValue] = useState([]);
  const [ccvalue, setCCValue] = useState([]);
  const [bccvalue, setBCCValue] = useState([]);
  const [mid, setMailerID] = useState([]);
  const [ccList, setCCList] = useState([]);
  const [bccList, setBCCList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [errorField, setErrorField] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [selType, setSelType] = useState(1);
  const [selFile, setSelFile] = useState("");

  useEffect(() => {
    getMailList("to");
  }, [value, selType]);
  useEffect(() => {
    getMailList("cc");
  }, [ccvalue, selType]);
  useEffect(() => {
    getMailList("bcc");
  }, [bccvalue, selType]);

  const getMailList = (kind) => {
    let tmpValueList = [];
    if (kind == "to") {
      tmpValueList = [...value];
    } else if (kind == "cc") {
      tmpValueList = [...ccvalue];
    } else if (kind == "bcc") {
      tmpValueList = [...bccvalue];
    }
    let tmpList = [];
    let tmpNameList = [];

    let srcList = [...dataList];
    tmpValueList.map((info) => {
      let id = info;
      let type = 0;
      if (id.includes("division:")) {
        id = id.split("division:")[1];
        type = 1;
      } else if (id.includes("team:")) {
        id = id.split("team:")[1];
        type = 2;
      } else if (id.includes("reg:")) {
        id = id.split("reg:")[1];
        type = 0;
      } else {
        id = info;
        type = 3;
      }
      if (type == 0) {
        srcList.map((reg) => {
          if (reg.ID == id) {
            reg.children.map((division) => {
              division.children.map((team) => {
                if (team.coach_email != "") {
                  let tmpObj = {
                    id: team.ID,
                    name: team.title,
                    type: "team",
                  };
                  tmpList = [...tmpList, tmpObj];
                  tmpNameList = [...tmpNameList, team.title];
                }
                if (selType == 1) {
                  if (team.childrens) {
                    team.childrens.map((member) => {
                      let tmpObj = {
                        id: member.participant_id,
                        name: member.title,
                        type: "member",
                      };
                      tmpList = [...tmpList, tmpObj];
                      tmpNameList = [...tmpNameList, member.title];
                    });
                  }

                }
              });
            });
          }
        });
      } else if (type == 1) {
        srcList.map((reg) => {
          reg.children.map((division) => {
            if (division.ID == id) {
              division.children.map((team) => {
                if (team.coach_email != "") {
                  let tmpObj = {
                    id: team.ID,
                    name: team.title,
                    type: "team",
                  };
                  tmpList = [...tmpList, tmpObj];
                  tmpNameList = [...tmpNameList, team.title];
                }
                if (selType == 1) {
                  if (team.childrens) {
                    team.childrens.map((member) => {
                      let tmpObj = {
                        id: member.participant_id,
                        name: member.title,
                        type: "member",
                      };
                      tmpList = [...tmpList, tmpObj];
                      tmpNameList = [...tmpNameList, member.title];
                    });
                  }

                }
              });
            }
          });
        });
      } else if (type == 2) {
        srcList.map((reg) => {
          reg.children.map((division) => {
            division.children.map((team) => {
              if (team.ID == id) {
                if (team.coach_email != "") {
                  let tmpObj = {
                    id: team.ID,
                    name: team.title,
                    type: "team",
                  };
                  tmpList = [...tmpList, tmpObj];
                  tmpNameList = [...tmpNameList, team.title];
                }
                if (selType == 1) {
                  if (team.childrens) {
                    team.childrens.map((member) => {
                      let tmpObj = {
                        id: member.participant_id,
                        name: member.title,
                        type: "member",
                      };
                      tmpList = [...tmpList, tmpObj];
                      tmpNameList = [...tmpNameList, member.title];
                    });
                  }

                }
              }
            });
          });
        });
      } else {
        srcList.map((reg) => {
          reg.children.map((division) => {
            if (division.value == "all_registered_users") {
              if (id == "all_registered_users") {
                division.children.map((pemail) => {
                  let tmpObj = {
                    id: pemail.value,
                    name: pemail.title,
                    type: "parent",
                  };
                  tmpList = [...tmpList, tmpObj];
                  tmpNameList = [...tmpNameList, pemail.title];
                });
              } else {
                division.children.map((pemail) => {
                  if (pemail.value == id) {
                    let tmpObj = {
                      id: pemail.value,
                      name: pemail.title,
                      type: "parent",
                    };
                    tmpList = [...tmpList, tmpObj];
                    tmpNameList = [...tmpNameList, pemail.title];
                  }
                });
              }
            }

          });
        });
      }
    });
    if (kind == "to") {
      setMailerID(tmpList);
      setNameList(tmpNameList);
    } else if (kind == "cc") {
      setCCList(tmpList);
    } else if (kind == "bcc") {
      setBCCList(tmpList);
    }
  };
  const onChangeSelect = (e) => {
    setValue(e);
  };
  const onChangeSelectCC = (e) => {
    setCCValue(e);
  };
  const onChangeSelectBCC = (e) => {
    setBCCValue(e);
  };
  const onEditorStateChange = (e) => {
    if (e != null) {
      let selStr = draftToHtml(convertToRaw(e.getCurrentContent()));
      setContent(selStr);
      setEditorState(e);
    }
  };

  const treeProps = {
    treeData: dataList,
    value: value,
    onChange: onChangeSelect,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    treeDefaultExpandAll: true,
    virtual: false,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };
  const treePropsCC = {
    treeData: dataList,
    value: ccvalue,
    onChange: onChangeSelectCC,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    treeDefaultExpandAll: true,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };
  const treePropsBCC = {
    treeData: dataList,
    value: bccvalue,
    onChange: onChangeSelectBCC,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    treeDefaultExpandAll: true,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
  };
  const onCreate = () => {
    if (subject == "") {
      message.error("Please input subject");
      return;
    }
    if (value.length == 0 && bccvalue.length == 0) {
      message.error("Please select member or team");
      return;
    }
    if (content == "") {
      message.error("Please input content");
      return;
    }
    let tmpDList = [...mid];
    let tmpTList = [...mid];
    let tmpParentList = [...mid];
    let divisionIDs = tmpDList
      .filter((x) => x.type == "member")
      .map((y) => {
        return y.id;
      })
      .join(",");
    let teamIDs = tmpTList
      .filter((x) => x.type == "team" && x.id != undefined)
      .map((y) => {
        return y.id;
      })
      .join(",");
    let parentIDs = tmpParentList
      .filter((x) => x.type == "parent")
      .map((y) => {
        return y.id;
      })
      .join(",");
    if (divisionIDs == "" && teamIDs == "" && parentIDs == "") {
      message.error("Selected team haven't assigned yet");
      return;
    }

    let tmpDCCList = [...ccList];
    let tmpTCCList = [...ccList];
    let tmpPCCList = [...ccList];
    let ccDivisionIDs = tmpDCCList
      .filter((x) => x.type == "member")
      .map((y) => {
        return y.id;
      })
      .join(",");
    let ccTeamIDs = tmpTCCList
      .filter((x) => x.type == "team" && x.id != undefined)
      .map((y) => {
        return y.id;
      })
      .join(",");
    let ccParentIDs = tmpPCCList
      .filter((x) => x.type == "parent")
      .map((y) => {
        return y.id;
      })
      .join(",");
    let tmpDBCCList = [...bccList];
    let tmpTBCCList = [...bccList];
    let tmpPBCCList = [...bccList];
    let bccDivisionIDs = tmpDBCCList
      .filter((x) => x.type == "member")
      .map((y) => {
        return y.id;
      })
      .join(",");
    let bccTeamIDs = tmpTBCCList
      .filter((x) => x.type == "team" && x.id != undefined)
      .map((y) => {
        return y.id;
      })
      .join(",");
    let bccParentIDs = tmpPBCCList
      .filter((x) => x.type == "parent")
      .map((y) => {
        return y.id;
      })
      .join(",");
    let param = {
      mList: divisionIDs,
      tList: teamIDs,
      pList: parentIDs,
      subject,
      content,
      selType,
      ccDivisionList: ccDivisionIDs,
      ccTeamList: ccTeamIDs,
      ccParentList: ccParentIDs,
      bccDivisionList: bccDivisionIDs,
      bccTeamList: bccTeamIDs,
      bccParentList: bccParentIDs,
      attachment: selFile,
      mFlag: value.length > 0 ? true : false,
      ccFlag: ccvalue.length > 0 ? true : false,
      bccFlag: bccvalue.length > 0 ? true : false
    };
    proceed(param);
  };
  const onSetType = (e) => {
    setSelType(e.target.value);
  };
  const onDropImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    if (files[0].size / 1000 / 1000 > 5) {
      message.error("File size should not be greater than 5Mb.");
      return;
    }
    setSelFile(files[0]);
  };

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="message-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText="Send"
      onOk={onCreate}
    >
      <div>
        <Radio.Group
          onChange={(e) => {
            setMailerID([]);
            setNameList([]);
            onSetType(e);
          }}
          value={selType}
        >
          <Radio value={1}>Everyone</Radio>
          <Radio value={2}>Staff</Radio>
        </Radio.Group>
      </div>
      <Row style={{ marginTop: 20 }}>
        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Subject*</div>
        <Input
          className="custom-input"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ marginBottom: 5, fontWeight: 600 }}>To*: </div>
        <TreeSelect {...treeProps} />
        <div style={{ marginTop: 10, marginBottom: 5, fontWeight: 600 }}>
          CC:{" "}
        </div>
        <TreeSelect {...treePropsCC} />
        <div style={{ marginTop: 10, marginBottom: 5, fontWeight: 600 }}>
          BCC:{" "}
        </div>
        <TreeSelect {...treePropsBCC} />
        <div className="editor-rect">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <div className="new_image_uploader">
          <label htmlFor="new_image_uploader">
            <input
              type="file"
              id="new_image_uploader"
              name="new_image_uploader"
              style={{ display: "none" }}
              accept="*"
              onChange={onDropImage}
            />
            <span className="custom-button common-button ">Attach File</span>
          </label>
          {selFile?.name && (
            <span style={{ paddingLeft: 20 }}>
              {selFile?.name}
              <CloseOutlined
                style={{
                  color: "var(--redColor)",
                  marginLeft: 7,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelFile("");
                }}
              />{" "}
            </span>
          )}
        </div>
      </Row>
    </Modal>
  );
}

export default confirmable(MessageModal);
