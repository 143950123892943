// @flow strict

import React from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as mailIcon } from '../../../assets/icons/ic_envelope.svg';
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "./InputEmailOrPhone.css";
import { Input, Radio } from "antd";
import { REGISTER_WITH } from "config/CONSTANTS";
function InputEmailOrPhone(props) {
  const { userDetail, setUserDetail, placeholder } = props;
  const { wrongList, setWrongList } = props;
  return (
    <div style={{ paddingLeft: 50, paddingRight: 50 }}>

      <div
        className="email-input"
        style={{
          display:
            userDetail.register_with === REGISTER_WITH.email ? "block" : "none",
        }}
      >
        <Input
          type="email"
          placeholder={placeholder}
          prefix={<Icon component={mailIcon} className="menu-item-icon" />}
          value={userDetail.email === undefined ? "" : userDetail.email}
          onChange={(e) => {
            if (wrongList.includes("email")) {
              let tmpList = [...wrongList]
              setWrongList(tmpList.filter((x) => x !== "email"))
            }
            setUserDetail({ ...userDetail, email: e.target.value })
          }}
          style={{
            border:
              wrongList.includes("email") === true
                ? "1px solid var(--redColor)"
                : "1px solid var(--greyColor)",
          }}
        />
      </div>

      <div
        className="phone-number-input"
        style={{
          display:
            userDetail.register_with === REGISTER_WITH.phone ? "block" : "none",
        }}
      >
        <PhoneInput
          defaultCountry="ZA"
          placeholder="Enter your phone number"
          value={userDetail.phone === undefined ? "" : userDetail.phone}
          onChange={(e) => setUserDetail({ ...userDetail, phone: e })}
          className={
            wrongList.includes("phone") === true
              ? "phone-input-wrong"
              : "phone-input-correct"
          }
        />
      </div>
    </div>
  );
}

export default InputEmailOrPhone;
