import React from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./DivisionPage.css";
import { useEffect } from "react";
import { useState } from "react";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import { Col, Divider, message, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import TwoLineButton from "components/ButtonWidgets/TwoLineButton/TwoLineButton";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { showDivisionModal } from "./DivisionModal/showDivisionModal";
import { showTeamModal } from "./TeamModal/showTeamModal";
import {
  apiGetDivisionAll,
  apiCreateDivision,
  apiCreateTeam,
  apiGetDivisionList,
  apiUpdateDivision,
  apiUpdateTeam,
  apiDeleteDivision,
  apiDeleteTeam,
  apiCreateDivisionFromRegistration,
  apiCreateBeclutchTeam,
  apiCreateRosterInvitation,
} from "services/divisionService";
import ParentTable from "./ParentTable";
import ParentTableEmpty from "./ParentTableEmpty";
function DivisionPage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [key, setKey] = useState(-1);
  const [availableTeams, setAvailableTeams] = useState(0);

  useEffect(() => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      getData();
    }
  }, [appDataStore]);

  const getData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionAll(params)
      .then((res) => {
        if (res != null) {
          setKey(res.index);
          let tmpTeamList = [];
          if (res.list.length > 0) {
            setDataList(
              res.list.map((x, index) => {
                if (x.children.length > 0) {
                  x.children.map((y) => {
                    if (y.locked == "0") {
                      tmpTeamList = [...tmpTeamList, y.ID];
                    }
                  });
                }
                return { ...x, key: `parent:` + index };
              })
            );
            setAvailableTeams(tmpTeamList);
          } else {
            setDataList([]);
          }
        } else {
          setDataList([]);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onBuild = async () => {
    if (appDataStore.club == undefined) {
      message.error("Please select club");
      return;
    }
    if (appDataStore.curRegistration == undefined) {
      message.error("Please select registration");
      return;
    }
    var res = await showConfirmDlgWidget({
      title:
        "Creating divisions from registration will erase all current divisions.",
      content: "Are you sure you want to do this?",
      className: "add",
    });
    if (res == false) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("user_id", userDataStore.user.ID);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiCreateDivisionFromRegistration(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("New Division has been created successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onAddDivision = async () => {
    if (appDataStore.club == undefined) {
      message.error("Please select club");
      return;
    }
    if (appDataStore.curRegistration == undefined) {
      message.error("Please select registration");
      return;
    }
    var newDivision = await showDivisionModal({
      title: "Add New Division",
    });
    if (newDivision == null) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("user_id", userDataStore.user.ID);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("name", newDivision.division_name);
    setIsBusy(true);
    apiCreateDivision(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("New Division has been created successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };

  const onAddTeam = async (list) => {
    var team = await showTeamModal({
      title: "Add New Team",
      divisionList: list,
    });
    if (team == null) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("user_id", userDataStore.user.ID);
    params.append("division_id", team.division_id);
    params.append("name", team.team_name);
    params.append("coach_first_name", team.coach_first_name);
    params.append("coach_last_name", team.coach_last_name);
    params.append("email", team.coach_email);
    setIsBusy(true);
    apiCreateTeam(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("New Team has been created successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onEditTeam = async (list, team) => {
    var newTeam = await showTeamModal({
      title: "Edit Team",
      divisionList: list,
      selTeam: team,
    });
    if (newTeam == null) return;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("team_id", newTeam.ID);
    params.append("user_id", userDataStore.user.ID);
    params.append("division_id", newTeam.division_id);
    params.append("name", newTeam.team_name);
    params.append("coach_first_name", newTeam.coach_first_name);
    params.append("coach_last_name", newTeam.coach_last_name);
    params.append("email", newTeam.coach_email);
    params.append("beclutch_opponent_id", newTeam.beclutch_opponent_id);
    params.append("beclutch_team_id", newTeam.beclutch_team_id);
    params.append("locked", newTeam.locked);
    setIsBusy(true);
    apiUpdateTeam(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("Team has been updated successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getDevision = (team) => {
    if (appDataStore.club == undefined) {
      message.error("Please select club");
      return;
    }
    if (appDataStore.curRegistration == undefined) {
      message.error("Please select registration");
      return;
    }
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionList(params)
      .then((res) => {
        setIsBusy(false);
        if (res.length == 0) {
          message.error("Please create division");
          return;
        }
        if (team == "add") {
          onAddTeam(res);
        } else {
          onEditTeam(res, team);
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onUpdateDivision = async (division) => {
    var newDivision = await showDivisionModal({
      title: "Edit Division",
      selDivision: division,
    });
    if (newDivision == null) return;
    setIsBusy(true);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("id", newDivision.ID);
    params.append("name", newDivision.division_name);
    setIsBusy(true);
    apiUpdateDivision(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("Division has been updated successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };

  const onUpdateTeam = (team) => {
    getDevision(team);
  };
  const onDeleteDivision = (id) => {
    setIsBusy(true);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("division_id", id);
    setIsBusy(true);
    apiDeleteDivision(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("Division has been deleted successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onDeleteTeam = (id) => {
    setIsBusy(true);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("team_id", id);
    setIsBusy(true);
    apiDeleteTeam(params)
      .then((res) => {
        setIsBusy(false);
        getData();
        message.success("Team has been deleted successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onSendMail = async (info) => {
    setIsBusy(true)
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("fname", info.coach_first_name);
    params.append("lname", info.coach_last_name);
    params.append("email", info.coach_email);
    params.append("role_id", 2);
    params.append("team_name", info.team_name);
    params.append("sport", info.reg_sport_id);
    params.append("sport_title", info.reg_sport_name);
    params.append("club_id", info.reg_club_id);
    params.append("team_id", info.beclutch_team_id);
    params.append("send_mail", 1);
    params.append("role_title", "Coach");
    params.append("is_player", 0);
    apiCreateRosterInvitation(params)
      .then((res) => {
        setIsBusy(false)
        message.success("Invited successfully")
      })
      .catch((err) => {
        setIsBusy(false)
        message.error(err)
      });
  };
  const onCreateTeamDlg = async (id) => {

    if (availableTeams.length == 0) {
      message.error("No available teams.");
      return;
    }
    if (appDataStore.club == undefined) {
      message.error("Please select club");
      return;
    }
    if (userDataStore.clubs != undefined && userDataStore.clubs.length > 0) {
      let clubIndex = userDataStore.clubs.findIndex(
        (x) => x.CLUB_ID == appDataStore.club.id
      );
      let sport_item = userDataStore.clubs[clubIndex].CLUB_TEAM_INFO.PRODUCTION;
      var res = await showConfirmDlgWidget({
        title: "Team Create Confirmation",
        content: `Are you sure you want to create this team in the BeClutch App? (this cannot be undone)`,
        // content: `Are you sure you want to create (${availableTeams.length}) Teams in the BeClutch App? (this cannot be undone)`,
      });
      if (res == false) return;

      setIsBusy(true);
      const params = new URLSearchParams();
      params.append("token", userDataStore.token);
      params.append("team_ids", id);
      // params.append("team_ids", availableTeams.join(","));
      params.append("sport_item", sport_item);
      params.append("club_id", appDataStore.club.id);
      apiCreateBeclutchTeam(params)
        .then((res) => {
          setIsBusy(false);
          getData();
          message.success(
            "This team has been created successfully in the BeClutch App."
          );
        })
        .catch((err) => {
          message.error(err);
          setIsBusy(false);
        });
    }
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="division-page">
          <ClubBoxWidget />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <Row gutter={24} wrap={false}>
              <Col>
                <TwoLineButton
                  text1="Build Divisions"
                  text2="from Registration"
                  onClick={onBuild}
                />
              </Col>
              <Col>
                <TwoLineButton
                  text1="Add Division"
                  style={{ padding: "18px 20px" }}
                  onClick={onAddDivision}
                />
              </Col>
              <Col flex="auto">
                <TwoLineButton
                  text1="Add Team"
                  style={{ padding: "18px 20px" }}
                  onClick={() => {
                    getDevision("add");
                  }}
                />
              </Col>
              {/* <Col flex={"200px"}>
                <TwoLineButton
                  text1="Create Teams"
                  text2="in BeClutch App"
                  onClick={() => {
                    onCreateTeamDlg();
                  }}
                />
              </Col> */}
            </Row>
            <div style={{ marginTop: 20 }}>
              <div>
                <div className="ant-table">
                  <div className="ant-table-container">
                    <div className="ant-table-content">
                      <table style={{ tableLayout: "auto" }}>
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell ant-table-row-expand-icon-cell"></th>
                            <th className="ant-table-cell">Division Name</th>
                            <th className="ant-table-cell ant-table-row-action">
                              Team Count
                            </th>
                            <th className="ant-table-cell ant-table-row-action">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          {dataList.length > 0 ? (
                            dataList.map((x, index) => {
                              return (
                                <ParentTable
                                  userInfo={x}
                                  key={x.key}
                                  updateDivision={onUpdateDivision}
                                  deleteDivision={onDeleteDivision}
                                  updateTeam={onUpdateTeam}
                                  deleteTeam={onDeleteTeam}
                                  sendMail={onSendMail}
                                  createBeclutchTeam={onCreateTeamDlg}
                                  indexKey={key}
                                  indexID={index}
                                />
                              );
                            })
                          ) : (
                            <ParentTableEmpty />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </CommonDivWidget>
  );
}

export default DivisionPage;
