import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Input, Row, message, Select, Col } from "antd";
import Modal from "antd/lib/modal/Modal";

import "./TeamModal.css"
import { isEmpty } from "utils/GlobalFunctions";
function TeamModal(props) {
  const { show, proceed, title, selTeam, divisionList } = props;
  const Option = { Select }
  const [team, setTeam] = useState({division_id: "", team_name: "", coach_email: "", coach_first_name: "", coach_last_name: ""});
  const [errorField, setErrorField] = useState([]);
  useEffect(() => {
    if (selTeam !== undefined) {
      setTeam(selTeam)
    }
  }, [selTeam])

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(team, "division_id", errorList);
    errorList = isEmpty(team, "team_name", errorList);
    errorList = isEmpty(team, "coach_first_name", errorList);
    errorList = isEmpty(team, "coach_last_name", errorList);
    errorList = isEmpty(team, "coach_email", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if (validateFields()) {
      proceed(team);
    }
  };

  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="team-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      onOk={onCreate}
    >
      <Row>
        <div style={{ paddingBottom: "5px" }}>Division*</div>
        <Select
          className={
            errorField !== undefined && errorField.includes("division_id")
              ? `group-select-error`
              : `group-select`
          }
          style={{ width: "100%" }}
          value={team.division_id == "" ? null : team.division_id}
          onSelect={(v) => {
            setTeam({ ...team, division_id: v });
          }}
        >
          {divisionList.map((x, index) => {
            return (
              <Option
                key={x.ID}
                value={x.ID}
              >
                {x.division_name}
              </Option>
            )
          })}
        </Select>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ paddingBottom: "5px" }}>Team Name*</div>
        <Input
          className={errorField !== undefined && errorField.includes("team_name") ? `common-input-error` : `common-input`}
          value={team.team_name}
          onChange={(e) => {
            if (errorField.includes("team_name")) {
              let errors = errorField.filter((x) => x != "team_name");
              setErrorField([...errors]);
            }
            setTeam({ ...team, team_name: e.target.value });
          }}
        />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ paddingBottom: "5px" }}>Coach First Name</div>
        <Input
          className={errorField !== undefined && errorField.includes("coach_first_name") ? `common-input-error` : `common-input`}
          value={team.coach_first_name}
          onChange={(e) => {
            if (errorField.includes("coach_first_name")) {
              let errors = errorField.filter((x) => x != "coach_first_name");
              setErrorField([...errors]);
            }
            setTeam({ ...team, coach_first_name: e.target.value });
          }}
        />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ paddingBottom: "5px" }}>Coach Last Name</div>
        <Input
          className={errorField !== undefined && errorField.includes("coach_last_name") ? `common-input-error` : `common-input`}
          value={team.coach_last_name}
          onChange={(e) => {
            if (errorField.includes("coach_last_name")) {
              let errors = errorField.filter((x) => x != "coach_last_name");
              setErrorField([...errors]);
            }
            setTeam({ ...team, coach_last_name: e.target.value });
          }}
        />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ paddingBottom: "5px" }}>Email</div>
        <Input
          className={errorField !== undefined && errorField.includes("coach_email") ? `common-input-error` : `common-input`}
          value={team.coach_email}
          onChange={(e) => {
            if (errorField.includes("coach_email")) {
              let errors = errorField.filter((x) => x != "coach_email");
              setErrorField([...errors]);
            }
            setTeam({ ...team, coach_email: e.target.value });
          }}
        />
      </Row>
    </Modal>
  );
}

export default confirmable(TeamModal);
