import React from "react";
import { Input, Row, Col, Select } from "antd";

import "./SearchWidget.css";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { useState } from "react";
import ExportExcelWidget from "components/ExportExcelWidget/ExportExcelWidget";
import { useEffect } from "react";
const { Search } = Input;
const { Option } = Select;
function SearchWidget(props) {
  const {
    dataList,
    groupList,
    isGroup,
    groupVal,
    setGroupVal,
    onClear,
    onSearch,
    searchKey,
    setSearchKey,
    sport_type,
  } = props;
  const [excelData, setExcelData] = useState([]);
  useEffect(() => {
    if (dataList.length > 0) {
      let tmpList = [];
      if (sport_type != undefined && sport_type == "camp") {
        dataList.map((x) => {
          let tmpObj = {
            "Registration Name": x.registration_name,
            "Team Name": x.team_name == null ? "" : x.team_name,
            "Partipant Name": x.camper_first_name + " " + x.camper_last_name,
            Age: x.camper_age,
            Grade: x.camper_grade == 0 ? "K" : x.camper_grade,
            Street: x.camper_street,
            City: x.camper_city,
            State: x.camper_state,
            "Zip/Postal Code": x.camper_zip,
            "Camper T-Shirt Size": x.camper_t_shirt,
            "List of known allergies, health or behavioral concerns": window.atob(window.btoa(x.camper_health)),
            "Parent First Name": x.p_first_name,
            "Parent Last Name": x.p_last_name,
            "Parent Email": x.p_email,
            "Parent Mobile Telephone Number": x.p_cell_phone,
            "Parent Home Telephone Number": x.p_home_phone,
            "Parent Mobile Carrier": x.p_relation,
            "Additional Contact First Name": x.a_first_name,
            "Additional Contact Last Name": x.a_last_name,
            "Additional Mobile Telephone Number": x.a_cell_phone,
            "Additional Home Telephone Number": x.a_home_phone,
            "Additional Email Address": x.a_email,
            "Additional Relation to Camper": x.a_relation,
            "Contact First Name": x.c_first_name,
            "Contact Last Name": x.c_last_name,
            "Contact Email": x.c_email,
            "Contact Phone Number": x.c_cell_phone,
            "Registration Date": x.reg_date,
            "Total Checkout": x.total_checkout,
            Fee: x.processing_fee,
            "Payment Method": x.payment_method,
          };
          tmpList = [...tmpList, tmpObj];
        });
      } else {
        dataList.map((x) => {
          let tmpObj = {
            "Registration Group": x.fee_label,
            "Team Name": x.team_name,
            "Partipant Name": x.first_name + " " + x.last_name,
            "Participant Gender": x.gender,
            "Participant Dob": x.birthday,
            "Participant Email": x.email,
            "Participant Telephone": x.tele_phone,
            "Participant Grade": x.grade == "0" ? "K" : x.grade,
            "Participant Height (in inches)": x.height,
            "Previous basketball experience": x.experience,
            "Parent/Guardian First Name": x.p_first_name,
            "Parent/Guardian Last Name": x.p_last_name,
            "Parent/Guardian Home Phone": x.p_home_phone,
            "Parent/Guardian Cell Phone": x.p_cell_phone,
            "Parent/Guardian Email": x.p_email,
            "Parent/Guardian Relation": x.p_relation,
            Address: x.address,
            City: x.city,
            "State/Province": x.state,
            "Zip/Postal Code": x.zip,
            "Adult interested in Coaching?": x.coaching,
            "Contact First Name": x.c_first_name,
            "Contact Last Name": x.c_last_name,
            "Contact Email": x.c_email,
            "Contact Phone Number": x.c_cell_phone,
            "Registration Date": x.reg_date,
            Fee: x.fee,
            "Processing Fee": x.processing_fee,
            "Total Fee": x.total_fee,
            Status: x.payment_status,
            "Discount Code": x.discount_code,
          };
          tmpList = [...tmpList, tmpObj];
        });
      }
      setExcelData(tmpList);
    }
  }, [dataList]);
  return (
    <Row className="follower-cnt-txt" align="middle" justify="space-between">
      <Col>
        <span>Count: {isGroup ? groupList.length : dataList.length}</span>
      </Col>
      <Col>
        <Row gutter={32} align="middle">
          <Col>
            <Search
              placeholder={isGroup ? "Search label" : "Search name or email"}
              allowClear
              onSearch={(v) => {
                onSearch(v);
              }}
              onChange={(e) => {
                setSearchKey(e.target.value)
              }}
              value={searchKey}
              enterButton
            />
          </Col>
          <Col>
            <Select
              className={`common-select`}
              placeholder="Group by"
              style={{ width: 270 }}
              value={groupVal == "" ? null : groupVal}
              onSelect={(v) => {
                setGroupVal(v);
              }}
            >
              <Option value="timestamp">Reg Date</Option>
              {sport_type == undefined ||
                (sport_type != "camp" && (
                  <>
                    <Option value="fee_label">Registration Group</Option>
                    <Option value="gender">Gender</Option>
                    <Option value="discount_code">Discount Code</Option>
                  </>
                ))}
              {sport_type != undefined && sport_type == "camp" && (
                <Option value="trip">Field Trips</Option>
              )}
              <Option value="payment_method">Payment method</Option>
            </Select>
            <CommonButton
              style={{ marginLeft: 10 }}
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </CommonButton>
          </Col>
          <Col>
            <ExportExcelWidget
              excelData={excelData}
              fileName={"registration_users"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SearchWidget;
