import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Divider, Spin, Row, Col, message } from "antd";
import "./StandingPage.css";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import StandingTable from "./StandingTable/StandingTable";
import {
  apiGetRegistrationDetailByGroup,
  apiGetStandingList,
} from "services/dashboardService";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiGetDivisionAll } from "services/divisionService";

const { Option } = Select;
function StandingPage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [dataList, setDataList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [curOption, setCurOption] = useState("");

  const [divisionIDs, setDivisionIDs] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  useEffect(() => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      getDivisionList();
    } else {
      setDataList([]);
    }
    setCurOption("");
  }, [appDataStore]);
  const getData = (optionValue = "") => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("division_id", optionValue);
    apiGetStandingList(params)
      .then((res) => {
        if (res != null && res.length > 0) {
          setDataList(res);
        } else {
          setDataList([]);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const getDivisionList = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", appDataStore.club.id);
    params.append("registration_id", appDataStore.curRegistration.ID);
    params.append("user_id", userDataStore.user.ID);
    setIsBusy(true);
    apiGetDivisionAll(params)
      .then((res) => {
        if (res != null) {
          if (res.list.length > 0) {
            let tmpList = [...res.list]
            let idStr = tmpList.map((x)=>{
              return x.ID
            }).join(",")
            setDivisionIDs(idStr)
            setDivisionList(
              res.list.map((x, index) => {
                return { ...x, key: `parent:` + index };
              })
            );
            getData(idStr);
          } else {
            setDivisionList([]);
          }
        } else {
          setDivisionList([]);
        }
        setIsBusy(false);
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };

  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="registration-page">
          <ClubBoxWidget />
          <div>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <div className="club-box">
              <span className="club-name" style={{ paddingRight: 58 }}>
                Division:
              </span>
              <Select
                className="select-club"
                style={{ width: 300 }}
                placeholder="Filter By"
                value={curOption == "" ? null : curOption}
                onSelect={(v) => {
                  setCurOption(v);
                  getData(v);
                }}
              >
                {divisionList != undefined &&
                  divisionList.length > 0 &&
                  divisionList.map((optionInfo, index) => {
                    return (
                      <Option value={optionInfo.ID} key={index}>
                        {optionInfo.division_name}
                      </Option>
                    );
                  })}
              </Select>
              <CommonButton
                style={{ marginLeft: 30 }}
                onClick={() => {
                  setCurOption("");
                  getData();
                }}
              >
                Clear
              </CommonButton>
            </div>
            <Row gutter={32} style={{ marginTop: 20 }}>
              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                <StandingTable dataList={dataList} />
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </CommonDivWidget>
  );
}

export default StandingPage;
