import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Divider, Empty, message, Select, Spin } from "antd";
import {
  apiChangeRegistrationStatus,
  apiGetRegistrationDetail,
} from "services/dashboardService";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./Dashboard.css";
import ClubTable from "./ClubTable/ClubTable";
import ClubBoxWidget from "components/ClubBoxWidget/ClubBoxWidget";
import { ROUTE_HOME_SETTING } from "navigation/CONSTANTS";
const { Option } = Select;
const Dashboard = (props) => {
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const [isBusy, setIsBusy] = useState(false);

  const [regInfoList, setRegInfoList] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (appDataStore.curRegistration != undefined) {
      getRegistrationDetail(appDataStore.curRegistration.ID);
    } else {
      setRegInfoList([]);
      setUserList([]);
    }
  }, [appDataStore]);

  const getRegistrationDetail = (id) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", id);
    params.append("sport_type", userDataStore?.sport_type);
    setIsBusy(true);
    apiGetRegistrationDetail(params)
      .then((res) => {
        let tmpList = [];
        tmpList = [...tmpList, res.info];
        setRegInfoList(tmpList);
        setUserList(res.detail);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };

  const onChangeStatus = (info) => {
    let tmpList = [...regInfoList];
    let tmpIndex = tmpList.findIndex((x) => x.name == info.name);
    let newStatus = tmpList[tmpIndex].status == "OPEN" ? "CLOSE" : "OPEN";
    tmpList[tmpIndex].status = newStatus;

    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", appDataStore?.curRegistration.ID);
    params.append("status", newStatus == "OPEN" ? 1 : 0);
    setIsBusy(true);
    apiChangeRegistrationStatus(params)
      .then((res) => {
        setRegInfoList(tmpList);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onDetail = () => {
    history.push(ROUTE_HOME_SETTING + "/" + appDataStore?.curRegistration.ID);
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <div className="dashboard-page">
          <ClubBoxWidget newReg={true} />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <ClubTable
              dataList={regInfoList}
              onChangeStatus={onChangeStatus}
              onDetail={onDetail}
            />
            {/* <RegFormTable dataList={userList} /> */}
          </div>
        </div>
      </Spin>
    </CommonDivWidget>
  );
};

export default Dashboard;
