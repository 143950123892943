import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Empty, Input, Row, Select, Spin, Switch, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiSaveRegistrationGeneral } from "services/dashboardService";
import { isEmpty } from "utils/GlobalFunctions";
const GeneralTab = (props) => {
  const { Option } = Select;
  const { activityList, registrationID, setRegistrationID, settingInfo } =
    props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [userData, setUserData] = useState({
    discodeList: [],
  });
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (settingInfo != undefined) {
      let tmpActivity = "";
      let tmpSport_id = "";
      let tmpSport = "";
      if (activityList.length > 0) {
        let tmpList = [...activityList];
        let selActivity = tmpList.filter(
          (z) =>
            z.NAME == settingInfo.registration_info.club_sport &&
            z.TYPE == settingInfo.registration_info.club_sport_id
        )[0];

        if (selActivity != undefined) {
          tmpActivity = parseInt(selActivity.ID);
          tmpSport_id = selActivity.TYPE;
          tmpSport = selActivity.NAME;
        }
      }

      let tmpDisList = [...settingInfo.discount_codes];
      tmpDisList = tmpDisList.map((x) => {
        return {
          ID: x.ID,
          visible: 1,
          text: x.discount_code,
          percentage: x.discount_percentage,
          amount: x.discount_amount,
          free: x.discount_free == "1" ? true : false,
          multipleList: [2, 3, 4, 5, 6],
          type: x.type,
          row_index: x.row_index,
        };
      });
      setUserData({
        ...userData,
        domain: settingInfo.registration_info.domain,
        name: settingInfo.registration_info.registration_name,
        merchant: settingInfo.registration_info.merchant,
        discodeList: tmpDisList,
        activity: tmpActivity,
        sport_id: tmpSport_id,
        sport: tmpSport,
      });
    }
  }, [settingInfo, activityList]);
  const onAddCode = () => {
    let tmpList = [...userData.discodeList];
    let oneCode = {
      text: "",
      percentage: "",
      amount: "",
      free: true,
      visible: 1,
      type: "single",
      row_index: "",
      multipleList: [2, 3, 4, 5, 6],
    };
    tmpList = [...tmpList, oneCode];
    setUserData({ ...userData, discodeList: tmpList });
  };

  const validateFields = () => {
    let errorList = Array();
    errorList = isEmpty(userData, "name", errorList);
    errorList = isEmpty(userData, "domain", errorList);
    errorList = isEmpty(userData, "activity", errorList);
    errorList = isEmpty(userData, "merchant", errorList);
    
    let tmpList = [...userData.discodeList];
    let isDuplicateRow = false;
    let tmpRow = "";
    tmpList.map((x, index) => {
      if (x.type == "single") {
        errorList = isEmpty(x, "text", errorList, index);
      } else {
        let tmpErr = isEmpty(x, "row_index", errorList, index);
        if (tmpErr.length == 0) {
          if (tmpRow != x.row_index) {
            tmpRow = x.row_index;
          } else {
            isDuplicateRow = true;
          }
        }
        errorList = [...tmpErr];
      }
      if (x.percentage == "" && x.amount == "") {
        errorList = [...errorList, index + 'percentage'];
        errorList = [...errorList, index + 'amount'];
      }

    });
    setErrorField([...errorList]);
    if (isDuplicateRow) {
      message.error("Participants row is duplicated.");
    }
    return errorList.length == 0 && !isDuplicateRow;
  };
  const onSave = () => {
    if (!validateFields()) return;

    let clubID = appDataStore?.club?.id;
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", registrationID);
    params.append("club_id", clubID);
    params.append("sport_id", userData.sport_id);
    params.append("sport", userData.sport);
    params.append("name", userData.name);
    params.append("domain", userData.domain);
    params.append("merchant", userData.merchant);
    params.append("discount_code_list", JSON.stringify(userData.discodeList));
    setIsBusy(true);
    apiSaveRegistrationGeneral(params)
      .then((res) => {
        setIsBusy(false);
        if (
          res != undefined &&
          res.discount_code_list != undefined &&
          res.registration_id != undefined
        ) {
          setRegistrationID(res.registration_id);
          let tmpList = [...res.discount_code_list.reverse()];
          tmpList = tmpList.map((x) => {
            return {
              ID: x.ID,
              visible: 1,
              text: x.discount_code,
              percentage: x.discount_percentage,
              amount: x.discount_amount,
              free: x.discount_free == "1" ? true : false,
              multipleList: [2, 3, 4, 5, 6],
              type: x.type,
              row_index: x.row_index,
            };
          });
          setUserData({ ...userData, discodeList: tmpList });
          message.success("Registration information saved successfully.");
        } else {
          message.error(res?.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Registration Name*
            </div>
            <Input
              value={userData.name}
              className={
                errorField !== undefined && errorField.includes("name")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("name")) {
                  let errors = errorField.filter((x) => x != "name");
                  setErrorField([...errors]);
                }
                setUserData({ ...userData, name: e.target.value });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Domain Url*
            </div>
            <Input
              value={userData.domain}
              prefix="https://"
              className={
                errorField !== undefined && errorField.includes("domain")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("domain")) {
                  let errors = errorField.filter((x) => x != "domain");
                  setErrorField([...errors]);
                }
                setUserData({ ...userData, domain: e.target.value });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Activity Type*
            </div>
            <Select
              showSearch
              className={
                errorField !== undefined && errorField.includes("activity")
                  ? `common-select-error`
                  : `common-select`
              }
              value={userData.activity == undefined ? null : userData.activity}
              onSelect={(v, item) => {
                if (errorField.includes("activity")) {
                  let errors = errorField.filter((x) => x != "activity");
                  setErrorField([...errors]);
                }
                let tmpList = [...activityList];
                let selActivity = tmpList.filter((z) => z.ID == v)[0];
                setUserData({
                  ...userData,
                  sport_id: selActivity.TYPE,
                  sport: selActivity.NAME,
                  activity: v,
                });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activityList.map((x, index) => {
                return (
                  <Option value={parseInt(x.ID)} key={index}>
                    {x.NAME}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Merchant*
            </div>
            <Select
              showSearch
              className={
                errorField !== undefined && errorField.includes("merchant")
                  ? `common-select-error`
                  : `common-select`
              }
              value={userData.merchant == undefined ? null : userData.merchant}
              onSelect={(v) => {
                if (errorField.includes("merchant")) {
                  let errors = errorField.filter((x) => x != "merchant");
                  setErrorField([...errors]);
                }
                setUserData({ ...userData, merchant: v });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"stax"}>Stax</Option>
              <Option value={"forte"}>Forte</Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col>
            <div style={{ fontWeight: 600 }}>Discount Codes</div>
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onAddCode();
              }}
            >
              Add New
            </CommonButton>
          </Col>
        </Row>
        <div>
          {userData?.discodeList != undefined &&
            userData?.discodeList.length > 0 ? (
            <div style={{ marginTop: 20 }}>
              {userData.discodeList.map((x, index) => {
                if (x.visible == 1) {
                  return (
                    <Row
                      key={index}
                      gutter={[16, 16]}
                      style={{ marginTop: 10 }}
                      justify={"space-between"}
                      align={"bottom"}
                    >
                      <Col xs={24} sm={5}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Code Type*
                        </div>
                        <Select
                          value={x.type}
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "type")
                              ? `common-select-error`
                              : `common-select`
                          }
                          onSelect={(v) => {
                            let errors = errorField.filter(
                              (y) =>
                                y != index + "type" &&
                                y != index + "text" &&
                                y != index + "row_index"
                            );
                            setErrorField([...errors]);

                            let tmpCodeList = [...userData.discodeList];
                            tmpCodeList[index].type = v;
                            setUserData({
                              ...userData,
                              discodeList: tmpCodeList,
                            });
                          }}
                        >
                          <Option value={"single"}>Discount</Option>
                          <Option value={"multiple"}>
                            Multiple Participants
                          </Option>
                        </Select>
                      </Col>
                      {x.type == "single" ? (
                        <Col xs={24} sm={5}>
                          <div
                            style={{ paddingBottom: "5px", fontWeight: 600 }}
                          >
                            Code Text*
                          </div>
                          <Input
                            value={x.text}
                            className={
                              errorField !== undefined &&
                                errorField.includes(index + "text")
                                ? `common-input-error`
                                : `common-input`
                            }
                            onChange={(e) => {
                              if (errorField.includes(index + "text")) {
                                let errors = errorField.filter(
                                  (y) => y != index + "text"
                                );
                                setErrorField([...errors]);
                              }
                              let tmpCodeList = [...userData.discodeList];
                              tmpCodeList[index].text = e.target.value;
                              setUserData({
                                ...userData,
                                discodeList: tmpCodeList,
                              });
                            }}
                          />
                        </Col>
                      ) : (
                        <Col xs={24} sm={5}>
                          <div
                            style={{ paddingBottom: "5px", fontWeight: 600 }}
                          >
                            # of Participants*
                          </div>
                          <Select
                            value={x.row_index}
                            className={
                              errorField !== undefined &&
                                errorField.includes(index + "row_index")
                                ? `common-select-error`
                                : `common-select`
                            }
                            onSelect={(v) => {
                              if (errorField.includes(index + "row_index")) {
                                let errors = errorField.filter(
                                  (y) => y != index + "row_index"
                                );
                                setErrorField([...errors]);
                              }
                              let tmpCodeList = [...userData.discodeList];
                              tmpCodeList[index].row_index = v;
                              setUserData({
                                ...userData,
                                discodeList: tmpCodeList,
                              });
                            }}
                          >
                            {x.multipleList.map((row, rowKey) => {
                              return (
                                <Option value={row} key={rowKey}>
                                  {row}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                      )}
                      <Col xs={24} sm={5}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Percentage
                        </div>
                        <Input
                          value={x.percentage}
                          type={"number"}
                          prefix=""
                          suffix="%"
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "percentage")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "percentage") || errorField.includes(index + "amount")) {
                              let errors = errorField.filter(
                                (y) => y != index + "percentage"
                              );
                              errors = errorField.filter(
                                (y) => y != index + "amount"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCodeList = [...userData.discodeList];
                            tmpCodeList[index].percentage = e.target.value;
                            setUserData({
                              ...userData,
                              discodeList: tmpCodeList,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={5}>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Amount
                        </div>
                        <Input
                          value={x.amount}
                          type={"number"}
                          prefix="$"
                          suffix="USD"
                          className={
                            errorField !== undefined &&
                              errorField.includes(index + "amount")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "percentage") || errorField.includes(index + "amount")) {
                              let errors = errorField.filter(
                                (y) => y != index + "percentage"
                              );
                              errors = errorField.filter(
                                (y) => y != index + "amount"
                              );
                              setErrorField([...errors]);
                            }
                            let tmpCodeList = [...userData.discodeList];
                            tmpCodeList[index].amount = e.target.value;
                            setUserData({
                              ...userData,
                              discodeList: tmpCodeList,
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                          Free
                        </div>
                        <Switch
                          checkedChildren="Free"
                          unCheckedChildren="Non"
                          checked={x.free}
                          style={{ marginBottom: 10 }}
                          onChange={(e) => {
                            let tmpCodeList = [...userData.discodeList];
                            tmpCodeList[index].free = e;
                            setUserData({
                              ...userData,
                              discodeList: tmpCodeList,
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <CloseCircleOutlined
                          style={{
                            color: "red",
                            marginBottom: 15,
                            cursor: "pointer",
                            fontSize: 16,
                          }}
                          onClick={() => {
                            let tmpCodeList = [...userData.discodeList];
                            tmpCodeList[index].visible = 0;
                            setUserData({
                              ...userData,
                              discodeList: tmpCodeList,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  );
                }
              })}
            </div>
          ) : (
            <Empty description="No codes" style={{ marginTop: 20 }} />
          )}
        </div>
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <CommonButton
            style={{ padding: "12px 45px" }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
};
export default GeneralTab;
