import React from "react";
import { Tag, Popconfirm, Space, message, Row, Col } from "antd";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import {
  BuildOutlined,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  MailOutlined,
} from "@ant-design/icons";
export function showColumnAction(info, selDivision, setTitleLabel, editSetup) {
  return (
    <Row gutter={16}>
      <Col>
        {info.is_add ? (
          <>
            <a
              style={{ color: "var(--greenColor)" }}
              onClick={async () => {
                selDivision(info);
                setTitleLabel(info.division_name);
              }}
            >
              <EditFilled /> Edit
            </a>
            <a
              style={{ color: "var(--blueColor)", marginLeft: 20 }}
              onClick={async () => {
                editSetup(info);
              }}
            >
              <BuildOutlined /> Create Staged Schedule
            </a>
          </>
        ) : (
          <a
            style={{ color: "var(--blueColor)" }}
            onClick={async () => {
              selDivision(info);
              setTitleLabel(info.division_name);
            }}
          >
            <EditFilled /> Add
          </a>
        )}
      </Col>
    </Row>
  );
}
