
import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./RegUserColumn";
function RegUserTable(props) {
  const { dataList, addUser, selRosters, setSelectedUsers, selectedRowKeys, setSelectedRowKeys } = props;
  if (dataList === undefined) return <div />

  const columns = [
    {
      title: "Participant Name",
      dataIndex: 'name',
      width: '300px',
      sorter: (a, b) => `${a.last_name}`.localeCompare(`${b.last_name}`),
    },
    {
      title: "Action",
      width: "150px",
      dataIndex: "operator",
      render: (op, info) => {
        return showColumnAction(info, addUser);
      },
    },
    {
      title: "Gender",
      dataIndex: 'gender',
      width: '100px',
      sorter: (a, b) => `${a.gender}`.localeCompare(`${b.gender}`),
    },
    {
      title: "DOB",
      dataIndex: 'birthday',
      width: '100px',
      render: (info) => {
        var formattedTime = ""
        if (info !== undefined && info != null & info != "") {
          let year = info.split("-")[0]
          let month = info.split("-")[1]
          let day = info.split("-")[2]
          formattedTime = month + '/' + day + '/' + year.substr(-2);
        }

        return formattedTime
      },
      sorter: (a, b) => `${a.birthday}`.localeCompare(`${b.birthday}`),
    },
  ];

  const dataSource = dataList.length == 0 ? [] : dataList.map((data, index) => {
    return { ...data, key: index };
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedUsers(selectedRows)
    },
  };
  const paginationProps = {
    total: dataSource.length,
    showSizeChanger: true,
    defaultPageSize: 12,
    pageSizeOptions: ["10", "12", "20", "30"],
    locale: { items_per_page: "" },
  };
  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={{
          ...rowSelection,
        }}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        scroll={{ x: 400 }}
        pagination={paginationProps}
      />
    </div>
  );
}

export default withRouter(RegUserTable);
