import { message, Slider } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { confirmable } from "react-confirm";
import AvatarEditor from "react-avatar-editor";
import "./ImageReadDlg.css";

const ImageReadDlg = (props) => {
  const { show, proceed } = props;
  const { title, currentImage, ratio } = props;
  const [imageRef, setImageRef] = useState();
  const [newImageFile, setNewImageFile] = useState();
  const [modificationInfo, setModificationInfo] = useState({
    scale: 1,
    rotation: 0,
  });
  const onDropImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    if (files[0].size / 1000 / 1000 > 2) {
      message.error("Image size should not be greater than 2Mb.");
      return;
    }
    setModificationInfo({
      scale: 1,
      rotation: 0,
    });
    setNewImageFile(files[0]);
  };
  const onClickSave = () => {
    if (newImageFile === undefined) {
      proceed(null);
    }
    if (imageRef !== null) {
      try {
        const url = imageRef.getImageScaledToCanvas().toDataURL();
        const file = DataURLtoFile(url, "avatar_img.png");
        proceed(file);
      } catch (e) {
        proceed(newImageFile);
      }
      //   this.setState({ userProfilePic: url });
    }
  };
  const DataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  const onSetImageRef = (editor) => {
    if (editor !== null) setImageRef(editor);
  };
  return (
    <Modal
      onCancel={() => {
        proceed(null);
      }}
      open={show}
      closable={true}
      maskClosable={false}
      title={title}
      style={{ minWidth: ratio === undefined ? 300 : 200 * ratio + 100 }}
      onOk={() => {
        onClickSave();
      }}
      className="new-image-upload-dialog"
    >
      <div className="new-image-upload-container">
        <AvatarEditor
          ref={onSetImageRef}
          image={newImageFile === undefined ? currentImage : newImageFile}
          height={200}
          width={ratio === undefined ? 200 : 200 * ratio}
          scale={parseFloat(modificationInfo.scale)}
          rotate={parseFloat(modificationInfo.rotation)}
          backgroundColor={null}
        />
        {newImageFile !== undefined && (
          <div>
            <div style={{ textAlign: "left" }}>
              <span>Scale</span>
              <Slider
                min={0.2}
                max={3}
                tooltipVisible={false}
                value={modificationInfo.scale}
                onChange={(v) => {
                  setModificationInfo({ ...modificationInfo, scale: v });
                }}
                step={0.01}
                trackStyle={{ backgroundColor: "var(--blueColor)" }}
                handleStyle={{ borderColor: "var(--blueColor)" }}
              />
            </div>
            <div style={{ textAlign: "left" }}>
              <span>Rotation</span>
              <Slider
                min={-180}
                max={180}
                tooltipVisible={false}
                value={modificationInfo.rotation}
                onChange={(v) => {
                  setModificationInfo({ ...modificationInfo, rotation: v });
                }}
                step={1}
                trackStyle={{ backgroundColor: "var(--blueColor)" }}
                handleStyle={{ borderColor: "var(--blueColor)" }}
              />
            </div>
          </div>
        )}

        <div className="new_image_uploader">
          <label htmlFor="new_image_uploader">
            <input
              type="file"
              id="new_image_uploader"
              name="new_image_uploader"
              style={{ display: "none" }}
              accept="image/x-png, image/jpeg, image/jpg"
              onChange={onDropImage}
            />
            <span className="new-image-upload-image-button custom-button">
              Upload new image
            </span>
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default confirmable(ImageReadDlg);
