import { message } from "antd";
import { useAuth } from "navigation/Auth/ProvideAuth";
import CodeVerificationWidget from "components/CodeVerificationWidget/CodeVerificationWidget";

import { REGISTER_WITH } from "config/CONSTANTS";
import { ROUTE_DASHBOARD, ROUTE_REGISTER } from "navigation/CONSTANTS";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  UpdateCurClub,
  UpdateCurRegistration
} from "redux/actions/appActions";
import { updateUser } from "redux/actions/userActions";
import InputPage from "./InputPage";

const LOGIN_PAGES = {
  InputPage: "InputPage",
  ActivationCodePage: "ActivationCodePage",
  InputLocationPage: "InputLocationPage",
};
const pageList = [
  LOGIN_PAGES.InputPage,
  LOGIN_PAGES.ActivationCodePage,
  LOGIN_PAGES.InputLocationPage,
];
function LoginPage(props) {
  const appDataStore = useSelector((x) => x.appDataStore);
  const dispatch = useDispatch();
  const auth = useAuth();
  const [userDetail, setUserDetail] = useState({
    register_with: REGISTER_WITH.email,
  });
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    dispatch(updateUser(null));
    dispatch(UpdateCurClub({ club: undefined }));
    dispatch(
      UpdateCurRegistration({
        curRegistration: undefined,
      }))
  }, []);

  const onClickLogin = async () => {
    setIsBusy(true);
    auth
      .signin(userDetail)
      .then((res) => {
        setIsBusy(false);
        onSuccessLogin(res);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const onSuccessLogin = (info) => {
    if (info.msg === undefined || info.user === undefined) {
      return;
    }
    let sport_type = "basketball";
    if (
      info?.default_member.SPORT_INFO.PRODUCTION_GROUP_ID == 3 &&
      info?.default_member.SPORT_INFO.TYPE == 7
    ) {
      sport_type = "camp";
    }
    dispatch(updateUser({ ...info, token: info.msg, sport_type }));
    // const { from } = { from: { pathname: appDataStore.currentRoute === undefined ? ROUTE_DASHBOARD : appDataStore.currentRoute } };
    const { from } = {
      from: {
        pathname:
          appDataStore.currentRoute === undefined
            ? ROUTE_DASHBOARD
            : ROUTE_DASHBOARD,
      },
    };
    setTimeout(() => {
      props.history.replace(from.pathname == "/login" ? { pathname: ROUTE_DASHBOARD } : from);
    }, 500);
  };
  const onClickRegister = () => {
    props.history.push(ROUTE_REGISTER);
  };

  const onClickRegisterVendor = () => { };
  return (
    <div style={{ color: "var(--blackColor)" }}>
      <InputPage
        onClickLogin={onClickLogin}
        onClickRegister={onClickRegister}
        onClickRegisterVendor={onClickRegisterVendor}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        isBusy={isBusy}
      />
    </div>
  );
}

export default withRouter(LoginPage);
