import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Input, Row, message, Select, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";

import "./RosterAddModal.css"
import { isEmpty } from "utils/GlobalFunctions";
import { apiGetTeamList } from "services/divisionService";
function RosterAddModal(props) {
  const { show, proceed, title, selRoster, divisionList, token, multiple } = props
  const Option = { Select }
  const [roster, setRoster] = useState({ name: "", id: "" })
  const [teamList, setTeamList] = useState([])
  const [errorField, setErrorField] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (selRoster !== undefined) {
      if (multiple) {
        let tmpList = [...selRoster]
        setRoster({...roster, name: tmpList.map((x)=>x.name).join(","), id: tmpList.map((x)=>x.id).join(",")})
      } else {
        setRoster(selRoster)
      }
      
    }
  }, [selRoster])
  const getTeamData = (division_id) => {
    if (selRoster == undefined) return;
    setIsBusy(true)
    const params = new URLSearchParams()
    params.append("token", token)
    params.append("division_id", division_id)
    setIsBusy(true);
    apiGetTeamList(params)
      .then((res) => {
        setIsBusy(false);
        setTeamList(res)
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      })

  }
  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(roster, "team_id", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if (validateFields()) {
      proceed(roster);
    }
  };

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="roster-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        <div style={{ paddingBottom: "10px", color: "var(--blueColor)" }}>Participant: {roster.name}</div>
        <Row>
          <div style={{ paddingBottom: "5px" }}>Division*</div>
          <Select
            className={
              errorField !== undefined && errorField.includes("division_id")
                ? `group-select-error`
                : `group-select`
            }
            style={{ width: "100%" }}
            value={roster.division_id == "" ? null : roster.division_id}
            onSelect={(v) => {
              setRoster({ ...roster, division_id: v });
              getTeamData(v)
            }}
          >
            {divisionList.map((division) => {
              return (
                <Option
                  key={division.ID}
                  value={division.ID}
                >
                  {division.division_name}
                </Option>
              )
            })}
          </Select>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <div style={{ paddingBottom: "5px" }}>Team*</div>
          <Select
            className={
              errorField !== undefined && errorField.includes("team_id")
                ? `group-select-error`
                : `group-select`
            }
            style={{ width: "100%" }}
            value={roster.team_id == "" ? null : roster.team_id}
            onSelect={(v) => {
              setRoster({ ...roster, team_id: v });
            }}
          >
            {teamList.map((team) => {
              return (
                <Option
                  key={team.ID}
                  value={team.ID}
                >
                  {team.team_name}
                </Option>
              )
            })}
          </Select>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(RosterAddModal);
