import { Modal, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateEvent } from "services/divisionService";
import "./CreateScheduleModal.css";
function CreateScheduleModal(props) {
  const { show, proceed } = props;
  const { title, subtitle, content, stageList, token } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);

  useEffect(() => {
    if (stageList != undefined) {
      createEvent();
    }
  }, [stageList]);

  const createEvent = async () => {
    setIsLoading(true);
    const cnt_avg = Math.ceil((100 / stageList.length).toFixed(2));
    const current_progress = 0;
    let progressStatus = {
      cnt_avg: cnt_avg,
      current_progress: current_progress,
    };
    let invite_IDs = [];
    for (let i = 0; i < stageList.length; i++) {
      const params = new URLSearchParams();
      params.append("token", token);
      params.append("home_team_id", stageList[i].home_team_beclutch_id);
      params.append("away_team_id", stageList[i].away_team_beclutch_id);
      params.append("home_opponent_id", stageList[i].home_team_opponent_id);
      params.append("away_opponent_id", stageList[i].away_team_opponent_id);
      params.append("year", stageList[i].start_date == "" ? "" : stageList[i].start_date.split("/")[2]);
      params.append("month", stageList[i].start_date == "" ? "" : stageList[i].start_date.split("/")[0]);
      params.append("day_of_month", stageList[i].start_date == "" ? "" : stageList[i].start_date.split("/")[1]);
      params.append(
        "start_time_hour",
        parseInt(stageList[i].start_time.split(":")[0])
      );
      params.append(
        "start_time_min",
        stageList[i].start_time == "" ? "" : parseInt(stageList[i].start_time.split(" ")[0].split(":")[1])
      );
      params.append("start_time_ampm", stageList[i].start_time == "" ? "" : stageList[i].start_time.split(" ")[1].toUpperCase());
      params.append(
        "arrival_time_hour",
        (stageList[i].arrival_time_hour == null || stageList[i].arrival_time_hour == "") ? parseInt(stageList[i].start_time.split(":")[0]) : stageList[i].arrival_time_hour
      );
      params.append(
        "arrival_time_min",
        (stageList[i].arrival_time_min == null || stageList[i].arrival_time_min == "") ? stageList[i].start_time == "" ? "" : parseInt(stageList[i].start_time.split(" ")[0].split(":")[1]) : stageList[i].arrival_time_min
      );
      params.append("arrival_time_ampm", (stageList[i].arrival_time_min == null || stageList[i].arrival_time_min == "") ? stageList[i].start_time == "" ? "" : stageList[i].start_time.split(" ")[1].toUpperCase() : stageList[i].arrival_time_min.toUpperCase());
      params.append("location_id", stageList[i].location_id);
      params.append("addr", stageList[i].address);
      params.append("memo", stageList[i].memo);
      params.append("notify", stageList[i].notify);
      params.append("duration_hour", stageList[i].duration_hour);
      params.append("duration_min", stageList[i].duration_min);
      params.append("day_of_week", stageList[i].week_of_start_date);

      const response = await apiCreateEvent(params);

      if (response == null || response == 0) continue;
      if (response == 1) {
        invite_IDs = [...invite_IDs, stageList[i]["ID"]];
      }
      progressStatus.current_progress += progressStatus.cnt_avg;
      setLoadingPercent(progressStatus.current_progress.toFixed(2));
    }
    setIsLoading(false);
    proceed(invite_IDs.length == 0 ? false : invite_IDs.join(","));
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="schedule-progress-modal"
      closable={true}
      maskClosable={false}
      title={title}
      footer={null}
    >
      <p className="schedule-progressing-title">{content}</p>
      <Progress
        strokeColor={{
          "0%": "rgb(96 168 220)",
          "100%": "#87d068",
        }}
        percent={loadingPercent}
      />
    </Modal>
  );
}

export default confirmable(CreateScheduleModal);
