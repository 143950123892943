import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Radio, Checkbox } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const StepItemWidget = (props) => {
  const { itemInfo, onCheck, isLast, userData } = props;

  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ fontWeight: 600 }}>
        <div style={{ marginTop: 10, marginBottom: 5 }}>
          <Row align={"top"} justify="space-between" gutter={16}>
            <Col md={22}>
              <Checkbox
                onChange={(e) => {
                  if (onCheck != undefined) {
                    onCheck(itemInfo.id);
                  }
                }}
                checked={userData.camper_sel_trip.includes(
                  itemInfo.id
                )}
              >
                {itemInfo.title.split('\\n').map((section, secKey) => {
                  return <div key={secKey}>{section}</div>;
                })}
              </Checkbox>
            </Col>
            <Col md={2} style={{ textAlign: "right" }}>
              ${itemInfo.price}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default StepItemWidget;
