import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, Col, message, Select, Radio, Input, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./LocationModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import { apiAddLocation } from "services/divisionService";

const { Option } = Select;
function LocationModal(props) {
  const { show, proceed, title, token } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [location, setLocation] = useState({
    name: "",
    street: "",
    city: "",
    zip: "",
    state: "",
    state_idx: ""
  });
  const stateList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [errorField, setErrorField] = useState([]);

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(location, "name", errorList);
    errorList = isEmpty(location, "street", errorList);
    errorList = isEmpty(location, "state", errorList);
    errorList = isEmpty(location, "city", errorList);
    errorList = isEmpty(location, "zip", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onCreate = () => {
    if (!validateFields()) return;
    if (location.name.includes("-") || location.name.includes(",")) {
      message.error("name can't include character `,` or `-` ")
      return;
    }
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("name", location.name);
    params.append("street", location.street);
    params.append("state", location.state);
    params.append("city", location.city);
    params.append("zip", location.zip);
    params.append("country", "United States");
    params.append("country_idx", 0);
    params.append("state_idx", location.state_idx);
    params.append("link", "");
    params.append("note", "");
    params.append("lat", "");
    params.append("lot", "");
    setIsBusy(true);
    apiAddLocation(params)
      .then((res) => {
        setIsBusy(false);
        proceed(location);
        message.success("Location has been added successfully.");
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
    
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="location-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText="Add"
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        <Row style={{ marginTop: 20 }}>
          <div style={{ paddingBottom: "5px" }}>Name*</div>
          <Input
            value={location.name}
            className={
              errorField !== undefined && errorField.includes("name")
                ? `common-input-error`
                : `common-input`
            }
            onChange={(e) => {
              if (errorField.includes("name")) {
                let errors = errorField.filter((x) => x != "name");
                setErrorField([...errors]);
              }
              setLocation({ ...location, name: e.target.value });
            }}
          />
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>Street*</div>
            <Input
              value={location.street}
              className={
                errorField !== undefined && errorField.includes("street")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("street")) {
                  let errors = errorField.filter((x) => x != "street");
                  setErrorField([...errors]);
                }
                setLocation({ ...location, street: e.target.value });
              }}
            />
          </Col>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>City*</div>
            <Input
              value={location.city}
              className={
                errorField !== undefined && errorField.includes("city")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("city")) {
                  let errors = errorField.filter((x) => x != "city");
                  setErrorField([...errors]);
                }
                setLocation({ ...location, city: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>State/Province*</div>
            <Select
              showSearch
              className={
                errorField !== undefined && errorField.includes("state")
                  ? `group-select-error`
                  : `group-select`
              }
              value={location.state == undefined ? null : location.state}
              onSelect={(v1, v2) => {
                if (errorField.includes("state")) {
                  let errors = errorField.filter((x) => x != "state");
                  setErrorField([...errors]);
                }
                setLocation({ ...location, state: v1, state_idx: v2.key + 1 });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {stateList.map((x, index) => {
                return <Option value={x} key={index}>{x}</Option>;
              })}
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <div style={{ paddingBottom: "5px" }}>Zip/Postal Code*</div>
            <Input
              value={location.zip}
              className={
                errorField !== undefined && errorField.includes("zip")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("zip")) {
                  let errors = errorField.filter((x) => x != "zip");
                  setErrorField([...errors]);
                }
                setLocation({ ...location, zip: e.target.value });
              }}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(LocationModal);
