import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import {
  Switch,
  Row,
  message,
  TreeSelect,
  Radio,
  Input,
  Col,
  Spin,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import "./ResultModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiUpdateGameScore } from "services/divisionService";

const { SHOW_PARENT } = TreeSelect;
function ResultModal(props) {
  const { show, proceed, info, token } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [resultInfo, setResultInfo] = useState({
    home_team: "",
    away_team: "",
    memo: "",
    GAME_ID: "",
  });
  const [errorField, setErrorField] = useState([]);

  useEffect(() => {
    if (info != undefined) {
      setResultInfo({
        ...resultInfo,
        GAME_ID: info.GAME_ID,
        home_team: info.TEAM_SCORE,
        away_team: info.OPPONENT_SCORE,
        memo: info.MEMO == "null" ? "" : info.MEMO,
      });
    }
  }, [info]);
  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(resultInfo, "home_team", errorList);
    errorList = isEmpty(resultInfo, "away_team", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onFinal = () => {
    if (!validateFields()) return;
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("game_id", resultInfo.GAME_ID);
    params.append("us_score", resultInfo.home_team);
    params.append("them_score", resultInfo.away_team);
    params.append("memo", resultInfo.memo);
    params.append("flag", 1);
    setIsBusy(true);
    apiUpdateGameScore(params)
      .then((res) => {
        setIsBusy(false);
        message.success("Updated successfully")
        proceed(null)
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onUpdate = () => {
    if (!validateFields()) return;
    const params = new URLSearchParams();
    params.append("token", token);
    params.append("game_id", resultInfo.GAME_ID);
    params.append("us_score", resultInfo.home_team);
    params.append("them_score", resultInfo.away_team);
    params.append("memo", resultInfo.memo);
    params.append("flag", 0);
    setIsBusy(true);
    apiUpdateGameScore(params)
      .then((res) => {
        setIsBusy(false);
        message.success("Updated successfully")
        proceed(null)
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="result-dlg"
      closable={true}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={isBusy}>
        <Row gutter={16} justify="center" align="middle">
          <Col xs={9}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Home Team
            </div>
            <Input
              value={resultInfo.home_team}
              type="number"
              className={
                errorField !== undefined && errorField.includes("home_team")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("home_team")) {
                  let errors = errorField.filter((x) => x != "home_team");
                  setErrorField([...errors]);
                }
                setResultInfo({ ...resultInfo, home_team: e.target.value });
              }}
            />
          </Col>
          <Col xs={6}>
            <div
              style={{
                marginTop: "15px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              VS
            </div>
          </Col>
          <Col xs={9}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
              Away Team
            </div>
            <Input
              value={resultInfo.away_team}
              type="number"
              className={
                errorField !== undefined && errorField.includes("away_team")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("away_team")) {
                  let errors = errorField.filter((x) => x != "away_team");
                  setErrorField([...errors]);
                }
                setResultInfo({ ...resultInfo, away_team: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col xs={24}>
            <Input
              value={resultInfo.memo}
              placeholder="Memo"
              className="common-input"
              onChange={(e) => {
                setResultInfo({ ...resultInfo, memo: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: 30 }}>
          <Col>
            <CommonButton
              style={{
                width: "100%",
                backgroundColor: "#b90e06",
                padding: "8px 30px",
              }}
              onClick={() => {
                proceed(null);
              }}
            >
              Cancel
            </CommonButton>
          </Col>
          <Col>
            <CommonButton
              style={{
                width: "100%",
                backgroundColor: "rgb(25 128 227)",
                padding: "8px 40px",
              }}
              onClick={() => {
                onFinal();
              }}
            >
              Final
            </CommonButton>
          </Col>
          <Col>
            <CommonButton
              style={{ width: "100%", padding: "8px 30px" }}
              onClick={() => {
                onUpdate();
              }}
            >
              Update
            </CommonButton>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(ResultModal);
