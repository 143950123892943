import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, message, TreeSelect, Radio, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./MessageOneModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import { CloseOutlined } from "@ant-design/icons";

const { SHOW_PARENT } = TreeSelect;
function MessageOneModal(props) {
  const { show, proceed, title, toEmail } = props;
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [editorState, setEditorState] = useState("");
  const [selFile, setSelFile] = useState("");
  const onEditorStateChange = (e) => {
    if (e != null) {
      let selStr = draftToHtml(convertToRaw(e.getCurrentContent()));
      setContent(selStr);
      setEditorState(e);
    }
  };
  const onDropImage = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    if (files[0].size / 1000 / 1000 > 5) {
      message.error("File size should not be greater than 5Mb.");
      return;
    }
    setSelFile(files[0]);
  };
  const onCreate = () => {
    if (subject == "") {
      message.error("Please input subject");
      return;
    }
    if (content == "") {
      message.error("Please input content");
      return;
    }
    let param = {
      toEmail,
      subject,
      content,
      attachment: selFile
    };
    proceed(param);
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="message-one-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText="Send"
      onOk={onCreate}
    >
      <Row style={{ marginTop: 20 }}>
        <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Subject*</div>
        <Input
          className="custom-input"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <div style={{ marginBottom: 10, fontWeight: 600 }}>
          To*:{" "}
          <span style={{ fontWeight: 400, color: "var(--blueColor)" }}>
            {toEmail}
          </span>
        </div>
        <div className="one-editor-rect">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorOneClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <div className="new_image_uploader">
          <label htmlFor="new_image_uploader">
            <input
              type="file"
              id="new_image_uploader"
              name="new_image_uploader"
              style={{ display: "none" }}
              accept="*"
              onChange={onDropImage}
            />
            <span className="custom-button common-button ">Attach File</span>
          </label>
          {selFile?.name && (
            <span style={{ paddingLeft: 20 }}>
              {selFile?.name}
              <CloseOutlined
                style={{
                  color: "var(--redColor)",
                  marginLeft: 7,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelFile("");
                }}
              />{" "}
            </span>
          )}
        </div>
      </Row>
    </Modal>
  );
}

export default confirmable(MessageOneModal);
