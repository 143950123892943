import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Empty, Input, Row, Select, Spin, Switch, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiSaveRegistrationGeneral, apiSaveRegistrationMain, apiSaveRegistrationWaiver } from "services/dashboardService";
import { isEmpty } from "utils/GlobalFunctions";
const WaiverTab = (props) => {
  const { TextArea } = Input;
  const { registrationID, setRegistrationID, settingInfo } =
    props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [waiverList, setWaiverList] = useState([
    {
      title: "",
      description: "",
      allow_no: false,
      visible: 1
    }
  ]);
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (settingInfo != undefined) {
      let tmpList = [...settingInfo.waiver_list];
      if (tmpList.length > 0) {
        tmpList = tmpList.map((x) => {
          return {
            ID: x.ID,
            visible: 1,
            title: x.waiver_title,
            description: x.waiver_text,
            allow_no: x.allow_no == 1 ? true : false
          };
        });

        setWaiverList(tmpList);
      }
    }
  }, [settingInfo]);
  const onAddWaverItem = () => {
    let tmpList = [...waiverList];
    let oneCode = {
      title: "",
      description: "",
      allow_no: false,
      zipcodes_allowed: "",
      visible: 1
    };
    tmpList = [...tmpList, oneCode];
    setWaiverList(tmpList);
  };

  const validateFields = () => {
    let errorList = Array();
    let tmpList = [...waiverList];
    tmpList.map((x, index) => {
      errorList = isEmpty(x, "title", errorList, index);
      errorList = isEmpty(x, "description", errorList, index);
    });
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onSave = () => {
    if (!validateFields()) return;

    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", registrationID);
    params.append("waiver_list", JSON.stringify(waiverList));
    setIsBusy(true);
    apiSaveRegistrationWaiver(params)
      .then((res) => {
        setIsBusy(false);
        if (
          res != undefined &&
          res.waiver_list != undefined &&
          res.registration_id != undefined
        ) {
          setRegistrationID(res.registration_id);
          setWaiverList([]);
          let tmpList = [...res.waiver_list];
          tmpList = tmpList.map((x) => {
            return {
              ID: x.ID,
              visible: 1,
              title: x.waiver_title,
              description: x.waiver_text,
              allow_no: x.allow_no == 1 ? true : false
            };
          });
          setWaiverList(tmpList);
          message.success("Waiver saved successfully.");
        } else {
          message.error(res?.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify={"space-between"}>
          <Col>
            <div style={{ fontWeight: 600 }}>Registration Waivers</div>
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onAddWaverItem();
              }}
            >
              Add New Waiver
            </CommonButton>
          </Col>
        </Row>
        <div>
          {waiverList.length > 0 ? (
            <div style={{ marginTop: 20 }}>
              {waiverList.map((x, index) => {
                if (x.visible == 1) {
                  return (
                    <div key={index} style={{ border: "1px solid #eee", padding: 10, marginTop: 10 }}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                          <Row style={{ paddingBottom: "5px", fontWeight: 600 }} justify={"space-between"}>
                            <Col>
                              <div>
                                Waiver Name*
                              </div>
                            </Col>
                            <Col>
                              {index != 0 && <CloseCircleOutlined
                                style={{
                                  color: "red",
                                  marginBottom: 15,
                                  cursor: "pointer",
                                  fontSize: 16,
                                }}
                                onClick={() => {
                                  let tmpList = [...waiverList];
                                  tmpList[index].visible = 0;
                                  setWaiverList(tmpList);
                                }}
                              />}
                            </Col>
                          </Row>
                          <Input
                            value={x.title}
                            className={
                              errorField !== undefined && errorField.includes(index + "title")
                                ? `common-input-error`
                                : `common-input`
                            }
                            onChange={(e) => {
                              if (errorField.includes(index + "title")) {
                                let errors = errorField.filter((y) => y != index + "title");
                                setErrorField([...errors]);
                              }
                              let tmpList = [...waiverList];
                              tmpList[index].title = e.target.value;
                              setWaiverList(tmpList);
                            }}
                          />
                        </Col>
                      </Row>
                      <div style={{ marginTop: 10, fontWeight: 600, paddingBottom: 5 }}>
                        Waiver Text*
                      </div>
                      <div>
                        <TextArea
                          rows={6}
                          value={x.description}
                          className={
                            errorField !== undefined && errorField.includes(index + "description")
                              ? `common-input-error`
                              : `common-input`
                          }
                          onChange={(e) => {
                            if (errorField.includes(index + "description")) {
                              let errors = errorField.filter((y) => y != index + "description");
                              setErrorField([...errors]);
                            }
                            let tmpList = [...waiverList];
                            tmpList[index].description = e.target.value;
                            setWaiverList(tmpList);
                          }}
                        />
                      </div>
                      <Row gutter={[32, 16]} style={{ marginTop: 10 }}>
                        <Col>
                          <div style={{ paddingBottom: "5px", fontWeight: 600 }}>
                            Allow Negative Response
                          </div>
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={x.allow_no}
                            style={{ marginBottom: 10 }}
                            onChange={(e) => {
                              let tmpList = [...waiverList];
                              tmpList[index].allow_no = e;
                              setWaiverList(tmpList);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ marginTop: 60, textAlign: "center" }}>
          <CommonButton
            style={{ padding: "12px 45px" }}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </CommonButton>
        </div>
      </Spin>
    </div>
  );
};
export default WaiverTab;
