import React, { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import "./MessagePage.css";
import { Row, Spin, Col, message } from "antd";
import MessageTable from "./MessageTable/MessageTable";
import { useState } from "react";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import {
  ArrowLeftOutlined,
  ConsoleSqlOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { showMessageModal } from "./MessageModal/showMessageModal";
import { useSelector } from "react-redux";
import {
  apiGetDivisionAll,
  apiGetDivisionTreeList,
  apiGetMessageDetailList,
  apiGetMessageList,
  apiSendMessageGroup,
} from "services/divisionService";
function MessagePage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [isBusy, setIsBusy] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [isDetail, setIsDetail] = useState(false);

  useEffect(() => {
    if (
      appDataStore.club != undefined &&
      appDataStore.curRegistration != undefined
    ) {
      getDivisionTreeList();
    }
    getData();
  }, []);
  const getData = () => {
    setMessageList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("sport_type", userDataStore.sport_type);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiGetMessageList(params)
      .then((res) => {
        setIsBusy(false);
        if (res != null) {
          setMessageList(res);
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const getDivisionTreeList = () => {
    setDivisionList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("user_id", userDataStore.user.ID);
    params.append("sport_type", userDataStore.sport_type);
    params.append("registration_id", appDataStore.curRegistration.ID);
    setIsBusy(true);
    apiGetDivisionTreeList(params)
      .then((res) => {
        setIsBusy(false);
        if (res != null) {
          if (res.length > 0) {
            setDivisionList(res);
          }
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onMailDlg = async () => {
    if (divisionList.length == 0) {
      return;
    }
    var resInfo = await showMessageModal({
      title: "Send Mail",
      token: userDataStore.token,
      dataList: divisionList,
    });
    if (resInfo == null) return;
    let messageTitle = appDataStore?.curRegistration?.registration_name
    let tmpClub = userDataStore.clubs.filter((x) => x.CLUB_OR_TEAM == "1")
    if (tmpClub.length > 0) {
      messageTitle = tmpClub[0].CLUB_TEAM_INFO.NAME
    }
    setIsBusy(true);
    let params = {
      token: userDataStore.token,
      registration_id: appDataStore.curRegistration.ID,
      member_ids: resInfo.mList,
      team_ids: resInfo.tList,
      parent_ids: resInfo.pList,
      cc_member_ids: resInfo.ccDivisionList,
      cc_team_ids: resInfo.ccTeamList,
      cc_parent_ids: resInfo.ccParentList,
      bcc_member_ids: resInfo.bccDivisionList,
      bcc_team_ids: resInfo.bccTeamList,
      bcc_parent_ids: resInfo.bccParentList,
      subject: resInfo.subject,
      content: resInfo.content,
      attachment: resInfo.attachment,
      sport_type: userDataStore.sport_type,
      sel_type: resInfo.selType == 1 ? "Everyone" : "Coach",
      message_title: messageTitle,
      mFlag: resInfo.mFlag,
      ccFlag: resInfo.ccFlag,
      bccFlag: resInfo.bccFlag,
    }
    apiSendMessageGroup(params)
      .then((res) => {
        setIsBusy(false);
        message.success("Message was sent successfully.");
        getData();
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onDetail = (record) => {
    setIsDetail(true);
    setMessageList([]);
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("created_time", record.created);
    setIsBusy(true);
    apiGetMessageDetailList(params)
      .then((res) => {
        setIsBusy(false);
        if (res != null) {
          setMessageList(res);
        }
      })
      .catch((err) => {
        message.error(err);
        setIsBusy(false);
      });
  };
  const onClear = () => {
    setIsDetail(false)
    getData();
  };
  return (
    <CommonDivWidget>
      <Spin spinning={isBusy}>
        <Row justify="space-between">
          <Col>
            {isDetail && (
              <ArrowLeftOutlined
                style={{ color: "var(--mainColor)", cursor: "pointer" }}
                onClick={() => onClear()}
              />
            )}
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onMailDlg();
              }}
            >
              <MailOutlined style={{ marginRight: 10 }} />
              {"Email " + appDataStore?.curRegistration?.registration_name}
            </CommonButton>
          </Col>
        </Row>
        <MessageTable
          dataList={messageList}
          onDetail={onDetail}
          isDetail={isDetail}
        />
      </Spin>
    </CommonDivWidget>
  );
}

export default MessagePage;
