import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, message, TreeSelect, Radio, Input, Col } from "antd";
import Modal from "antd/lib/modal/Modal";
import parse from "html-react-parser";
import "./MessageDetailModal.css";

const { SHOW_PARENT } = TreeSelect;
function MessageDetailModal(props) {
  const { show, proceed, title, subject, content, send_date, attachment } =
    props;

  const onEditorStateChange = (e) => {
    // if (e != null) {
    //   let selStr = draftToHtml(convertToRaw(e.getCurrentContent()));
    //   setContent(selStr);
    //   setEditorState(e);
    // }
  };
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="message-detail-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText="Ok"
      onOk={() => {
        proceed(null);
      }}
    >
      <Row style={{ marginTop: 20 }} gutter={32}>
        <Col style={{ fontWeight: 600 }}>Subject:</Col>
        <Col>{subject}</Col>
      </Row>
      <Row style={{ marginTop: 10 }} gutter={32}>
        <Col style={{ fontWeight: 600 }}>Send Date:</Col>
        <Col>{send_date}</Col>
      </Row>
      <div style={{ marginTop: 10 }}>
        <div style={{ fontWeight: 600 }}>Content</div>
        <div className="editor-rect">
          {parse(content)}
        </div>
      </div>
      {attachment != "" && (
        <div style={{ marginTop: 10 }}>
          <a
            download={attachment}
            href="data:application/octet-stream;base64,PD94ANDSOON"
          >
            Download Attachment
          </a>
        </div>
      )}
    </Modal>
  );
}

export default confirmable(MessageDetailModal);
