import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./RegFormColumn";
import "./RegFormTable.css";
import { numberWithCommas } from "utils/GlobalFunctions";

function RegFormTable(props) {
  const { dataList, setVisible, setUserData, deleteData, sport_type } = props;
  if (dataList === undefined) return <div />;
  let columns = [];
  if (sport_type != undefined && sport_type == "camp") {
    columns = [
      {
        title: "Partipant Name",
        dataIndex: "name",
        fixed: "left",
        width: 160,
        sorter: (a, b) => `${a.camper_last_name}`.localeCompare(`${b.camper_last_name}`),
      },
      {
        title: "Age",
        dataIndex: "camper_age",
        width: 90,
        sorter: (a, b) => `${a.camper_age}`.localeCompare(`${b.camper_age}`),
      },
      {
        title: "Grade",
        dataIndex: "camper_grade",
        width: 90,
        sorter: (a, b) => parseInt(a.camper_grade) - parseInt(b.camper_grade),
        render: (info) => {
          return info == 0 ? "K" : info;
        },
      },

      {
        title: "Registration Date",
        width: 150,
        dataIndex: "reg_date",
        render: (record) => {
          return record?.replaceAll("-", "/");
        },
        sorter: (a, b) => a.reg_date - b.reg_date,
      },
      {
        title: "Team Name",
        dataIndex: "team_name",
        width: 150,
        sorter: (a, b) => `${a.team_name}`.localeCompare(`${b.team_name}`),
      },
      {
        title: "Total CheckOut",
        dataIndex: "total_checkout",
        width: 100,
        render: (info) => {
          return "$" + numberWithCommas(info);
        },
        sorter: (a, b) => parseFloat(a.total_checkout) - parseFloat(b.total_checkout),
      },
      {
        title: "Fee",
        dataIndex: "processing_fee",
        width: 100,
        render: (info) => {
          return "$" + numberWithCommas(info);
        },
        sorter: (a, b) => parseFloat(a.processing_fee) - parseFloat(b.processing_fee),
      },
      {
        title: "Payment Method",
        dataIndex: "payment_method",
        width: 150,
        sorter: (a, b) =>
          `${a.payment_method}`.localeCompare(`${b.payment_method}`),
      },
      {
        title: "Action",
        dataIndex: "operator",
        width: 155,
        fixed: "right",
        render: (op, info) => {
          return showColumnAction(info, setVisible, setUserData, deleteData);
        },
      },
    ];
  } else {
    columns = [
      {
        title: "Partipant Name",
        dataIndex: "name",
        fixed: "left",
        width: 160,
        sorter: (a, b) => `${a.last_name}`.localeCompare(`${b.last_name}`),
      },
      {
        title: "Gender",
        dataIndex: "gender",
        width: 90,
        sorter: (a, b) => `${a.gender}`.localeCompare(`${b.gender}`),
      },
      {
        title: "DOB",
        dataIndex: "birthday",
        width: 100,
        render: (info) => {
          var formattedTime = "";
          if (info !== undefined && (info != null) & (info != "")) {
            let year = info.split("-")[0];
            let month = info.split("-")[1];
            let day = info.split("-")[2];
            formattedTime = month + "/" + day + "/" + year.substr(-2);
          }

          return formattedTime;
        },
        sorter: (a, b) => `${a.birthday}`.localeCompare(`${b.birthday}`),
      },

      {
        title: "Registration Date",
        width: 150,
        dataIndex: "reg_date",
        render: (record) => {
          return record.replaceAll("-", "/");
        },
        sorter: (a, b) => a.reg_date - b.reg_date,
      },
      {
        title: "Registration Group",
        dataIndex: "fee_label",
        width: 150,
        sorter: (a, b) => `${a.fee_label}`.localeCompare(`${b.fee_label}`),
      },
      {
        title: "Team Name",
        dataIndex: "team_name",
        width: 150,
        sorter: (a, b) => `${a.team_name}`.localeCompare(`${b.team_name}`),
      },
      {
        title: "Fee",
        dataIndex: "fee",
        width: 100,
        render: (info) => {
          return "$" + numberWithCommas(info);
        },
        sorter: (a, b) => parseFloat(a.fee) - parseFloat(b.fee),
      },
      {
        title: "Status",
        dataIndex: "payment_status",
        width: 100,
        sorter: (a, b) =>
          `${a.payment_status}`.localeCompare(`${b.payment_status}`),
      },
      {
        title: "Grade",
        dataIndex: "grade",
        width: 80,
        sorter: (a, b) => parseInt(a.grade) - parseInt(b.grade),
        render: (info) => {
          return info == 0 ? "K" : info;
        },
      },
      {
        title: "Height",
        dataIndex: "height",
        width: 65,
        sorter: (a, b) => parseFloat(a.height) - parseFloat(b.height),
      },
      {
        title: "Experience",
        dataIndex: "experience",
        width: 90,
        sorter: (a, b) => `${a.experience}`.localeCompare(`${b.experience}`),
      },
      {
        title: "Will Coach?",
        dataIndex: "coaching",
        width: 150,
        sorter: (a, b) => `${a.coaching}`.localeCompare(`${b.coaching}`),
      },
      {
        title: "Discount Code",
        dataIndex: "discount_code",
        width: 150,
        sorter: (a, b) =>
          `${a.discount_code}`.localeCompare(`${b.discount_code}`),
      },
      {
        title: "Action",
        dataIndex: "operator",
        width: 155,
        fixed: "right",
        render: (op, info) => {
          return showColumnAction(info, setVisible, setUserData, deleteData);
        },
      },
    ];
  }

  const dataSource =
    dataList.length == 0
      ? []
      : dataList.map((data, index) => {
        return { ...data, key: index };
      });

  return (
    <div className="info-list-widget">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        scroll={{ x: 900 }}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default withRouter(RegFormTable);
