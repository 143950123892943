import React from "react";
import { withRouter } from "react-router";
import { Table } from "antd";
import { showColumnAction } from "./DivisionTableColumn";
function DivisionTable(props) {
  const { dataList, setSelDivision, setTitleLabel, editSetup } = props;
  if (dataList === undefined) return <div />;


  const columns = [
    {
      title: "Division Name",
      dataIndex: "division_name",
      key: "division_name",
    },
    {
      title: "Action",
      dataIndex: "operator",
      render: (op, info) => {
        return showColumnAction(
          info,
          setSelDivision,
          setTitleLabel,
          editSetup
        );
      },
    },
  ];

  let dataSource = [];
  dataList.forEach((element, index) => {
    let divisionObj = {
      ID: element.ID,
      club_id: element.club_id,
      registration_id: element.registration_id,
      division_name: element.division_name,
      key: index,
      is_add: element.is_add,
      setup_id: element.setup_id
    };
    dataSource = [...dataSource, divisionObj];
  });

  return (
    <div className="division-list-widget" style={{ marginTop: 20 }}>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={{ pageSize: 20 }}
      />
    </div>
  );
}

export default DivisionTable;
