import { Modal, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { apiCreateRosterInvitation } from "services/divisionService";
import "./ProgressbarDlg.css";
function ProgressbarDlg(props) {
  const { show, proceed } = props;
  const { title, subtitle, content, info, token } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);

  useEffect(() => {
    if (info != undefined) {
      sendInvitation();
    }
  }, [info]);

  const sendInvitation = async () => {
    setIsLoading(true);
    const cnt_avg = Math.ceil((100 / info.invite_avialble.length).toFixed(2));
    const current_progress = 0;
    let progressStatus = {
      cnt_avg: cnt_avg,
      current_progress: current_progress,
    };
    let invite_IDs = [];
    for (let i = 0; i < info.invite_avialble.length; i++) {
      const params = new URLSearchParams();
      params.append("token", token);
      params.append("fname", info.invite_avialble[i].fname);
      params.append("lname", info.invite_avialble[i].lname);
      params.append("email", info.invite_avialble[i].email);
      params.append("role_id", 6);
      params.append("team_name", info.team_name);
      params.append("sport", info.reg_sport_id);
      params.append("sport_title", info.reg_sport_name);
      params.append("club_id", info.reg_club_id);
      params.append("team_id", info.beclutch_team_id);
      params.append("send_mail", 1);
      params.append("role_title", "Player");
      params.append("is_player", 1);
      const response = await apiCreateRosterInvitation(params);
      if (response == null) continue;
      if (response != null) {
        invite_IDs = [...invite_IDs, info.invite_avialble[i]["ID"]];
      }
      progressStatus.current_progress += progressStatus.cnt_avg;
      setLoadingPercent(progressStatus.current_progress.toFixed(2));
    }
    setIsLoading(false);
    proceed(invite_IDs.length == 0 ? false : invite_IDs.join(","))
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="progress-modal"
      closable={true}
      maskClosable={false}
      title={title}
      footer={null}
    >
      <p className="progressing-title">{content}</p>
      <Progress
        strokeColor={{
          "0%": "rgb(96 168 220)",
          "100%": "#87d068",
        }}
        percent={loadingPercent}
      />
    </Modal>
  );
}

export default confirmable(ProgressbarDlg);
