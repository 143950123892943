import React from 'react';
import { Tag, Popconfirm, Space, message, Row, Col } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { DeleteOutlined, EditFilled, EditOutlined, UserAddOutlined } from '@ant-design/icons';


export function showColumnAction(info, adduser) {
  return (
    <Row gutter={8}>
      <Col>
        <a
          style={{ color: "var(--blueColor)" }}
          onClick={async () => {
            adduser(info)
          }}
        >
          <UserAddOutlined /> Add
        </a>
      </Col>
    </Row>

  );

}

