import React from "react";
import { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Drawer,
  DatePicker,
  Divider,
  InputNumber,
} from "antd";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import moment from "moment";
import "./RightDetailWidget.css";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { isEmpty, ValidateEmail } from "utils/GlobalFunctions";
import { useEffect } from "react";
import { MaskedInput } from "antd-mask-input";
import StepItemWidget from "./StepItemWidget";
import { showEncryptModal } from "./EncryptModal/showEncryptModal";
import { useSelector } from "react-redux";

const { Option } = Select;
function CampRightDetailWidget(props) {
  const {
    visible,
    setVisible,
    userData,
    setUserData,
    updateData,
    telePhone,
    setTelephone,
    homePhone,
    setHomephone,
    cellPhone,
    setCellphone,
    contactPhone,
    setContactPhone,
    tripList,
  } = props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const [errorField, setErrorField] = useState([]);
  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    setErrorField([]);
  }, []);
  const onClose = () => {
    setErrorField([]);
    setVisible(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const validateOneFields = () => {
    setUserData({
      ...userData,
      // tele_phone: telePhone,
      p_home_phone: homePhone,
      p_cell_phone: cellPhone,
      c_cell_phone: contactPhone,
    });
    var errorList = Array();
    errorList = isEmpty(userData, "camper_first_name", errorList);
    errorList = isEmpty(userData, "camper_last_name", errorList);
    errorList = isEmpty(userData, "camper_age", errorList);
    errorList = isEmpty(userData, "camper_street", errorList);
    errorList = isEmpty(userData, "camper_city", errorList);
    errorList = isEmpty(userData, "camper_state", errorList);
    errorList = isEmpty(userData, "camper_zip", errorList);
    errorList = isEmpty(userData, "camper_grade", errorList);

    errorList = isEmpty(userData, "p_first_name", errorList);
    errorList = isEmpty(userData, "p_last_name", errorList);
    errorList = isEmpty(userData, "p_relation", errorList);

    if (
      cellPhone != undefined &&
      cellPhone != "" &&
      cellPhone != "___-___-____"
    ) {
      if (cellPhone.length != 12) {
        errorList.push("p_cell_phone");
      }
    } else {
      errorList.push("p_cell_phone");
    }
    if (
      homePhone != undefined &&
      homePhone != "" &&
      homePhone != "___-___-____"
    ) {
      if (homePhone.length != 12) {
        errorList.push("p_home_phone");
      }
    }

    let pemailErrList = [];
    pemailErrList = isEmpty(userData, "p_email", pemailErrList);
    if (pemailErrList.length == 0) {
      if (ValidateEmail(userData.p_email) === false) {
        errorList.push("p_email");
      }
    } else {
      errorList = [...errorList, ...pemailErrList];
    }

    errorList = isEmpty(userData, "c_first_name", errorList);
    errorList = isEmpty(userData, "c_last_name", errorList);

    let cemailErrList = [];
    cemailErrList = isEmpty(userData, "c_email", cemailErrList);
    if (cemailErrList.length == 0) {
      if (ValidateEmail(userData.c_email) === false) {
        errorList.push("c_email");
      }
    } else {
      errorList = [...errorList, ...cemailErrList];
    }

    if (
      contactPhone != undefined &&
      contactPhone != "" &&
      contactPhone != "___-___-____"
    ) {
      if (contactPhone.length != 12) {
        errorList.push("c_cell_phone");
      }
    } else {
      errorList.push("c_cell_phone");
    }
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onUpdateData = () => {
    let valid = validateOneFields();
    if (!valid) return;
    updateData();
    onClose();
  };

  const showHealthForm = async (u_data) => {
    var resInfo = await showEncryptModal({
      title: "Decrypting health form for user[" + userDataStore.user.EMAIL + "]",
      user_data: u_data,
    });
    if (resInfo == null) return;
  }
  const onCheck = (id) => {
    if (userData.camper_sel_trip.includes(id)) {
      let tmpCheckedFees = [...userData.camper_sel_trip.split(",")];
      tmpCheckedFees = tmpCheckedFees.filter((x) => x != "" && x != id);
      let newIDs = tmpCheckedFees.join(",");
      if (newIDs != "") {
        newIDs = newIDs + ",";
      }
      setUserData({ ...userData, camper_sel_trip: newIDs });
    } else {
      setUserData({
        ...userData,
        camper_sel_trip: userData.camper_sel_trip + id + ",",
      });
    }
  };

  return (
    <>
      <Drawer
        title=""
        placement="right"
        onClose={onClose}
        open={visible}
        className="camper right-detail-drawer"
      >
        <div className="block">
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Participant Info</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <Row gutter={[16, 6]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name*</div>
                <Input
                  value={userData.camper_first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("first_name")) {
                      let errors = errorField.filter((x) => x != "first_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      camper_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                <Input
                  value={userData.camper_last_name}
                  className={
                    errorField !== undefined && errorField.includes("last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("last_name")) {
                      let errors = errorField.filter((x) => x != "last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      camper_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
              <Col xs={24} sm={8}>
                <div style={{ paddingBottom: "5px" }}>Age*</div>
                <InputNumber
                  min={1}
                  max={100}
                  size="small"
                  value={userData.camper_age}
                  style={{ fontWeight: 400, width: "100%" }}
                  className={
                    errorField !== undefined && errorField.includes("age")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("age")) {
                      let errors = errorField.filter((x) => x != "age");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      camper_age: e,
                    });
                  }}
                  step={1}
                />
              </Col>
              <Col xs={24} sm={8}>
                <div style={{ paddingBottom: "5px" }}>Grade*</div>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("grade")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ fontWeight: 400, width: "100%" }}
                  value={
                    userData.camper_grade == undefined
                      ? null
                      : userData.camper_grade
                  }
                  onSelect={(v) => {
                    if (errorField.includes("grade")) {
                      let errors = errorField.filter((x) => x != "grade");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, camper_grade: v });
                  }}
                >
                  <Option value="0">K</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                </Select>
              </Col>
              <Col xs={24} sm={8}>
                <div style={{ paddingBottom: "5px" }}>T-Shirt Size*</div>
                <Select
                  className={
                    errorField !== undefined && errorField.includes("t_shirt")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ fontWeight: 400, width: "100%" }}
                  value={
                    userData.camper_t_shirt == undefined
                      ? null
                      : userData.camper_t_shirt
                  }
                  onSelect={(v) => {
                    if (errorField.includes("t_shirt")) {
                      let errors = errorField.filter((y) => y != "t_shirt");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      camper_t_shirt: v,
                    });
                  }}
                >
                  <Option value="YS">YS</Option>
                  <Option value="YM">YM</Option>
                  <Option value="YL">YL</Option>
                  <Option value="YXL">YXL</Option>
                  <Option value="AS">AS</Option>
                  <Option value="AM">AM</Option>
                  <Option value="AL">AL</Option>
                  <Option value="AXL">AXL</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <div style={{ paddingBottom: "5px" }}>Address*</div>
                <Input
                  value={userData.camper_street}
                  className={
                    errorField !== undefined && errorField.includes("street")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("street")) {
                      let errors = errorField.filter((x) => x != "street");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, camper_street: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={24}>
                <div style={{ paddingBottom: "5px" }}>State/Province*</div>
                <Select
                  showSearch
                  className={
                    errorField !== undefined && errorField.includes("state")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ width: "100%", fontWeight: 400 }}
                  value={
                    userData.camper_state == undefined
                      ? null
                      : userData.camper_state
                  }
                  onSelect={(v) => {
                    if (errorField.includes("state")) {
                      let errors = errorField.filter((x) => x != "state");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, camper_state: v });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="AL">Alabama</Option>
                  <Option value="AK">Alaska</Option>
                  <Option value="AZ">Arizona</Option>
                  <Option value="AR">Arkansas</Option>
                  <Option value="CA">California</Option>
                  <Option value="CO">Colorado</Option>
                  <Option value="CT">Connecticut</Option>
                  <Option value="DE">Delaware</Option>
                  <Option value="FL">Florida</Option>
                  <Option value="GA">Georgia</Option>
                  <Option value="HI">Hawaii</Option>
                  <Option value="ID">Idaho</Option>
                  <Option value="IL">Illinois</Option>
                  <Option value="IN">Indiana</Option>
                  <Option value="IA">Iowa</Option>
                  <Option value="KS">Kansas</Option>
                  <Option value="KY">Kentucky</Option>
                  <Option value="LA">Louisiana</Option>
                  <Option value="ME">Maine</Option>
                  <Option value="MD">Maryland</Option>
                  <Option value="MA">Massachusetts</Option>
                  <Option value="MI">Michigan</Option>
                  <Option value="MN">Minnesota</Option>
                  <Option value="MS">Mississippi</Option>
                  <Option value="MO">Missouri</Option>
                  <Option value="MT">Montana</Option>
                  <Option value="NE">Nebraska</Option>
                  <Option value="NV">Nevada</Option>
                  <Option value="NH">New Hampshire</Option>
                  <Option value="NJ">New Jersey</Option>
                  <Option value="NM">New Mexico</Option>
                  <Option value="NY">New York</Option>
                  <Option value="NC">North Carolina</Option>
                  <Option value="ND">North Dakota</Option>
                  <Option value="OH">Ohio</Option>
                  <Option value="OK">Oklahoma</Option>
                  <Option value="OR">Oregon</Option>
                  <Option value="PA">Pennsylvania</Option>
                  <Option value="RI">Rhode Island</Option>
                  <Option value="SC">South Carolina</Option>
                  <Option value="SD">South Dakota</Option>
                  <Option value="TN">Tennessee</Option>
                  <Option value="TX">Texas</Option>
                  <Option value="UT">Utah</Option>
                  <Option value="VT">Vermont</Option>
                  <Option value="VA">Virginia</Option>
                  <Option value="WA">Washington</Option>
                  <Option value="WV">West Virginia</Option>
                  <Option value="WI">Wisconsin</Option>
                  <Option value="WY">Wyoming</Option>
                </Select>
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>City*</div>
                <Input
                  value={userData.camper_city}
                  className={
                    errorField !== undefined && errorField.includes("city")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("city")) {
                      let errors = errorField.filter((x) => x != "city");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, camper_city: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Zip/Postal Code*</div>
                <Input
                  value={userData.camper_zip}
                  maxLength={5}
                  className={
                    errorField !== undefined && errorField.includes("zip")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("zip")) {
                      let errors = errorField.filter((x) => x != "zip");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, camper_zip: e.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
              <Col xs={24}>
                <div style={{ paddingBottom: "5px" }}>
                  List of known allergies, health or behavioral concerns
                </div>
                <Input
                  value={userData.camper_health}
                  className={
                    errorField !== undefined && errorField.includes("health")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("health")) {
                      let errors = errorField.filter((x) => x != "health");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      camper_health: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row align="middle" justify="center" style={{ marginTop: 20, marginBottom: 20 }}>
              <Col>
                <CommonButton
                  style={{ padding: "8px 24px" }}
                  onClick={() => {
                    showHealthForm(userData);
                  }}
                >
                  Show Health Form
                </CommonButton>
              </Col>
            </Row>
          </div>
        </div>
        <div className="block" style={{ marginTop: 10 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Parent/Guardian</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <Row gutter={[16, 6]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name*</div>
                <Input
                  value={userData.p_first_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "p_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      p_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                <Input
                  value={userData.p_last_name}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_last_name")) {
                      let errors = errorField.filter((x) => x != "p_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      p_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Mobile Number*</div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={cellPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "p_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setCellphone(e.target.value);
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Home Number</div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={homePhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_home_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_home_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "p_home_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setHomephone(e.target.value);
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Email*</div>
                <Input
                  type={"email"}
                  value={userData.p_email}
                  className={
                    errorField !== undefined && errorField.includes("p_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("p_email")) {
                      let errors = errorField.filter((x) => x != "p_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, p_email: e.target.value });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Mobile Carrier*</div>
                <Select
                  className={
                    errorField !== undefined &&
                      errorField.includes("p_relation")
                      ? `common-select-error`
                      : `common-select`
                  }
                  style={{ width: "100%" }}
                  value={
                    userData.p_relation == undefined
                      ? null
                      : userData.p_relation
                  }
                  onSelect={(v) => {
                    if (errorField.includes("p_relation")) {
                      let errors = errorField.filter((y) => y != "p_relation");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      p_relation: v,
                    });
                  }}
                >
                  <Option value="Verizon">Verizon</Option>
                  <Option value="AT&T">AT&T</Option>
                  <Option value="T-Mobile">T-Mobile</Option>
                  <Option value="Sprint">Sprint</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Col>
            </Row>
          </div>
        </div>
        <div className="block" style={{ marginTop: 10 }}>
          <div>
            <Row justify="space-between">
              <Col>
                <span className="title">Emergency Contact</span>
              </Col>
              <Col>
                <span
                  className="title"
                  style={{ cursor: "pointer", display: "none" }}
                >
                  Close
                </span>
              </Col>
            </Row>
            <Divider
              style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
            ></Divider>
          </div>
          <div style={{ marginTop: 10 }}>
            <Row gutter={[16, 6]}>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>First Name*</div>
                <Input
                  value={
                    userData.c_first_name == "null" ? "" : userData.c_first_name
                  }
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_first_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_first_name")) {
                      let errors = errorField.filter(
                        (x) => x != "c_first_name"
                      );
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_first_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Last Name*</div>
                <Input
                  value={
                    userData.c_last_name == "null" ? "" : userData.c_last_name
                  }
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_last_name")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_last_name")) {
                      let errors = errorField.filter((x) => x != "c_last_name");
                      setErrorField([...errors]);
                    }
                    setUserData({
                      ...userData,
                      c_last_name: capitalizeFirstLetter(e.target.value),
                    });
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Email*</div>
                <Input
                  type="email"
                  value={userData.c_email == "null" ? "" : userData.c_email}
                  className={
                    errorField !== undefined && errorField.includes("c_email")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_email")) {
                      let errors = errorField.filter((x) => x != "c_email");
                      setErrorField([...errors]);
                    }
                    setUserData({ ...userData, c_email: e.target.value });
                  }}
                />
              </Col>

              <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>Phone Number*</div>
                <MaskedInput
                  mask={"000-000-0000"}
                  value={contactPhone}
                  className={
                    errorField !== undefined &&
                      errorField.includes("c_cell_phone")
                      ? `common-input-error`
                      : `common-input`
                  }
                  onChange={(e) => {
                    if (errorField.includes("c_cell_phone")) {
                      let errors = errorField.filter(
                        (x) => x != "c_cell_phone"
                      );
                      setErrorField([...errors]);
                    }
                    setContactPhone(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <div style={{ marginTop: 10 }}>
              <Row justify="space-between">
                <Col>
                  <span className="title">Field Trips</span>
                </Col>
                <Col>
                  <span
                    className="title"
                    style={{ cursor: "pointer", display: "none" }}
                  >
                    Close
                  </span>
                </Col>
              </Row>
              <Divider
                style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
              ></Divider>
            </div>
            <div className="block" style={{ padding: 10 }}>
              {tripList.map((x, index) => {
                return (
                  <StepItemWidget
                    itemInfo={x}
                    onCheck={onCheck}
                    key={index}
                    isLast={index == tripList.length - 1}
                    userData={userData}
                  />
                );
              })}
            </div>
          </div>
          <Row align="middle" justify="center" style={{ marginTop: 30 }}>
            <Col>
              <CommonButton
                style={{ padding: "8px 24px" }}
                onClick={() => {
                  onUpdateData();
                }}
              >
                Update
              </CommonButton>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
}

export default CampRightDetailWidget;
