// You can use CONSTANTS.js file for below definitions of constants and import here.
export const SIZE_CHANGED = "SIZE_CHANGED";
export const MENU_COLLAPSED_CHANGED = "MENU_COLLAPSED_CHANGED";
export const CURRENT_ROUTE = "CURRENT_ROOT";
export const UNREAD_NOTIFICATION_COUNT = "UNREAD_NOTIFICATION_COUNT";
export const REGISTRATION_LIST = "REGISTRATION_LIST";
export const CUR_REGISTRATION = "CUR_REGISTRATION";
export const CUR_CLUB = "CUR_CLUB";

export const ChangeSize = (payload) => ({
  type: SIZE_CHANGED,
  payload
})

export const ChangeMenuCollapsed = (payload) => ({
  type: MENU_COLLAPSED_CHANGED,
  payload
})

export const UpdateCurrentRoot = (payload) => ({
  type: CURRENT_ROUTE,
  payload
})
export const UpdateCurClub = (payload) => ({
  type: CUR_CLUB,
  payload
})
export const UpdateRegistrationList = (payload) => ({
  type: REGISTRATION_LIST,
  payload
})
export const UpdateCurRegistration = (payload) => ({
  type: CUR_REGISTRATION,
  payload
})