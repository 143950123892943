import React from "react";
import { Input, Row, Col, Select } from "antd";

import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import ExportExcelWidget from "components/ExportExcelWidget/ExportExcelWidget";
import { useEffect } from "react";
import { useState } from "react";
const { Search } = Input;
const { Option } = Select;
function TransactionSearchWidget(props) {
  const {
    dataList,
    groupList,
    isGroup,
    groupVal,
    setGroupVal,
    onClear,
    onTransactionDetail,
    searchKey,
    setSearchKey,
    onSearch,
    sport_type,
  } = props;

  const [excelData, setExcelData] = useState([]);
  useEffect(() => {
    if (dataList.length > 0) {
      let tmpList = [];
      dataList.map((x) => {

        let date = new Date(x.timestamp * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth() + 1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        let hour = date.getHours();

        let minute = date.getMinutes();

        // Will display time in 10:30:23 format
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let pm = " AM";
        if (hour >= 12) {
          pm = " PM";
        }
        if (hour > 12) {
          hour = hour - 12;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        year = year + "";
        let formattedTime =
          month + "/" + day + "/" + year + " " + hour + ":" + minute + pm;

        let tmpObj = {
          "Registration ID": x.user_id,
          Description: x.description,
          "Child Num": x.child_num,
          Amount: "$" + x.amount,
          "Est Fee": "$" + x.est_fee,
          Timestamp: formattedTime
        };
        tmpList = [...tmpList, tmpObj];
      });
      setExcelData(tmpList);
    }
  }, [dataList]);
  return (
    <Row className="follower-cnt-txt" align="middle" justify="space-between">
      <Col>
        <span>Count: {isGroup ? groupList.length : dataList.length}</span>
      </Col>
      <Col>
        <Row gutter={32} align="middle">
          <Col>
            <Select
              className={`common-select`}
              placeholder="Group by"
              style={{ width: 270 }}
              value={groupVal == "" ? null : groupVal}
              onSelect={(v) => {
                setGroupVal(v);
              }}
            >
              <Option value="description">Description</Option>
            </Select>
            <CommonButton
              style={{ marginLeft: 10 }}
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </CommonButton>
          </Col>
          <Col>
            <ExportExcelWidget
              excelData={excelData}
              fileName={"registration_details"}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TransactionSearchWidget;
